/**
 * Created by Andrey Popov on 2/24/22.
 */

Telly.PRIZES.dragonia = [
    [{
        units: [
            { currentType: "drresource", stage: 0 },
            { currentType: "drhero", stage: 0 },
            { code: "worker", stage: 0 }
        ]
    }],

    [{
        units: [
            { currentType: "drresource", stage: 1 },
            { currentType: "drhero", stage: 1 },
            { code: "worker", stage: 1 },
            { code: "drmagicplant", stage: 1 }
        ]
    }],

    [{
        units: [
            { currentType: "drresource", stage: 2 },
            { currentType: "drhero", stage: 2 },
            { code: "worker", stage: 2 },
            { code: "drmagicplant", stage: 1 },
            { code: "crystal", stage: 1 }
        ]
    }]
];

Rudolf.DATA.dragonia = {
    maxRewards: 5,

    values: {
        drgiftaxe: [1, 2, 5, 15, 30, 60],
        drgiftcuirass: [1, 2, 5, 15, 30, 60],
        drgifthelm: [1, 2, 5, 15, 30, 60],
        drgiftshield: [1, 2, 5, 15, 30, 60]
    },

    rewards: [
        { value: 95, unit: { code: "drshopchest", stage: 1 } },
        { value: 60, unit: { code: "drfreechest", stage: 2 } },
        { value: 48, unit: { code: "worker", stage: 4 } },
        { value: 48, unit: { code: "crystal", stage: 3 } },
        { value: 30, unit: { chest: "drresource", stage: 2 } },
        { value: 26, unit: { code: "crystal", stage: 2 } },
        { value: 20, unit: { code: "worker", stage: 2 } },
        { value: 17, unit: { code: "crystal", stage: 2 } },
        { value: 17, unit: { code: "worker", stage: 2 } },
        { value: 17, unit: { code: "drfreechest", stage: 0 } },
        { value: 16, unit: { code: "drgrowing", stage: 3 } },
        { value: 16, unit: { code: "rubies", stage: 1 } },
        { value: 16, unit: { chest: "drresource", stage: 0 } },
        { value: 8, unit: { code: "drgrowing", stage: 2 } },
        { value: 8, unit: { code: "rubies", stage: 0 } },
        { value: 6, unit: { code: "bppointsstar", stage: 0 } },
        { value: 6, unit: { code: "crystal", stage: 0 } },
        { value: 5, unit: { code: "worker", stage: 0 } },
        { value: 3, unit: { family: "drresource", stage: 0 } },
        { value: 3, unit: { hero: "drhero", stage: 0 } },
        { value: 2, unit: { code: "coins", stage: 0 } },
        { value: 2, unit: { code: "drgrowing", stage: 1 } },
        { value: 1, unit: { code: "drgrowing", stage: 0 } }
    ]
};

MissionTree.PRIZES.dragonia = [{
    amount: 5,
    units: [
        { code: "drgrowing", stage: 1 },
        { code: "drgrowing", stage: 1 },
        { code: "drgrowing", stage: 1 },
        { code: "drgrowing", stage: 1 },
        { code: "drgrowing", stage: 2 },
        { code: "drgiftaxe", stage: 0 },
        { code: "drgiftcuirass", stage: 0 },
        { code: "drgifthelm", stage: 0 },
        { code: "drgiftshield", stage: 0 },
        { code: "drgiftaxe", stage: 1 },
        { code: "drgiftcuirass", stage: 1 },
        { code: "drgifthelm", stage: 1 },
        { code: "drgiftshield", stage: 1 }
    ]
}];

Ruins.TIERS.dragonia = {
    cheap: {
        soft: 59,
        rewards: [
            { code: "drfreechest", stage: 0, amount: 1 },
            { code: "worker", stage: 0, amount: 1 },
            { code: "crystal", stage: 0, amount: 1 }
        ]
    },
    costly: {
        hard: 249,
        rewards: [
            { code: "drshopchest", stage: 1, amount: 1 },
            { code: "crystal", stage: 3, amount: 1 },
            { code: "drmagicplant", stage: 5, amount: 1 },
            { code: "worker", stage: 4, amount: 1 }
        ]
    }
};

GrowingsPlanner.CONFIG.dragonia = cleverapps.clone(GrowingsPlanner.CONFIG.universal, true);

Landmarks.WORLDS.dragonia = {
    fog: "fog5",
    force: Forces.LANDMARK_DONOR_SLOT2,
    landmarks: [{
        code: "landmark",
        stage: 6,
        targetExpedition: "main",
        donors: "dryeti"
    }]
};

var DragoniaSourceMine = {
    prize: function (level, unit) {
        var amounts = [[1], [1, 1], [1, 1, 1]];
        var chestStage = [0, 0, 0];

        switch (unit.code) {
            case "drsledsource":
                amounts = [[1], [1, 2], [1, 2, 2, cleverapps.Random.random(2, 3)]];
                break;

            case "drcastlesource":
                amounts = [[2], [2, 3], [1, 2, 3, cleverapps.Random.random(3, 4)]];
                break;
        }

        if (level === unit.getData().mineable.length - 1) {
            return [{
                units: [{ code: unit.getResource() + "chest", stage: chestStage[unit.stage] }]
            }, {
                amount: [1, 3],
                units: [{ code: "drsnail", stage: 0 }]
            }];
        }

        return [{
            amount: amounts[unit.stage][level],
            units: [{ code: unit.getResource(), stage: 0 }]
        }, {
            amount: [1, 3],
            units: [{ code: "drsnail", stage: 0 }]
        }];
    }
};

Mines.drsource = DragoniaSourceMine.prize;
Mines.drgrowing = WandsGrowing.prize;
