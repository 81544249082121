/**
 * Created by mac on 2/25/20
 */

var FogTile = function (x, y, fogBlock, options) {
    this.x = x;
    this.y = y;
    this.openDelay = 0;
    this.isHead = options && options.head;
    this.fogBlock = fogBlock;
    this.fake = options && options.fake;
    this.state = fogBlock.state;

    Map2d.currentMap.add(Map2d.LAYER_FOG, this.x, this.y, this);
    if (Map2d.currentMap.isVisibleCell(x, y)) {
        Map2d.currentMap.onAdd(Map2d.LAYER_FOG, x, y, this);
    }
};

FogTile.prototype.clear = function () {
    this.removeView();
};

FogTile.prototype.onOpenFogBlock = function () {
    if (this.view) {
        this.view.onOpenFogBlock();
    }
};

FogTile.prototype.setView = function (view) {
    this.view = view;
};

FogTile.prototype.removeView = function () {
    this.view = undefined;
};

FogTile.prototype.onGetView = function () {
    return this.view;
};

FogTile.prototype.onOpen = function (callback, silent) {
    callback = callback || function () {};
    if (this.view) {
        this.view.onOpen(callback, silent);
    } else {
        callback();
    }
};

FogTile.prototype.onUpdateState = function (state, silent) {
    if (this.view) {
        this.view.onUpdateState(state, silent);
    }
};

FogTile.prototype.onClick = function () {
    if (this.view) {
        this.view.onClick();
    }
};

FogTile.prototype.checkTouchInside = function (touch) {
    if (this.view) {
        return this.view.checkTouchInside(touch);
    }
};

FogTile.prototype.pointOfInterestsSqueeze = function () {
    if (this.view) {
        this.view.pointOfInterestsSqueeze();
    }
};

FogTile.prototype.onDestructFog = function () {
    if (this.view) {
        this.view.destructFog();
    }
};

FogTile.prototype.touchFilter = function () {
    return Game.currentGame && Game.currentGame.tutorial && Game.currentGame.tutorial.disableHandleClick(this.fogBlock.id);
};

FogTile.prototype.onClickEvent = function (touch) {
    if (this.fogBlock) {
        var pointOfInterestUnit = undefined;
        var pointOfInterestTile = this.checkPointOfInterestTouchInside(touch);
        if (pointOfInterestTile) {
            pointOfInterestUnit = this.getFakeUnit();
            pointOfInterestUnit = pointOfInterestUnit.head || pointOfInterestUnit;
        }
        if (pointOfInterestTile) {
            pointOfInterestTile.pointOfInterestsSqueeze();
        }
        this.fogBlock.click(pointOfInterestUnit);
    }
};

FogTile.prototype.getFogType = function () {
    var type = Map2d.currentMap.getTerrainType(this.x, this.y);
    return (bundles[type.name] || bundles[type.defaultTiles]).meta.fog;
};

FogTile.prototype.getFogBundle = function () {
    return bundles["fogs_" + this.getFogType()];
};

FogTile.prototype.getOpenDelay = function () {
    return this.openDelay;
};

FogTile.prototype.getOpenDuration = function () {
    var type = this.getFogType();
    var styles = cleverapps.styles.FogTileView[type];

    return styles && styles.whirlwind && styles.whirlwind.duration
        || styles && styles.dispelling && styles.dispelling.duration
        || (styles && styles.groundStateOnly ? 0 : 500);
};

FogTile.prototype.checkPointOfInterestTouchInside = function (touch) {
    if (this.isPointOfInterest()) {
        var fakeUnit = this.getFakeUnit();

        var fogTile = this;
        if (fakeUnit.head) {
            fogTile = Map2d.currentMap.getFog(fakeUnit.head.x, fakeUnit.head.y);
        }
        if (fogTile.checkTouchInside(touch)) {
            return fogTile;
        }
    }
    return false;
};

FogTile.prototype.getFakeUnit = function () {
    return Map2d.currentMap.fogs.fakeUnits[this.x] && Map2d.currentMap.fogs.fakeUnits[this.x][this.y];
};

FogTile.prototype.updateState = function (state, silent) {
    this.state = state;
    this.onUpdateState(state, silent);

    if (this.balloon) {
        this.balloon.updateState(state, silent);
    }
};

FogTile.prototype.open = function (silent, callback) {
    Map2d.currentMap.remove(Map2d.LAYER_FOG, this.x, this.y);
    Map2d.currentMap.counter.inc();

    var fakeUnit = this.getFakeUnit();
    var unit;

    if (fakeUnit && !fakeUnit.head) {
        unit = new Unit(fakeUnit);
        unit.setPosition(this.x, this.y);
        Map2d.currentMap.add(Map2d.LAYER_UNITS, this.x, this.y, unit);
    }

    new ActionPlayer([
        function (f) {
            this.onOpen(f, silent);
        }.bind(this),

        function (f) {
            if (unit) {
                Map2d.currentMap.onAddUnit(this.x, this.y, unit);
                Map2d.currentMap.onUnitAvailable(unit);
                unit.showUp(f);
            } else {
                f();
            }
        }.bind(this),

        function (f) {
            callback && callback();
            f();
            Map2d.currentMap.counter.dec();
        }
    ]).play();

    this.fogBlock = undefined;
};

FogTile.prototype.isPointOfInterest = function () {
    var fakeUnit = this.getFakeUnit();
    if (!fakeUnit) {
        return false;
    }

    return fakeUnit.pointOfInterest || (fakeUnit.head && fakeUnit.head.pointOfInterest);
};

FogTile.prototype.addBalloon = function (balloon) {
    this.balloon = balloon;
};

FogTile.prototype.removeBalloon = function () {
    delete this.balloon;
};

FogTile.prototype.fromBlockedGround = function () {
    return this.fogBlock && this.fogBlock.id === "blockedGroundFog";
};
