/**
 * Created by r4zi4l on 04.07.2021
 */

var UnitsShopWindow = ShopWindow.extend({
    ctor: function (options) {
        options = options || {};

        this.tab = options.tab;
        this.onChangeTab = options.onChangeTab;
        this.unitsShopTab = cleverapps.unitsShop.tabs[this.tab];
        this.unitsShopTab.init();
        this.unitsShopTab.setFreshShown();

        this.rewardsOnClose = true;

        this._super(options);
    },

    createTabs: function () {
        var shops = cleverapps.unitsShop.getAvailableShops();
        var isCinema = [ShopWindow.TABS.UNITS_ADS, ShopWindow.TABS.UNITS_ADS_UNDECIDED, ShopWindow.TABS.UNITS_ADS_VIP].indexOf(this.tab) !== -1;
        if (shops.length < 2 || isCinema) {
            return;
        }

        var tabs = shops.map(function (shop) {
            return {
                id: shop.tab,
                icon: bundles.tile_shop.frames["tab_" + shop.unit.code]
            };
        });

        this.tabs = new RoundTabs(tabs, function (id) {
            this.rewardsOnClose = false;

            new UnitsShopWindow({ tab: id, onChangeTab: true });

            this.close();
        }.bind(this));
        this.tabs.setLocalZOrder(2);
        this.addChild(this.tabs);

        this.tabs.setTab(this.tab);

        if (!this.onChangeTab) {
            this.tabs.show();
        }
    },

    onWindowLoaded: function (options) {
        this.createBottomLine();
        this.createCart();

        this.updateBottomLine();

        this.createTabs();
        this.updateTabs();
        this._super(options);
    },

    isVertical: function () {
        return false;
    },

    setupChildren: function () {
        this._super();
        this.updateBottomLine();
        this.updateTabs();
    },

    updateTabs: function () {
        var styles = cleverapps.styles.UnitsShopWindow.roundTabs;

        this.tabs && this.tabs.setPositionRound(styles);
    },

    createCart: function () {
        var styles = cleverapps.styles.UnitsShopWindow.cart;

        this.cart = new UnitsShopCart(this.unitsShopTab);
        this.bottomLine.addChild(this.cart);
        this.cart.setAnchorPoint(0.5, 0.5);
        this.cart.setPositionRound(styles);
        this.cart.setCascadeOpacityEnabledRecursively(!this.cart.amount);
    },

    updateBottomLine: function () {
        var styles = cleverapps.styles.UnitsShopWindow;

        var sceneSize = cleverapps.resolution.getSceneSize();
        var scale = Math.min(1, sceneSize.width / cleverapps.styles.UnitsShopWindow.minWidth);

        this.bottomLine.setContentSize2(sceneSize.width / scale / 0.9, styles.bottomLine.height);
        this.bottomLine.setScale(scale);
        this.bottomLine.setPositionRound(sceneSize.width / 2, styles.bottomLine.offsetY + this.bottomLine.height / 2);

        this.bottomLineContent.setPositionRound(this.bottomLine.width / 2 + styles.bottomLine.content.x, this.bottomLine.height / 2 + styles.bottomLine.content.y);

        this.cart.setPositionRound(styles.cart);
    },

    createBottomLine: function () {
        var styles = cleverapps.styles.UnitsShopWindow.bottomLine;

        var bottomLine = this.bottomLine = cleverapps.UI.createScale9Sprite(bundles.tile_shop.frames.line_png);
        bottomLine.setLocalZOrder(2);
        this.addChild(bottomLine);

        var appearInText = cleverapps.UI.generateOnlyText("UnitsShop.newItemsWillAppear", cleverapps.styles.FONTS.UNITSSHOP_APPEAR_IN);
        appearInText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_RIGHT);
        appearInText.fitTo(styles.appearInText.maxWidth);

        var timer = new cleverapps.CountDownView(new cleverapps.CountDown(this.unitsShopTab.getLeftTime()), {
            font: cleverapps.styles.FONTS.UNITSSHOP_TIMER,
            icon: bundles.tile_shop.frames.units_shop_timer
        });

        if (!connector.platform.oneOf(connector.SP_MOBAGE, connector.MOBAGE)) {
            this.refreshButton = new cleverapps.UI.Button({
                text: "UnitsShop.reset$$" + UnitsShop.PRODUCTS[this.tab].refreshPrice,
                width: styles.button.width,
                height: styles.button.height,
                onClicked: this.refresh.bind(this)
            });
            this.updateRefreshButton();
        }

        this.bottomLineContent = new cleverapps.Layout([appearInText, timer, this.refreshButton].filter(Boolean), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.content.margin
        });
        this.bottomLineContent.setAnchorPoint(1, 0.5);
        bottomLine.addChild(this.bottomLineContent);
        bottomLine.setCascadeOpacityEnabledRecursively(true);

        return bottomLine;
    },

    updateRefreshButton: function () {
        if (this.refreshButton) {
            if (this.unitsShopTab.countBoughtProducts() > 0) {
                this.refreshButton.enable();
            } else {
                this.refreshButton.disable();
            }
        }
    },

    refresh: function () {
        if (this.unitsShopTab.buyRefresh()) {
            this.rewardsOnClose = false;

            new UnitsShopWindow({ tab: this.tab });
            this.close();
        }
    },

    buyProduct: function (tileModel, buyParams) {
        buyParams = buyParams || {};
        buyParams.afterBuy = this.updateRefreshButton.bind(this);
        this._super(tileModel, buyParams);
    },

    selectTileForScroll: function () {
        var selectedTile;
        if (this.options.priorityUnit) {
            var pUnit = this.options.priorityUnit;

            selectedTile = this.tiles.filter(function (tile) {
                var unit = tile.tileModel.product.unit;
                return pUnit.code === unit.code && (pUnit.stage === undefined || pUnit.stage === unit.stage);
            }, this)[0];
        }

        return selectedTile || this._super();
    },

    onShow: function () {
        this._super();
        this.bottomLine.setOpacity(0);
        this.bottomLine.runAction(new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.FadeIn(0.3)
        ));
    },

    beforeCloseAnimation: function (callback) {
        if (!this.rewardsOnClose || !this.unitsShopTab.getCartAmount()) {
            callback();
            return;
        }

        this.cart.replaceParentSamePlace(this, {
            keepScale: true
        });
        this.cart.setLocalZOrder(3);

        this.bottomLine.runAction(new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.FadeOut(0.3)
        ));

        if (this.tabs) {
            this.tabs.hide();
        }

        this.hideSelf();

        var cartRewards = UnitsShop.cartRewards;
        var cupStars = cartRewards.map(function (unitReward) {
            return unitReward.cupStars;
        }).filter(Boolean);

        this.cart.processItems(function () {
            if (!cupStars.length) {
                callback();
                return;
            }

            var delay = 0;
            var collectAnimation = function () {
                cupStars.forEach(function (cupReward) {
                    delay = cupReward.reward.collectRewardsAnimation(cupReward.unit) / 1000;
                });
            };

            Map2d.currentMap.focusOnUnit(cupStars[0].unit, {
                allowScrollWithFocus: true,
                delay: delay,
                action: collectAnimation,
                callback: callback
            });
        });
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    UNITSSHOP_APPEAR_IN: {
        size: 48,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    UNITSSHOP_TIMER: {
        name: "default",
        size: 56
    }
});

cleverapps.styles.UnitsShopWindow = {
    margin: -50,

    minWidth: 1900,

    roundTabs: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 40 }
    },

    bottomLine: {
        height: 90,
        offsetY: 150,

        content: {
            x: 890,
            y: 0,
            margin: 20
        },

        appearInText: {
            maxWidth: 800
        },

        button: {
            width: 360,
            height: 110
        }
    },

    cart: {
        x: { align: "center", dx: -830 },
        y: { align: "center" }
    }
};
