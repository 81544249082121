/**
 * Created by andrey on 29.03.2023
 */

var Map2dCellView = function (x, y, parent) {
    this.cell = cc.p(x, y);
    this.parent = parent;

    var pos = parent.alignInGrid(x, y);
    this.x = pos.x;
    this.y = pos.y;
    this.width = cleverapps.styles.Map2dView.cell.width;
    this.height = cleverapps.styles.Map2dView.cell.height;
};

Map2dCellView.prototype.getParent = function () {
    return this.parent;
};

Map2dCellView.prototype.getPosition = function () {
    return cc.p(this.x, this.y);
};

Map2dCellView.prototype.getBoundingBox = function () {
    return cc.rect(this.x, this.y, 0, 0);
};