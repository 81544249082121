/**
 * Created by Andrey Popov on 19.12.2024
 */

var MergeComponent = function (unit) {
    UnitComponent.call(this, unit);
};

MergeComponent.prototype = Object.create(UnitComponent.prototype);
MergeComponent.prototype.constructor = MergeComponent;

MergeComponent.prototype.handleDragMove = function (x, y, cellsInRadius) {
    var isErrorLight, wrongCrystalMerged;

    var affected = this.findList(cellsInRadius, this.listMergeAtPoint.bind(this));

    if (!affected) {
        isErrorLight = true;

        affected = this.findList(cellsInRadius, this.listSameItemsGroup.bind(this));
        if (!affected && this.unit.isMagicCrystal()) {
            affected = this.findList(cellsInRadius, this.listDisableCrystalUnitsAtPoint.bind(this));
            wrongCrystalMerged = affected && affected[0];
        }
    }

    if (affected) {
        x = affected[0].x;
        y = affected[0].y;
    }

    if (this.unit.dragX === x && this.unit.dragY === y) {
        return true;
    }

    this.unit.dragX = x;
    this.unit.dragY = y;

    this.unhighlightMerge();

    if (affected) {
        this.unit.onMarkNormal();
        if (this.unit.light) {
            this.unit.light.hide();
        }

        cleverapps.centerHint.create({
            unit: this.unit,
            amount: affected.length + 1,
            wrongMerged: isErrorLight,
            wrongCrystalMerged: wrongCrystalMerged
        });

        isErrorLight ? this.highlightMergeError(affected) : this.highlightMerge(affected);

        if (this.unit.light) {
            this.unit.light.move(x, y);
        }

        return true;
    }
};

MergeComponent.prototype.findList = function (cells, filter) {
    for (var i = 0; i < cells.length; i++) {
        var cell = cells[i];
        var list = filter(cell.x, cell.y);
        if (list) {
            break;
        }
    }

    return list;
};

MergeComponent.prototype.handleDragEnd = function (x, y) {
    var mergeInfoUnit = Map2d.currentMap.getUnit(x, y);
    var affected = this.listMergeAtPoint(x, y);

    this.unhighlightMerge();

    if (!affected) {
        return false;
    }

    var needConfirmation = this.unit.isMagicCrystal() && !cleverapps.gameModes.skipOpenChestWindow
        && (Game.currentGame && Game.currentGame.tutorial && !Game.currentGame.tutorial.isActive());

    if (needConfirmation) {
        var amount = mergeInfoUnit.findComponent(MergeComponent).mergeBonus(affected.length + 1).bonus;
        cleverapps.focusManager.display({
            stack: true,
            focus: "ConfirmMergeWindow",
            action: function (f) {
                new ConfirmMergeWindow(this.unit, mergeInfoUnit, amount, function (confirmed) {
                    if (confirmed) {
                        Game.currentGame.merge(affected, this.unit, mergeInfoUnit);
                    } else {
                        this.unit.dragX = undefined;
                        this.unit.dragY = undefined;
                        this.unit.onMove();
                    }
                }.bind(this));

                cleverapps.focusManager.onceNoWindowsListener = f;
            }.bind(this)
        });
    } else {
        Game.currentGame.merge(affected, this.unit, mergeInfoUnit);
    }

    return true;
};

MergeComponent.prototype.highlightMergeError = function (affected) {
    if (this.view) {
        this.view.highlightMergeError(affected);
    }
};

MergeComponent.prototype.highlightMerge = function (affected) {
    if (this.view) {
        this.view.highlightMerge(affected);
    }
};

MergeComponent.prototype.unhighlightMerge = function () {
    if (this.view) {
        this.view.unhighlightMerge();
    }
};

MergeComponent.prototype.listMergeAtPoint = function (x, y) {
    var map = Map2d.currentMap;
    var mergingUnit = map.getUnit(x, y);

    var affected = [];
    if (mergingUnit && map.isGround(x, y) && !map.getFog(x, y) && this.unit.isMergeable(mergingUnit, true)) {
        affected = map.bfs(x, y, map.compareMergeable.bind(map, mergingUnit));
    }

    if (affected.length >= 2) {
        return affected;
    }
};

MergeComponent.prototype.listDisableCrystalUnitsAtPoint = function (x, y) {
    var map = Map2d.currentMap;
    var unit = map.getUnit(x, y);

    var affected = [];
    if (unit && map.isGround(x, y) && !map.getFog(x, y) && Unit.DISABLED_MERGABLE_UNITS_FOR_CRYSTAL.includes(unit.code)) {
        affected = map.bfs(x, y, map.compareMergeable.bind(map, unit));
    }

    if (affected.length >= 2) {
        return affected;
    }
};

MergeComponent.prototype.listSameItemsGroup = function (x, y) {
    var map = Map2d.currentMap;
    var mergingUnit = map.getUnit(x, y);

    var affected = [];
    if (map.compareEqual(this.unit, x, y)) {
        affected = map.bfs(x, y, map.compareEqual.bind(map, mergingUnit));
    }

    if (affected.length >= 2) {
        return affected;
    }
};

MergeComponent.prototype.mergeBonus = function (mergeAmount) {
    var prize = 0;
    var next = 5;
    var stages = Families[this.unit.code].units;

    if (mergeAmount >= 3 && stages[this.unit.stage].heroitem && this.unit.stage === stages.length - 2) {
        prize = 1;
        mergeAmount -= 3;
    } else {
        while (mergeAmount >= 3) {
            var merging = 3;
            var p = 1;
            while (mergeAmount >= 2 * merging - 1) {
                p *= 2;
                merging = 2 * merging - 1;
            }
            if (next === 5) {
                next = 2 * merging - 1;
            }

            mergeAmount -= merging;
            prize += p;
        }
    }

    return {
        bonus: prize,
        keepLast: mergeAmount,
        next: next
    };
};