/**
 * Created by mac on 12/23/20
 */

var CollectibleView = function (collectible, unitView) {
    this.collectible = collectible;
    this.unitView = unitView;
};

CollectibleView.prototype.feedAnimation = function (target) {
    Map2d.currentMap.showLayerTile(Map2d.LAYER_UNITS, target.x, target.y);

    var targetView = target.onGetView();
    var feedable = target.findComponent(Feedable);

    this.feedingAction = this.unitView.runAction(
        AnimationsLibrary.feed(this.unitView, targetView)
    ).setFinalize(function () {
        feedable.onFeed();
    });
};

CollectibleView.prototype.clearAnimations = function () {
    if (this.feedingAction && !this.feedingAction.isDone()) {
        this.unitView.stopAction(this.feedingAction);
        delete this.feedingAction;
    }
};

CollectibleView.prototype.collectionAnimation = function (index, callback) {
    var target;
    var sound;

    switch (this.collectible.unit.getType()) {
        case "fruit":
        case "herofood":
            target = "harvested";
            sound = bundles.fruit.urls.fruit_fly_finish_effect;
            break;
    }

    if (target) {
        target = cleverapps.aims.getTarget(target);
    }

    var actions = [];

    actions.push(new cc.Show());
    actions.push(AnimationsLibrary.animateCollect(this.unitView, target, {
        path: true,
        adjustToTarget: true,
        collectEffect: true,
        sound: sound
    }));

    actions.push(new cc.Hide());
    actions.push(new cc.CallFunc(callback));
    actions.push(new cc.RemoveSelf());

    this.unitView.stopAllActions();
    this.unitView.runAction(new cc.Sequence(actions));
};
