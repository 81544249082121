/**
 * Created by Denis Kuzin on 12 october 2022
 */

var Pixel = function (options) {
    this.type = options.type;
    this.order = options.order;
    this.amountPixels = options.amountPixels;
    this.onDiePixel = options.onDiePixel;
    this.collectPrize = options.collectPrize;
    this.reward = PixelsSwarmConfig[this.type].reward;

    this.flying = false;
    this.dead = false;

    this.onFlyingChanged = function () {};
    this.onAnimateCollect = function () {};
    this.onDie = function () {};

    var centerCell = Map2d.currentMap.getScreenCenterCell() || Map2d.currentMap.getFocusCell();
    var startingY = 0;

    for (var y = startingY; y < centerCell.y; y++) {
        if (Map2d.currentMap.isScreenCellPosition(centerCell.x, y)) {
            break;
        }

        startingY = y;
    }

    var dy = 4;
    this.x = centerCell.x + Math.ceil(this.order - this.amountPixels / 2);
    this.y = (startingY - dy - 2) + cleverapps.Random.random(dy);
};

Pixel.prototype.setFlying = function (flying) {
    if (flying !== this.flying) {
        this.flying = flying;
        this.onFlyingChanged(this.flying);
    }
};

Pixel.prototype.collect = function () {
    if (!this.flying || this.dead) {
        return;
    }

    this.onAnimateCollect(function (options) {
        if (this.reward.resource) {
            cleverapps.audio.playSound(options.sound);

            var value = cleverapps.Random.random(this.reward.amount[0], this.reward.amount[1]);
            var rewardType = this.reward.resource === "coins" ? "soft" : this.reward.resource;

            cleverapps.aims.showDelta(value, {
                target: options.source,
                type: rewardType
            });
            Game.currentGame.addReward(rewardType, value, options.source, { delay: options.delay });
        } else {
            var prize = Prizes.Generate(this.reward)[0];

            if (!prize) {
                prize = {
                    code: cleverapps.Random.choose(cleverapps.unitsLibrary.listAvailableByType("fruit")),
                    stage: 0
                };
            }

            var left = Game.currentGame.spawn(prize, options.source, {
                fromNode: true,
                sound: options.sound,
                delay: options.delay
            });
            if (left.length) {
                return;
            }
        }

        this.collectPrize();
        this.die();
    }.bind(this));
};

Pixel.prototype.die = function (silent) {
    this.dead = true;

    this.setFlying(false);
    this.onDiePixel();

    this.onDie(silent);
};
