/**
 * Created by Denis Kuzin on 02 march 2023
 */

var TreasureMap = function (unit) {
    UnitComponent.call(this, unit);
};

TreasureMap.prototype = Object.create(UnitComponent.prototype);
TreasureMap.prototype.constructor = TreasureMap;

TreasureMap.prototype.handleClick = function () {
    this.unit.squeeze();
    cleverapps.audio.playSound(bundles.main.urls.click_effect);

    var fogBlock;

    var blocks = Map2d.currentMap.fogs.blocks;
    for (var i in blocks) {
        if (blocks[i].isTreasureMapTarget()) {
            fogBlock = blocks[i];
            break;
        }
    }

    if (fogBlock) {
        Map2d.currentMap.focusOnUnit(fogBlock.head, {
            callback: function () {
                if (Map2d.currentMap.isScreenCellPosition(this.unit.x, this.unit.y)) {
                    this.unit.onDestruction();
                    this.unit.remove(true);
                } else {
                    this.unit.remove();
                }
            }.bind(this)
        });
    }

    return true;
};
