/**
 * Created by dmitry on 12.12.2024
 */

var FieldInfoView = cc.Node.extend({
    ctor: function (info) {
        var styles = cleverapps.styles.FieldInfoView;

        var resources = info.actions.map(function (action) {
            return new FieldInfoIcon(action);
        });

        var content = new cleverapps.Layout(resources, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin,
            padding: styles.padding
        });

        this._super();
        this.setContentSize2(content.getContentSize());

        var background = InfoView.CreateInfoBackground(content.getContentSize());
        this.addChild(background);

        content.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(content);
    }
});

FieldInfoView.ACTION_PLANT = 1;
FieldInfoView.ACTION_HARVEST = 2;

cleverapps.styles.FieldInfoView = {
    margin: 16,

    padding: {
        x: 12,
        top: 12,
        bottom: 16
    }
};
