/**
 * Created by mac on 12/8/20
 */

var Map2dInnerView = cc.Node.extend({
    ctor: function (map2d) {
        this._super();

        this.map2d = map2d;
        this.scroller = map2d.scroller;

        map2d.onDiscoverMapView = function () {
            return this;
        }.bind(this);

        var visibleBox = map2d.visibleBox;

        var SIZE = map2d.getWidth() + map2d.getHeight();
        // console.log(map2d.getWidth(), map2d.getHeight())
        var totalWidth = SIZE * cleverapps.styles.Map2dView.cell.width / 2;
        var totalHeight = SIZE * cleverapps.styles.Map2dView.cell.height / 2;

        this.cutLeft = totalWidth * visibleBox.cutLeft;
        this.cutRight = totalWidth * visibleBox.cutRight;

        this.cutTop = totalHeight * visibleBox.cutTop;
        this.cutBottom = totalHeight * visibleBox.cutBottom;

        this.setContentSize2(totalWidth - this.cutLeft - this.cutRight, totalHeight - this.cutTop - this.cutBottom);
        this.setAnchorPoint(0.5, 0.5);

        var under = this.under = new cc.Node();

        if (cleverapps.config.debugMode) {
            this.under._debugExclude = true;
        }

        under.setContentSize(this.getContentSize());
        under.setPositionRound(this.width / 2, this.height / 2);
        under.setAnchorPoint(0.5, 0.5);
        this.addChild(under, -1);

        var cloudsAbove = this.cloudsAbove = new cc.Node();

        if (cleverapps.config.debugMode) {
            this.cloudsAbove._debugExclude = true;
        }

        cloudsAbove.setContentSize(this.getContentSize());
        cloudsAbove.setPositionRound(this.width / 2, this.height / 2);
        cloudsAbove.setAnchorPoint(0.5, 0.5);
        this.addChild(cloudsAbove, 1);

        var animations = this.animations = this.movingNode = new cc.Node();

        if (cleverapps.config.debugMode) {
            this.animations._debugExclude = true;
        }

        animations.setContentSize(this.getContentSize());
        animations.setPositionRound(this.width / 2, this.height / 2);
        animations.setAnchorPoint(0.5, 0.5);
        animations.debugId = "Animations";
        this.addChild(animations, 1);

        if (cleverapps.config.debugMode) {
            animations._debugExclude = true;
        }

        map2d.onAdd = this.createListener(function (layer, x, y, object) {
            if (cleverapps.config.debugMode && object instanceof Unit) {
                throw "User onAddUnit to create unit view on map";
            }

            var view = this.createView(object);
            if (object && view) {
                this.addTile(layer, x, y, view);
                if (map2d.getValue(layer, x, y) === object) {
                    view.cell.view = true;
                }
            }
        }.bind(this));

        map2d.onAddUnit = this.createListener(function (x, y) {
            this.showLayerTile(Map2d.LAYER_UNITS, x, y);
        }.bind(this));

        map2d.onAnimationsAdd = this.createListener(function (object) {
            var view = this.createView(object);
            if (view) {
                this.animations.addChild(view);
            }
        }.bind(this));

        if (!cleverapps.environment.isAdministratorScene()) {
            this.addControls();
            cleverapps.aims.registerTarget("units", this, {
                noTargetDelta: true,
                flyingUnderShadow: true,
                flyingAnimation: Reward.SPAWN_UNITS_ANIMATION
            });
        }

        var layers = this.getLayersOrder();
        this.layers = layers;
        this.layersFlat = this.layers.flat();

        this.tiles = [];

        for (var i = 0; i < layers.length; i++) {
            this.tiles[layers[i][0]] = [];
            for (var y = Math.round(-map2d.getHeight() * 0.5); y < Math.round(map2d.getHeight() * 1.5); y++) {
                this.tiles[layers[i][0]][y] = (y >= 0 && y < map2d.getHeight()) ? [] : {};
            }
            for (var j = 1; j < layers[i].length; j++) {
                this.tiles[layers[i][j]] = this.tiles[layers[i][0]];
            }
        }

        this.map2d.showTiles = this.createListener(this.showTiles.bind(this));
        this.map2d.hideTiles = this.createListener(this.hideTiles.bind(this));
        this.map2d.removeTiles = this.createListener(this.removeTiles.bind(this));
        this.map2d.showLayerTile = this.showLayerTile.bind(this);

        if (engine === "creator") {
            this.tilesAddedBeforeRunning = [];

            this.pixiObject._static = true;
            this.pixiObject.addComponent(MapRenderer);
        }

        this.map2d.workers.onAddWorker = this.createListener(function (worker) {
            var view = new WorkerView(worker);
            this.addTile(Map2d.LAYER_UNITS, 0, 0, view);
        }.bind(this));

        this.map2d.workers.regular.forEach(function (worker) {
            this.map2d.workers.onAddWorker(worker);
        }, this);

        this.scheduleUpdateWithPriority(-1);
    },

    onExit: function () {
        this._super();

        this.map2d.onUpdateVisitRect = function () {};
        this.map2d.addViews = function () {};
        this.map2d.removeViews = function () {};
    },

    onEnter: function () {
        this._super();

        if (engine === "creator") {
            this.tilesAddedBeforeRunning.forEach(function (tile) {
                tile._performRecursive(function (node) {
                    node.onEnter();
                    node.onEnterTransitionDidFinish();
                });
            });
            this.tilesAddedBeforeRunning = [];
        }
    },

    onEnterTransitionDidFinish: function () {
        this._super();

        this.visibleRect && this.setVisibleRect(this.visibleRect);
    },

    onResize: function () {
    },

    update: function (dt) {
        this._super(dt);

        if (this.controlsNode && this.scroller.getScrollView() && !this.scroller.getScrollView().touchScrollDisabled) {
            var unitTouch = this.controlsNode.getUnitTouch();
            if (unitTouch && this.scroller.getScrollView().borderTouchScrollIteration(unitTouch, dt * 1500, 85)) {
                this.controlsNode.forceMoveUnit();
            }
        }

        if (this.tilesVisibleRectDirty) {
            var rects = this.getTilesVisitIsoRect(this.visibleRect);
            this.map2d.setVisitRect(rects.visitRect, rects);
            delete this.tilesVisibleRectDirty;
        }

        this.updateInvisibleTiles();

        this.map2d.removeQueue.process(dt);
    },

    getLayersOrder: function () {
        var layersOrders = [
            [Map2d.LAYER_WATER],
            [Map2d.LAYER_BORDERS],
            [Map2d.LAYER_GROUND],
            [Map2d.ABOVE_GROUND]
        ];

        var fogsUnits = [[Map2d.LAYER_UNITS], [Map2d.LAYER_FOG]];
        if (Map2d.FOGS_UNITS_ONE_LAYER) {
            fogsUnits = [fogsUnits.flat()];
        }

        return layersOrders.concat(fogsUnits);
    },

    updateInvisibleTiles: function () {
        var bgSize = cleverapps.resolution.getBgSize();
        var sceneBox = cc.rect(0, 0, bgSize.width, bgSize.height);
        var map2d = this.map2d;

        var disabled = map2d.lastVisitRectTime + 500 > cleverapps.timeouts.time;

        if (this.updateInvisibleTilesDisabled === disabled) {
            return;
        }
        this.updateInvisibleTilesDisabled = disabled;

        this._renderCmd.setDirtyFlag(cc.Node._dirtyFlags.transformDirty);

        this.iterateVisibleTiles(function (tile, layer, x, y) {
            var invisible = !tile.permanentOnMap && !(disabled || map2d.isScreenCellPosition(x, y) || cc.rectIntersectsRect(sceneBox, tile.getBoundingBoxToWorld()));
            if (tile.invisible !== invisible) {
                tile.invisible = invisible;
                cc.renderer.childrenOrderDirty = true;
            }
        });
    },

    findTouchUnitOrDecorator: function (touch, withDecorators) {
        var cell = this.getCellByTouch(touch);
        if (cell) {
            var results = [];

            cc.iterateIsoRect(cc.rect(cell.x - 6, cell.y + 4, 2, 6), function (x, y) {
                var decorators;

                if (withDecorators) {
                    decorators = this.map2d.decorators.getDecorators(x, y);
                    if (decorators) {
                        decorators.forEach(function (decorator) {
                            if (decorator.hasHandleClick()) {
                                results.push(decorator);
                            }
                        });
                    }
                }

                var unit = this.map2d.getUnitWithHead(x, y);
                if (unit && results.indexOf(unit) === -1) {
                    results.push(unit);
                }
            }.bind(this));

            results.reverse();

            var bestScore = 0;
            var bestItem = undefined;

            for (var i = 0; i < results.length; i++) {
                var item = results[i];
                var score = 0;

                if (item.checkTouchInside(touch)) {
                    if ((item instanceof Map2dDecorator) && item.getLayerId() < Map2d.LAYER_UNITS) {
                        score = 1;
                    } else if ((item instanceof Unit) && item.getData().isTree && !item.checkTouchInside(touch, true)) {
                        score = 2;
                    } else {
                        score = 3;
                    }
                }

                if (score > bestScore) {
                    bestScore = score;
                    bestItem = item;
                }
            }

            return bestItem;
        }
    },

    getCellByTouch: function (touch, rawValue) {
        var pos = this.convertTouchToNodeSpace(touch);
        return this.getCellByCoordinates(pos, rawValue);
    },

    findTouchFog: function (touch) {
        var cell = this.getCellByTouch(touch);
        if (cell) {
            var additionalCells = [{ x: 0, y: 0 }, { x: -1, y: 1 }, { x: -1, y: 0 }, { x: 0, y: 1 }];
            for (var i = 0; i < additionalCells.length; i++) {
                var fog = this.map2d.getFog(cell.x + additionalCells[i].x, cell.y + additionalCells[i].y);
                if (fog) {
                    if (fog.checkPointOfInterestTouchInside(touch)) {
                        return fog;
                    }
                }
            }
            return this.map2d.getFog(cell.x, cell.y);
        }
    },

    onCellClick: function (cell) {
        if (cleverapps.skins.getSlot("walkingWorkersSkins")) {
            this.animateClick(cell, !this.map2d.workers.tryMove(cell));
        }
    },

    getUnitCenterPos: function (x, y) {
        var unit = this.map2d.getUnit(x, y);
        var unitView = unit && unit.getShape().length > 1 && unit.onGetView();

        if (!unitView) {
            var tile = this.map2d.fogs.findTile(x, y);
            unitView = tile && tile.onGetView() && tile.onGetView().fogUnit;
        }

        if (unit && unit.onGetView() && unit.onGetView().clickRect) {
            return this.animations.convertToNodeSpace(unit.onGetView().convertToWorldSpace(cc.rectGetCenter(unit.onGetView().clickRect)));
        }

        if (unitView) {
            return this.animations.convertToNodeSpace(unitView.parent.convertToWorldSpace(unitView.getCenterPos()));
        }

        if (unit && unit.isMultiCell()) {
            return this.alignPositionInGrid(
                unit.x,
                unit.y,
                MultiCellView.calcCenterPosition(unit.findComponent(MultiCell).shape)
            );
        }

        return this.alignInGrid(x, y);
    },

    addControls: function () {
        var controlsNode = this.controlsNode = new Map2dControls(this);
        this.addChild(controlsNode, -1);

        this.toggleScroll = this.createListener(function (enabled) {
            this.scroller.getScrollView().touchScrollDisabled = !enabled;
        }.bind(this));

        this.toggleZoom = this.createListener(function (enabled) {
            this.scroller.getScrollView().touchZoomDisabled = !enabled;
        }.bind(this));

        cleverapps.focusManager.registerControl("map2dScroll", this.toggleScroll);
        cleverapps.focusManager.registerControl("map2dZoom", this.toggleZoom);
    },

    addTile: function (layer, x, y, tile) {
        if (tile.parent) {
            var position = this.convertToNodeSpace(tile.parent.convertToWorldSpace(tile.getPosition()));
            position.x = Math.round(position.x);
            position.y = Math.round(position.y);

            tile.removeTemporarily(false);
            tile.setPositionRound(position);
        } else {
            this.alignViewInGrid(x, y, tile);
        }

        tile.cell = { layer: layer, x: x, y: y };

        tile.setOrderOfArrival(cc.s_globalOrderOfArrival++);
        this._addCellTile(layer, x, y, tile);
        tile.saveStack = true;

        if (engine === "creator") {
            cc.Node.prototype.addChild.call(this, tile, undefined, undefined, true);

            if (!this.isRunning()) {
                this.tilesAddedBeforeRunning.push(tile);
            }
        } else {
            tile.setParent(this);
            if (tile.alignment) {
                tile.doAlignment();
            }
        }

        if (tile.additionalViews) {
            Object.values(tile.additionalViews).forEach(function (view) {
                this.upAdditionalViewAboveClouds(tile, view);
            }.bind(this));
        }

        if (engine === "cocos2d") {
            if (this.isRunning()) {
                tile._performRecursive(cc.Node._stateCallbackType.onEnter);
                if (this._isTransitionFinished) {
                    tile._performRecursive(cc.Node._stateCallbackType.onEnterTransitionDidFinish);
                }
            }

            tile.invisible = false;
            cc.renderer.childrenOrderDirty = true;
        }

        if (tile.createParts) {
            tile.createParts().forEach(function (partView) {
                this.addTile(partView.part.layer, x + partView.part.x, y + partView.part.y, partView);
                partView.x = tile.x;
                partView.y = tile.y + (tile.height - tile.sprite.height) / 2;
            }.bind(this));
        }
    },

    upAdditionalViewAboveClouds: function (tile, additionalView) {
        if (tile.parent) {
            additionalView.replaceParentSamePlace(this.cloudsAbove);
            additionalView.setLocalZOrder(this.getAdditionalViewLocalZOrder(tile.unit.x, tile.unit.y));
        }
    },

    getAdditionalViewLocalZOrder: function (x, y) {
        return -this.map2d.height + y - x;
    },

    getFogViewLocalZOrder: function (x, y) {
        return this.getAdditionalViewLocalZOrder(x, y) - 100;
    },

    addChild: function (node) {
        var cell = node.cell;
        if (cell) {
            this.addTile(cell.layer, cell.x, cell.y, node);
            return;
        }

        if (cleverapps.config.debugMode && this.running) {
            console.error("Map2d.addChild", node);
            throw "Cant add child to map2dView";
        }

        this._super.apply(this, arguments);
    },

    removeTile: function (layer, x, y, tile, cleanup) {
        if (!this._removeCellTile(layer, x, y, tile)) {
            return;
        }

        var cellPosition = this.alignInGrid(x, y);
        tile.x -= cellPosition.x;
        tile.y -= cellPosition.y;

        if (!tile.invisible) {
            cc.renderer.childrenOrderDirty = true;
        }

        if (cleanup === undefined) {
            cleanup = true;
        }
        this._detachChild(tile, cleanup);
    },

    removeChild: function (child, cleanup) {
        var cell = child.cell;
        if (cell) {
            this.removeTile(cell.layer, cell.x, cell.y, child, cleanup);
            return;
        }

        this._super.apply(this, arguments);
    },

    setLocalZOrder: function (child, zOrder) {
        if (engine === "creator" && child.cell) {
            if (child._localZOrder === zOrder) {
                return;
            }
            child._localZOrder = zOrder;
            child.arrivalOrder = cc.s_globalOrderOfArrival++;

            this.sortTiles(child.cell.layer, child.cell.y, child.cell.x);
        } else {
            this._super.apply(this, arguments);
        }
    },

    sortTiles: function (layer, y, x) {
        var tiles = this.tiles[layer][y][x];
        if (Array.isArray(tiles)) {
            tiles.sort(cc.Base.CHILDREN_ORDER_FN);
        }
    },

    reorderChild: function (child, zOrder) {
        var cell = child.cell;
        if (cell) {
            child.arrivalOrder = cc.s_globalOrderOfArrival++;
            child._setLocalZOrder(zOrder);

            this.sortTiles(cell.layer, cell.y, cell.x);

            cc.renderer.childrenOrderDirty = true;

            return;
        }

        this._super.apply(this, arguments);
    },

    _addCellTile: function (layer, x, y, tile) {
        var tiles = this.tiles[layer][y][x];
        if (!tiles) {
            this.tiles[layer][y][x] = tile;
        } else {
            if (!Array.isArray(tiles)) {
                this.tiles[layer][y][x] = [tiles];
                tiles = this.tiles[layer][y][x];
            }

            tiles.push(tile);
            tiles.sort(cc.Base.CHILDREN_ORDER_FN);
        }
    },

    _removeCellTile: function (layer, x, y, tile) {
        var tiles = this.tiles[layer][y][x];

        if (tiles === tile) {
            this.tiles[layer][y][x] = undefined;
            return true;
        }

        if (Array.isArray(tiles)) {
            var oldLength = tiles.length;
            cc.arrayRemoveObject(tiles, tile);
            var length = tiles.length;

            if (tiles.length === 1) {
                this.tiles[layer][y][x] = tiles[0];
            }

            return length < oldLength;
        }

        return false;
    },

    visitCurrent: function () {
        this._super();

        var self = this;

        this.iterateVisibleTiles(function (tile) {
            if (!tile.invisible) {
                tile.visit(self);
            }
        });
    },

    iterateVisibleTiles: function (iterator) {
        for (var i = 0; i < this.layers.length; i++) {
            var layer = this.layers[i][0];
            var self = this;

            cc.iterateIsoRect(this.map2d.getVisitRect(), function (x, y) {
                self.iterateCellLayerTiles(layer, x, y, iterator);
            });
        }
    },

    iterateTiles: function (iterator) {
        for (var i = 0; i < this.layers.length; i++) {
            var layer = this.layers[i][0];

            for (var y = 0; y < this.map2d.getHeight(); y++) {
                for (var x = 0; x < this.map2d.getWidth(); x++) {
                    this.iterateCellLayerTiles(layer, x, y, iterator);
                }
            }
        }
    },

    iterateCellTiles: function (x, y, iterator) {
        for (var i = 0; i < this.layers.length; i++) {
            this.iterateCellLayerTiles(this.layers[i][0], x, y, iterator);
        }
    },

    iterateCellLayerTiles: function (layer, x, y, iterator) {
        var tiles = this.tiles[layer][y];
        tiles = tiles && tiles[x];

        if (!tiles) {
            return;
        }

        if (!Array.isArray(tiles)) {
            iterator(tiles, layer, x, y);
            return;
        }

        for (var i = 0; i < tiles.length; i++) {
            iterator(tiles[i], layer, x, y);
        }
    },

    listTiles: function (layer, x, y) {
        var tiles = cleverapps.toArray(this.tiles[layer][y][x]) || [];
        return tiles.filter(function (tile) {
            return tile.cell.layer === layer;
        });
    },

    showTiles: function (x, y) {
        for (var i = 0; i < this.layersFlat.length; i++) {
            this.showLayerTile(this.layersFlat[i], x, y);
        }
        this.map2d.edges.addViews(x, y);
        this.map2d.decorators.addDecoratorsViews(x, y);
    },

    showLayerTile: function (layer, x, y) {
        var object = this.map2d.getValue(layer, x, y);

        if (object === undefined) {
            return;
        }

        var tile = this.getCellLayerTile(layer, x, y);
        if (tile) {
            tile.invisible = false;
            cc.renderer.childrenOrderDirty = true;
            return;
        }

        var X = this.map2d.pointer.x;
        var Y = this.map2d.pointer.y;

        this.map2d.setPointer(x, y);
        tile = this.createView(object);
        if (tile) {
            this.addTile(tile.layer || layer, x, y, tile);
            tile.cell.view = true;
        }
        this.map2d.setPointer(X, Y);
    },

    hideTiles: function (x, y) {
        this.iterateCellTiles(x, y, function (tile) {
            if (tile && tile.parent === this && !tile.permanentOnMap) {
                tile.invisible = true;
            }
        }.bind(this));
    },

    removeTiles: function (x, y) {
        var tiles = [];
        this.iterateCellTiles(x, y, function (tile) {
            if (tile && tile.parent === this && !tile.permanentOnMap) {
                tiles.push(tile);
            }
        }.bind(this));

        for (var i = 0; i < tiles.length; i++) {
            this.putTileInPool(tiles[i]);
        }
    },

    getCellLayerTile: function (layer, x, y) {
        var object = this.map2d.getValue(layer, x, y);
        if (object === undefined) {
            return;
        }

        if (layer === Map2d.LAYER_UNITS) {
            return object instanceof Unit && object.onGetView() ? object.onGetView() : undefined;
        }
        if (layer === Map2d.LAYER_FOG) {
            return object instanceof FogTile && object.onGetView() ? object.onGetView() : undefined;
        }

        var tiles = this.tiles[layer][y][x];
        if (!tiles) {
            return;
        }

        if (!Array.isArray(tiles)) {
            return tiles.cell.layer === layer && tiles.cell.view ? tiles : undefined;
        }

        for (var i = 0; i < tiles.length; i++) {
            if (tiles[i].cell.layer === layer && tiles[i].cell.view) {
                return tiles[i];
            }
        }
    },

    putTileInPool: function (tile) {
        if (tile.clearAnimations) {
            tile.clearAnimations();
        }

        var createErrorLog = function (message, tile) {
            message = message + " Cell: " + JSON.stringify(tile.cell);
            if (tile.unit) {
                message += " Unit: " + Unit.GetKey(tile.unit);
            }
            message += (tile.actionStack || "");
            if (cleverapps.config.debugMode) {
                throw message;
            } else {
                cleverapps.throwAsync(message);
            }
        };
        if (tile.getNumberOfRunningActions() > 0) {
            createErrorLog("Node with action is removed.", tile);
        }
        if (tile.sprite instanceof cleverapps.Spine) {
            if (!tile.sprite.isSaveToRemove()) {
                createErrorLog("Node with spine is removed.", tile);
            }
        }

        if (cleverapps.oneOf(tile, Map2dWaterView, Map2dGroundView, Map2dChessGroundView)) {
            cc.pool.putInPool(tile);
        } else {
            tile.removeFromParent();
        }

        delete tile.cell;
    },

    animateClick: function (cell, warning) {
        var clickAnimation = new cleverapps.Spine(bundles.merge.jsons.ground_click);
        clickAnimation.setAnimation(0, "animation", false);
        clickAnimation.setCompleteListenerRemove();
        clickAnimation.setSafeToRemove();
        clickAnimation.setLocalZOrder(10);
        this.addTile(Map2d.LAYER_UNITS, cell.x + 2, cell.y - 2, clickAnimation);

        clickAnimation.setPosition(this.getCoordinatesByCell(cell));

        if (warning) {
            clickAnimation.setColor(cleverapps.styles.COLORS.COLOR_RED);
        }
    },

    containsView: function (unitView) {
        return unitView.getParent() === this;
    }
});

Map2dInnerView.prototype.setVisibleRect = function (visibleRect) {
    this.visibleRect = visibleRect;

    this.map2d.setScreenRect(this.screenRectToIso(visibleRect));
    this.tilesVisibleRectDirty = true;
};

Map2dInnerView.prototype.screenRectToIso = function (rect) {
    var cell = this.getCellByCoordinates(rect);
    var width = Math.round(rect.width / cleverapps.styles.Map2dView.cell.width);
    var height = Math.round(rect.height / cleverapps.styles.Map2dView.cell.height);
    return cc.rect(cell.x, cell.y, width, height);
};

Map2dInnerView.prototype.getTilesVisitIsoRect = function (screenRect) {
    var styles = cleverapps.styles.Map2dView.cell;

    var frame = this.map2d.tilesVisibleRectFrame;

    var showRect = cc.rect(
        screenRect.x + frame.x * styles.width,
        screenRect.y + frame.y * styles.height,
        screenRect.width + frame.width * styles.width,
        screenRect.height + frame.height * styles.height
    );

    var visitRect = cc.rect(showRect.x, showRect.y, showRect.width, showRect.height);
    var showCells = {};

    var decorators = this.map2d.decorators.getLargeDecorators();
    for (var i = 0; i < decorators.length; i++) {
        var decorator = decorators[i];
        var decoratorRect = decorator.getLargeBox();
        var cellRect = decorator.getCellRect();

        if (cc.rectIntersectsRect(screenRect, decoratorRect) && !cc.rectContainsRect(showRect, cellRect)) {
            visitRect = cc.rectUnion(visitRect, cellRect);

            if (!showCells[decorator.y]) {
                showCells[decorator.y] = {};
            }
            showCells[decorator.y][decorator.x] = true;
        }
    }

    return {
        visitRect: this.screenRectToIso(visitRect),
        showRect: this.screenRectToIso(showRect),
        showCells: showCells
    };
};

Map2dInnerView.prototype.getCellByCoordinates = function (p, rawValue) {
    var zero = this.alignInGrid(0, 0);
    var coors = Map2dInnerView.ScreenToIso(p.x - zero.x, p.y - zero.y);
    if (rawValue) {
        return coors;
    }
    return cc.p(Math.round(coors.x), Math.round(coors.y));
};

Map2dInnerView.prototype.getCoordinatesByCell = function (cell) {
    var zero = this.alignInGrid(0, 0);
    var coors = Map2dInnerView.IsoToScreen(cell.x, cell.y);
    return cc.p(coors.x + zero.x, coors.y + zero.y);
};

Map2dInnerView.prototype.getOffsetByTouch = function (touch) {
    var cell = this.getCellByTouch(touch);
    var rawCell = this.getCellByTouch(touch, true);
    return cc.p(rawCell.x - cell.x, rawCell.y - cell.y);
};

Map2dInnerView.prototype.getSourceCenterPosition = function (source) {
    var position = this.alignInGrid(source.x, source.y);
    if (source instanceof Unit) {
        if (source.findComponent(MultiCell)) {
            var centerDelta = source.findComponent(MultiCell).getCenter();
            position.x += centerDelta.x;
            position.y += centerDelta.y;
        }
    }
    return position;
};

Map2dInnerView.ScreenToIso = function (x, y) {
    var dx = x / cleverapps.styles.Map2dView.cell.width;
    var dy = y / cleverapps.styles.Map2dView.cell.height;

    return {
        x: dx + dy,
        y: dx - dy
    };
};

Map2dInnerView.IsoToScreen = function (x, y) {
    var dx = (x + y) / 2;
    var dy = (x - y) / 2;

    return {
        x: dx * cleverapps.styles.Map2dView.cell.width,
        y: dy * cleverapps.styles.Map2dView.cell.height
    };
};

Map2dInnerView.prototype.toScreen = function (x, y) {
    return Map2dInnerView.IsoToScreen(x, y);
};

Map2dInnerView.prototype.getCell = function (x, y) {
    return new Map2dCellView(x, y, this);
};

Map2dInnerView.prototype.hasView = function (layer, x, y) {
    var object = this.map2d.getValue(layer, x, y);
    this.map2d.setPointer(x, y);

    return object instanceof FogTile || object instanceof Highlight
        || object instanceof Unit && !this.map2d.getFog(object.x, object.y) && !object.isMultiCellBody()
        || ViewReader.hasView(object, this.map2d)
        || this.map2d.decorators.hasView(layer, x, y);
};

Map2dInnerView.prototype.createView = function (object) {
    if (object instanceof FogTile) {
        return new FogTileView(object);
    }
    if (object instanceof Highlight) {
        return new HighlightView(object);
    }
    if (object instanceof Unit) {
        if (cleverapps.config.debugMode && !Map2d.unitsVisible) {
            return;
        }
        if (!object.isMultiCellBody()) {
            return new UnitView(object);
        }
    } else {
        return ViewReader.parse(object, this.map2d);
    }
};

Map2dInnerView.prototype.alignInGrid = function (x, y) {
    var X = x + y + 1;
    var Y = (x - y) + this.map2d.getHeight();
    return cc.p(X * cleverapps.styles.Map2dView.cell.width / 2 - this.cutLeft, Y * cleverapps.styles.Map2dView.cell.height / 2 - this.cutBottom);
};

Map2dInnerView.prototype.alignViewInGrid = function (x, y, view) {
    var cellPosition = this.alignInGrid(x, y);
    var position = view.alignment || view.getPosition();
    position = view.calculatePositionRound(position.x, position.y, { parentSize: { width: 0, height: 0 } });
    position.x += cellPosition.x;
    position.y += cellPosition.y;

    delete view.alignment;

    view.setPositionRound(position.x, position.y);
};

Map2dInnerView.prototype.alignPositionInGrid = function (x, y, position) {
    var cellPosition = this.alignInGrid(x, y);
    return cc.p(position.x + cellPosition.x, position.y + cellPosition.y);
};

Map2dInnerView.prototype.wrapWithScroll = function (onPositionChanged) {
    var scrollableMap = new cleverapps.UI.ScrollView(this, {
        scrollBarEnabled: false,
        zoom: cleverapps.config.demoMode ? { minZoom: 0.2 } : true,
        outOfBoundaryScale: cleverapps.skins.getSlot("outOfBoundaryScale"),
        inertialScrollFactor: 0.2
    });

    scrollableMap.setClippingEnabled(false);
    scrollableMap.isMapScroll = true;
    scrollableMap.debugId = "Scroll";

    scrollableMap.touchZoomDisabled = true;
    scrollableMap.touchScrollDisabled = true;

    this.scroller.onDiscoverScrollView = function () {
        return scrollableMap;
    };

    this.scroller.getScrollZoom = function () {
        return scrollableMap.zoomHandler.normalizeZoom(scrollableMap.zoom);
    };

    var scene = cleverapps.scenes.getRunningScene();
    this.scroller.isScrollProcessing = scene.createListener(function () {
        return scrollableMap.scrollHandler.isProcessing();
    });

    scrollableMap.setLocalZOrder(-1);

    scrollableMap.updateVisibility();

    scrollableMap.zoomHandler.setZoom((cleverapps.silentIntro ? 1 : 0.8) * Map2dScroller.currentScroller.getBasicZoom(), true);

    scrollableMap.onUpdatePositionListener = scrollableMap.onUpdateZoomListener = scene.createListener(function () {
        this.map2d.onPositionChanged();
        onPositionChanged();
    }.bind(this));

    return scrollableMap;
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    MERGE_BONUS_TEXT: {
        size: 45,
        color: cleverapps.styles.COLORS.COLOR_RED,
        stroke: {
            color: cleverapps.styles.COLORS.WHITE,
            size: 2
        }
    }
});

cleverapps.styles.Map2dView = {
    cell: {
        width: 174,
        height: 100
    }
};
