/**
 * Created by mac on 12/24/20
 */

var Mineable = function (unit, options) {
    UnitComponent.call(this, unit);

    var unitData = this.unit.getData();

    this.mined = options.mined || 0;
    this.state = options.state || (this.unit.getData().mined ? Mineable.STATES.FINISHED : Mineable.STATES.WAIT);
    this.endless = unitData.mineable[0].endless;

    if ((this.state === Mineable.STATES.FINISHED || cleverapps.gameModes.initMineableWithPrizes) && !this.isCompleted() && !unit.getData().manualPrizes) {
        this.unit.setPrizes(this.listPrize(), this.getTask().exp);
    }

    // Migration for stuck
    if (!unitData.monster && this.state === Mineable.STATES.FINISHED && unit.prizes === undefined) {
        this.state = Mineable.STATES.WAIT;
    }

    if (this.isCompleted()) {
        this.onNeedToBeRemoved();
    }
};

Mineable.prototype = Object.create(UnitComponent.prototype);
Mineable.prototype.constructor = Mineable;

Mineable.prototype.onPrizeHarvested = function () {
    this.nextPosition();
};

Mineable.prototype.onNeedToBeRemoved = function () {
    if (this.isCompleted() || cleverapps.gameModes.easyChop) {
        Map2d.mapEvent(Map2d.MINE, { affected: this.unit });

        this.unit.movable = false;
        this.timeout = setTimeout(function () {
            delete this.timeout;

            var generator = this.unit.findComponent(GeneratorComponent);
            var monster = this.unit.findComponent(Monster);
            if (generator) {
                generator.gotoNextLevel();
            } else if (monster) {
                monster.die();
            } else {
                var growing = this.unit.findComponent(Growing);
                if (growing) {
                    growing.remove();
                }
                this.unit.onDestruction();
                this.unit.remove(true);

                Map2d.currentMap.blockedGrounds.updateBlockedGrounds();
            }
        }.bind(this), 1000);
    }
};

Mineable.prototype.onInfoOpened = function () {
    this.onUpdateState();
};

Mineable.prototype.onInfoClosed = function (withAction) {
    if (!withAction) {
        this.onUpdateState();

        var task = this.getTask();
        if (task && task.energy && !cleverapps.lives.canTake(task.energy) && Game.currentGame.specialEnergyOffer.isReady()) {
            Game.currentGame.specialEnergyOffer.wantsToShow();
        }
    }
};

Mineable.prototype.onUpdateState = function () {
    if (this.view) {
        this.view.restoreState(true);
    }
};

Mineable.prototype.onRemoveFreePrizeView = function () {
    if (this.view) {
        this.view.removeFreePrize(true);
    }
};

Mineable.prototype.handleClick = function () {
    if (this.endless) {
        this.startMine();
        return true;
    }

    if (this.isFree()) {
        if (!Map2d.currentMap.dragging && Game.currentGame.tutorial.isInfoAvailable(this.unit)) {
            this.unit.squeeze();
            this.startMine();
        }
        return true;
    }
};

Mineable.prototype.save = function (data) {
    if (this.mined) {
        data.mined = this.mined;
    }

    if (this.state !== Mineable.STATES.WAIT) {
        data.state = this.state;
    }
};

Mineable.prototype.listPrize = function () {
    if (!this.endless) {
        var generator = this.unit.findComponent(GeneratorComponent);
        this.unit.seedRandom(this.mined + (generator ? generator.changedLevels * 10 : 0));
    }

    var info = Mines[this.unit.getType()](this.mined, this.unit);
    if (info) {
        return Prizes.Generate(cleverapps.toArray(info));
    }
    return [];
};

Mineable.prototype.listAvailablePrizes = function () {
    var info = Mines[this.unit.getType()](this.mined, this.unit);
    return Prizes.Generate(cleverapps.toArray(info), { listOnly: true });
};

Mineable.prototype.getTask = function () {
    var task = cleverapps.clone(this.unit.getData().mineable[this.mined], true);

    var page = cleverapps.travelBook.getCurrentPage();
    if (!page.withEnergy()) {
        task.energy = 0;
    }

    return task;
};

Mineable.prototype.isFree = function () {
    if (!cleverapps.travelBook.getCurrentPage().withEnergy()) {
        return false;
    }

    var task = this.unit.getData().mineable[this.mined];
    if (!task || task.energy !== 0) {
        return false;
    }

    if (this.unit.prizes) {
        return false;
    }

    if (Map2d.currentMap.workers.findAssigned(this.unit)) {
        return false;
    }

    var locked = this.unit.findComponent(LockedComponent);
    if (locked && (locked.isLockActive() || locked.isUnlockInProcess())) {
        return false;
    }

    return true;
};

Mineable.prototype.startMine = function () {
    if (this.handleNoSpaceOnStart()) {
        return false;
    }

    if (Map2d.currentMap.workers.findAssigned(this.unit)) {
        return false;
    }

    if (Map2d.currentMap.workers.findInstantAssigned(this)) {
        return false;
    }

    if (this.isCompleted()) {
        return false;
    }

    var task = this.getTask();
    if (!task) {
        cleverapps.throwAsync("no task for mineable " + this.unit.code + "_" + this.unit.stage + "_" + this.mined + " " + this.unit.x + "_" + this.unit.y);
        return false;
    }

    var worker = Map2d.currentMap.workers.findFree(this.unit);
    if (!worker) {
        Game.currentGame.workersBusyHint(this.unit);
        return false;
    }

    if (!Game.currentGame.confirmEnoughEnergy(task.energy)) {
        return false;
    }

    if (!this.endless && !worker.assign(this.unit, cleverapps.parseInterval(task.time))) {
        return false;
    }

    if (task.energy) {
        Game.currentGame.useEnergy(task.energy, this.unit);
    }

    Map2d.mapEvent(Map2d.START_MINING, { unit: this.unit });

    this.onRemoveFreePrizeView();

    if (this.endless) {
        this.completeMining();
    }

    var tutorialStep = cleverapps.tutorial.getCurrentActiveStep();
    if (tutorialStep && tutorialStep.name === "mine") {
        tutorialStep.execute();
    }

    return true;
};

Mineable.prototype.handleNoSpaceOnStart = function () {
    return false;
};

Mineable.prototype.completeMining = function () {
    this.onRemoveFreePrizeView();

    var data = this.unit.getData();

    if (data.manualPrizes) {
        this.state = Mineable.STATES.FINISHED;
        this.unit.save();
    } else {
        var prizes = this.listPrize();
        this.state = (!prizes || prizes.length === 0) ? Mineable.STATES.WAIT : Mineable.STATES.FINISHED;
        this.unit.setPrizes(prizes, this.getTask().exp);
    }

    Map2d.mapEvent(Map2d.COMPLETE_MINING, {
        unit: this.unit,
        task: this.getTask()
    });

    if (data.autoCollect || cleverapps.gameModes.easyChop || this.endless) {
        this.unit.takePrizes();
    }
};

Mineable.prototype.isCompleted = function () {
    return this.mined >= this.countStages();
};

Mineable.prototype.countStages = function () {
    return this.unit.getData().mineable.length;
};

Mineable.prototype.nextPosition = function () {
    if (!this.endless) {
        this.mined++;
    }

    this.state = Mineable.STATES.WAIT;
    this.unit.save();

    this.onUpdateState();
};

Mineable.prototype.destructor = function () {
    if (this.timeout) {
        clearTimeout(this.timeout);
        delete this.timeout;
    }
};

Mineable.prototype.getActionInfo = function () {
    var locked = this.unit.findComponent(LockedComponent);
    if (locked && (locked.isLockActive() || locked.isUnlockInProcess())) {
        return;
    }

    if (this.state !== Mineable.STATES.WAIT || this.isCompleted()) {
        return;
    }

    if (this.endless) {
        this.startMine();
        return;
    }

    var data = this.unit.getData();
    var duration = cleverapps.parseInterval(this.getTask().time);
    var generator = this.unit.findComponent(GeneratorComponent);
    var info = {
        simpleInfo: data.simpleInfo,
        title: this.unit,
        duration: duration,
        generator: generator && generator.getGeneratorInfo(),
        buttons: {
            energy: {
                isQuick: duration === 0,
                action: this.startMine.bind(this),
                energy: this.getTask().energy
            }
        }
    };

    info.progress = {
        value: this.mined,
        total: data.mineable.length
    };

    return info;
};

Mineable.prototype.lockStateUpdate = function () {
    this.onUpdateState();
};

Mineable.STATES = {
    WAIT: 0,
    FINISHED: 1
};
