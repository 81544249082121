/**
 * Created by andrey on 20.02.2021.
 */

var FogBlockedGround = function (fogs) {
    FogBlock.call(this, FogBlockedGround.ID, fogs);

    this.state = FogBlock.CANTOPEN;
    this.showUpActions = [];

    if (!cleverapps.config.adminMode || cleverapps.config.wysiwygMode) {
        this.updateBlockedGrounds();
    }
};

FogBlockedGround.prototype = Object.create(FogBlock.prototype);
FogBlockedGround.prototype.constructor = FogBlockedGround;

FogBlockedGround.prototype.click = function () {
    cleverapps.centerHint.createTextHint("FogBlockedGround.clickMessage");
};

FogBlockedGround.prototype.updateBlockedGrounds = function (callback) {
    callback = callback || function () {};
    var BLOCKED = 1;
    var AVAILABLE = 2;

    var used = {};
    var dirs = ISO_NEIGHBORS;

    var queue = [];
    var index = 0;

    var map = this.fogs.map2d;

    var processCell = function (index) {
        var current = queue[index].cell;
        var status = queue[index].status;

        var usedStatus = used[current.x + current.y * map.getWidth()] || 0;

        if (status <= usedStatus || !map.isGround(current.x, current.y) && !map.isWaterUnit(current.x, current.y)) {
            return;
        }

        var fogTile = map.getFog(current.x, current.y);
        if (fogTile && fogTile.fogBlock !== this) {
            return;
        }

        var unit = map.getUnit(current.x, current.y);
        if (unit) {
            unit = unit.head || unit;
        }

        var fakeUnit = fogTile && fogTile.getFakeUnit();
        if (fakeUnit) {
            fakeUnit = fakeUnit.head || fakeUnit;
            if (map.getFog(fakeUnit.x, fakeUnit.y)) {
                unit = fakeUnit;
            }
        }

        if (unit) {
            var data = Families[unit.code].units[unit.stage];
            var shape = Unit.GetShape(unit);
            if (shape.length > 1) {
                shape.forEach(function (part) {
                    queue.push({
                        cell: cc.p(unit.x + part.x, unit.y + part.y),
                        status: status
                    });
                });
            }
        }

        used[current.x + current.y * map.getWidth()] = status;

        if (unit) {
            if (unit instanceof Unit && unit.isGrounded() || unit.grounded || data.stopsfog) {
                status = BLOCKED;
            }
        }

        for (var i = 0; i < dirs.length; i++) {
            queue.push({
                cell: cc.p(current.x + dirs[i].x, current.y + dirs[i].y),
                status: status
            });
        }
    }.bind(this);

    Object.keys(this.fogs.config).forEach(function (code) {
        var head = map.regions[code] && map.regions[code].head;
        var config = this.fogs.config[code];
        if (!head || used[head.x + head.y * map.getWidth()] && !config.money) {
            return;
        }

        queue.push({
            cell: head,
            status: AVAILABLE
        });

        while (index < queue.length) {
            processCell(index++);
        }
    }.bind(this));

    var units = map.listAvailableUnits(function (unit) {
        return map.isGround(unit.x, unit.y) && !map.getFog(unit.x, unit.y);
    });

    units.forEach(function (unit) {
        queue.push({
            cell: {
                x: unit.x,
                y: unit.y
            },
            status: AVAILABLE
        });

        while (index < queue.length) {
            processCell(index++);
        }
    });

    for (var y = 0; y < map.getHeight(); y++) {
        for (var x = 0; x < map.getWidth(); x++) {
            if (used[x + y * map.getWidth()] === BLOCKED && !map.getFog(x, y)) {
                this.add(x, y);
            }
        }
    }

    var openTiles = this.tiles.filter(function (tile) {
        return used[tile.x + tile.y * map.getWidth()] !== BLOCKED;
    });

    this.tiles = this.tiles.filter(function (tile) {
        return used[tile.x + tile.y * map.getWidth()] === BLOCKED;
    });

    this.showUpActions = [];
    callback = cleverapps.wait(openTiles.length, callback);

    openTiles.forEach(function (tile) {
        var fakeUnit = tile.getFakeUnit();
        if (fakeUnit && fakeUnit.code && Families[fakeUnit.code].units[fakeUnit.stage].hasShowUp) {
            this.showUpActions.unshift(function (f) {
                tile.open(false, function () {
                    f();
                    callback();
                });
            });
        } else {
            tile.open(false, callback);
        }
    }.bind(this));

    this.processFresh();
};

FogBlockedGround.prototype.processFresh = function () {
    if (!this.showUpActions.length || cleverapps.focusManager.isFocused()) {
        return;
    }

    cleverapps.focusManager.display({
        focus: "ShowUpUnits",
        actions: this.showUpActions
    });
    this.showUpActions = [];
};

FogBlockedGround.ID = "blockedGroundFog";