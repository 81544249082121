/**
 * Created by iamso on 24.12.2021
 */

var PortalView = cc.Node.extend({
    ctor: function (portal, unitView) {
        this._super();

        this.portal = portal;
        this.unitView = unitView;

        this.createGuideButton();
        this.restoreState();
        cleverapps.bundleLoader.ensureSoundsLoaded(bundles.merge.urls.portal_running_effect);
    },

    createGuideButton: function () {
        if (cleverapps.gameModes.hideGuideAndProgress) {
            return;
        }

        this.guideButton = new cleverapps.UI.HelpButton({
            type: cleverapps.styles.UI.Button.Images.help_button_unit,
            callback: function () {
                this.portal.openGuideWindow();
            }.bind(this) 
        });
        this.guideButton.setPositionRound(cleverapps.styles.PortalView.guideButton);
        this.addChild(this.guideButton);
    },

    restoreState: function (animated) {
        if (this.portal.running) {
            this.setRunning(animated);
        } else {
            this.setIdling(animated);
        }

        if (this.portal.running && this.portal.getTimeLeft() > 0) {
            this.createTimer(animated);
        } else {
            this.removeTimer(animated);
        }
    },

    setRunning: function (animated) {
        if (!animated) {
            this.unitView.getAnimation().setAnimation(0, "animation", true);
        } else {
            this.unitView.getAnimation().setAnimationAndIdleAfter("close", "animation");

            cleverapps.audio.playSound(bundles.merge.urls.portal_running_effect);
        }
    },

    setIdling: function (animated) {
        if (!animated) {
            this.unitView.getAnimation().setAnimation(0, "idle", true);
        } else {
            this.unitView.getAnimation().setAnimationAndIdleAfter("open", "idle");
        }
    },

    createTimer: function (animated) {
        if (this.unitView.getAdditionalView("timer")) {
            return;
        }

        var timer = new ComponentTimer(new cleverapps.CountDown(this.portal.getTimeLeft()), Portal.REFRESH_DURATION);
        timer.setPositionRound(cleverapps.styles.PortalView.timer);

        if (!animated) {
            this.unitView.createAdditionalView("timer", timer);
        } else {
            this.runAction(new cc.Sequence(
                new cc.DelayTime(0.1),
                new cc.CallFunc(function () {
                    this.unitView.animateAppearAdditionalView("timer", timer);
                }.bind(this))
            ));
        }
    },

    removeTimer: function (animated) {
        this.unitView.removeAdditionalView("timer", !animated);
    },

    teleportUnit: function (unit) {
        this.restoreState(true);

        this.runAction(new cc.Sequence(
            new cc.DelayTime(1),
            new cc.CallFunc(function () {
                var bubble = new PocketBubbleView(unit);
                this.addChild(bubble);
                bubble.setPositionRound(cleverapps.styles.PortalView.bubble);
                bubble.setScale(0);

                bubble.runAction(new cc.Sequence(
                    new cc.Spawn(
                        new cc.ScaleTo(0.5, 1.2),
                        new cc.MoveBy(0.5, cleverapps.styles.PortalView.bubble.showUp.x, cleverapps.styles.PortalView.bubble.showUp.y)
                    ),
                    AnimationsLibrary.animateCollect(bubble, "mainWorld", {
                        adjustToTarget: true,
                        collectEffect: true,
                        sound: bundles.main.urls.coins_drop_effect
                    }),
                    new cc.CallFunc(function () {
                        cleverapps.toolbar.resetByType(ToolbarItem.TRAVEL_BOOK);
                    }),
                    new cc.RemoveSelf()
                ));
            }.bind(this))
        ));
    }
});

cleverapps.styles.PortalView = {
    bubble: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 30 },
        showUp: {
            x: 0,
            y: 100
        }
    },

    guideButton: {
        x: { align: "center", dx: 90 },
        y: { align: "center", dy: 90 }
    },

    timer: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 20 }
    }
};
