/**
 * Created by razial on 19.10.2021
 */

cleverapps.SkinManager.SKINS.adventure_expedition = cleverapps.SkinManager.SKINS.adventure2_expedition = {
    bundle: "expedition_adventure",

    slots: Object.assign({}, cleverapps.SkinManager.SKINS.rose_energy.slots, {
        gameAudio: function () {
            return bundles.expedition_adventure.urls.adventure_music;
        },

        prizeView: function () {
            return "adventure";
        },

        getPerson: function () {
            return "_adventure";
        },

        isometricExplode: function () {
            return true;
        },

        workerCutTime: function () {
            return 1333;
        },

        noReceiveWorkerWindow: function () {
            return true;
        },

        useLongJumpAnimation: function () {
            return true;
        },

        walkingWorkersSkins: function () {
            return ["alpha", "beta", "gamma"];
        },

        bonusWorkerWindowJson: function () {
            return bundles.workerwindow.jsons.product_adventure;
        },

        bonusWorkerWindowSkin: function () {
            return ["alpha", "beta", "gamma"];
        },

        unitIcon: function (unit) {
            return bundles.unit_icons_adventure.frames["small_icon_family_" + unit.code + "_" + unit.stage]
                || bundles.unit_icons_adventure.frames["small_icon_family_" + unit.code];
        },

        roomToneEffect: function () {
            return bundles.merge.urls.room_tone_effect;
        },

        worker_json: bundles.merge_adventure.jsons.worker_json
    })
};
