/**
 * Created by Andrey Popov on 2/24/22.
 */

Telly.PRIZES.undersea = [
    [{
        units: [
            { currentType: "searesource", stage: 0 },
            { currentType: "seahero", stage: 0 },
            { code: "worker", stage: 0 }
        ]
    }],
    [{
        units: [
            { currentType: "searesource", stage: 1 },
            { currentType: "seahero", stage: 1 },
            { code: "worker", stage: 1 },
            { code: "seamagicplant", stage: 1 }
        ]
    }],
    [{
        units: [
            { currentType: "searesource", stage: 2 },
            { currentType: "seahero", stage: 2 },
            { code: "worker", stage: 2 },
            { code: "seamagicplant", stage: 1 },
            { code: "crystal", stage: 1 }
        ]
    }]
];

Rudolf.DATA.undersea = {
    maxRewards: 5,

    values: {
        seaanchor: [1, 2, 5, 15, 30, 60],
        seawheel: [1, 2, 5, 15, 30, 60],
        seaspyglass: [1, 2, 5, 15, 30, 60],
        seapiratehook: [1, 2, 5, 15, 30, 60]
    },

    rewards: [
        { value: 95, unit: { code: "seashopchest", stage: 1 } },
        { value: 60, unit: { code: "seafreechest", stage: 2 } },
        { value: 48, unit: { code: "worker", stage: 4 } },
        { value: 48, unit: { code: "crystal", stage: 3 } },
        { value: 30, unit: { chest: "searesource", stage: 2 } },
        { value: 26, unit: { code: "crystal", stage: 2 } },
        { value: 20, unit: { code: "worker", stage: 2 } },
        { value: 17, unit: { code: "crystal", stage: 2 } },
        { value: 17, unit: { code: "worker", stage: 2 } },
        { value: 17, unit: { code: "seafreechest", stage: 0 } },
        { value: 16, unit: { code: "seagrowing", stage: 3 } },
        { value: 16, unit: { code: "rubies", stage: 1 } },
        { value: 16, unit: { chest: "searesource", stage: 0 } },
        { value: 8, unit: { code: "seagrowing", stage: 2 } },
        { value: 8, unit: { code: "rubies", stage: 0 } },
        { value: 6, unit: { code: "bppointsstar", stage: 0 } },
        { value: 6, unit: { code: "crystal", stage: 0 } },
        { value: 5, unit: { code: "worker", stage: 0 } },
        { value: 3, unit: { family: "searesource", stage: 0 } },
        { value: 3, unit: { hero: "seahero", stage: 0 } },
        { value: 2, unit: { code: "coins", stage: 0 } },
        { value: 2, unit: { code: "seagrowing", stage: 1 } },
        { value: 1, unit: { code: "seagrowing", stage: 0 } }
    ]
};

MissionTree.PRIZES.undersea = [{
    amount: 5,
    units: [
        { code: "seagrowing", stage: 1 },
        { code: "seagrowing", stage: 1 },
        { code: "seagrowing", stage: 1 },
        { code: "seagrowing", stage: 1 },
        { code: "seagrowing", stage: 2 },
        { code: "seaanchor", stage: 0 },
        { code: "seawheel", stage: 0 },
        { code: "seaspyglass", stage: 0 },
        { code: "seapiratehook", stage: 0 },
        { code: "seaanchor", stage: 1 },
        { code: "seawheel", stage: 1 },
        { code: "seaspyglass", stage: 1 },
        { code: "seapiratehook", stage: 1 }
    ]
}];

Ruins.TIERS.undersea = {
    cheap: {
        soft: 59,
        rewards: [
            { code: "seafreechest", stage: 0, amount: 1 },
            { code: "worker", stage: 0, amount: 1 },
            { code: "crystal", stage: 0, amount: 1 }
        ]
    },
    costly: {
        hard: 249,
        rewards: [
            { code: "seashopchest", stage: 1, amount: 1 },
            { code: "crystal", stage: 3, amount: 1 },
            { code: "seamagicplant", stage: 5, amount: 1 },
            { code: "worker", stage: 4, amount: 1 }
        ]
    }
};

GrowingsPlanner.CONFIG.undersea = {
    fog: "fog5",
    maxAmount: 7,
    betweenTimeouts: ["5 minutes", "10 minutes", "15 minutes", "30 minutes", "1 hour"],
    units: [{
        code: "seagrowing",
        stage: 1
    }]
};

Landmarks.WORLDS.undersea = {
    fog: "fog5",
    force: Forces.LANDMARK_DONOR_SLOT2,
    landmarks: [{
        code: "landmark",
        stage: 2,
        targetExpedition: "main",
        donors: "seafisherman"
    }]
};

var UnderseaSourceMine = {
    prize: function (level, unit) {
        var sourceAmounts = [2, [2, 3], [3, 4], [4, 5], [5, 6]];
        var chestStages = [0, 0, 0];

        if (level === unit.getData().mineable.length - 1) {
            return [{
                units: [{ code: unit.getResource() + "chest", stage: chestStages[unit.stage] }]
            }];
        }

        return [{
            amount: sourceAmounts[level],
            units: [{ code: unit.getResource(), stage: 0 }]
        }, {
            amount: [1, 3],
            units: [{ code: "seasnail", stage: 0 }]
        }];
    }
};

var UnderseaFruitMine = {
    prize: function (level, unit) {
        return [{
            amount: 5,
            probabilities: [0.166, 0.166, 1],
            units: [
                { code: unit.code, stage: 1 },
                { code: "coins", stage: 0 },
                { code: unit.code, stage: 0 }
            ]
        }];
    }
};

var UnderseaGeneratorMine = {
    prize: function (level, unit) {
        return [{
            amount: 3,
            probabilities: [0.75, 0.25],
            units: [
                { code: unit.getResource() + "source", stage: 0 },
                { code: unit.getResource(), stage: 0 }
            ]
        }, {
            amount: [2, 5],
            units: [
                { code: "seasnail", stage: 0 },
                { code: "seasnail", stage: 1 }
            ]
        }];
    }
};

Mines.seafruit = UnderseaFruitMine.prize;
Mines.seasource = UnderseaSourceMine.prize;
Mines.seagrowing = WandsGrowing.prize;
Mines.seagenerator = UnderseaGeneratorMine.prize;
