/**
 * Created by andrey on 20.05.2022.
 */

var Bank = function (unit, options) {
    UnitComponent.call(this, unit);

    this.mined = options.mined || 0;
    this.lastHarvested = options.lh && cleverapps.expandTime(options.lh) || 0;

    if (cleverapps.config.editorMode || cleverapps.config.adminMode || cleverapps.config.wysiwygMode) {
        return;
    }

    cleverapps.adsLimits.on("update", this.update.bind(this), this);

    this.setState(options.state || Bank.STATES.WAIT);
    this.update();
};

Bank.prototype = Object.create(UnitComponent.prototype);
Bank.prototype.constructor = Bank;

Bank.prototype.onPrizeHarvested = function () {
    this.nextPosition();
};

Bank.prototype.onUpdateState = function () {
    if (this.view) {
        this.view.restoreState(true);
    }

    var pulsing = this.unit.findComponent(Pulsing);
    pulsing.setActive(this.state === Bank.STATES.READY);
};

Bank.prototype.destructor = function () {
    runCleaners(this);
    this.clearTimeout();
};

Bank.prototype.getTimeLeft = function () {
    return Math.max(this.getHarvestedTimeLeft(), this.getLimitTimeLeft());
};

Bank.prototype.getHarvestedTimeLeft = function () {
    return Math.max(0, this.lastHarvested + Bank.TIMEOUT - Date.now());
};

Bank.prototype.getLimitTimeLeft = function () {
    return cleverapps.adsLimits.state(AdsLimits.TYPES.BANK) === AdsLimits.STATE_LIMITED && cleverapps.rewardedAdsManager.isRewardedSupported()
        ? cleverapps.adsLimits.getLimitLeftTime(AdsLimits.TYPES.BANK) : 0;
};

Bank.prototype.getClearTimeLeft = function () {
    return Math.max(0, this.lastHarvested + Bank.CLEAR_TIMEOUT - Date.now());
};

Bank.prototype.isWithinLimits = function () {
    return cleverapps.adsLimits.state(AdsLimits.TYPES.BANK) === AdsLimits.STATE_READY;
};

Bank.prototype.update = function () {
    if (this.state === Bank.STATES.WATCHED) {
        return;
    }

    if (this.getClearTimeLeft() === 0) {
        this.lastHarvested = 0;
        this.mined = 0;
    }

    var timeLeft = this.getTimeLeft();
    var state = Bank.STATES.READY;

    if (timeLeft > 0) {
        state = Bank.STATES.WAIT;
    } else if (cleverapps.rewardedAdsManager.isRewardedSupported() && !this.isWithinLimits()) {
        state = Bank.STATES.WAIT_ADS;
    }

    this.setState(state);

    this.clearTimeout();

    if (state === Bank.STATES.WAIT) {
        this.timeout = new cleverapps.LongTimeout(this.update.bind(this), timeLeft);
        this.onUpdateState();
    }
};

Bank.prototype.setState = function (state) {
    if (this.state === state) {
        return;
    }

    this.state = state;

    var prizes = [Bank.STATES.WAIT, Bank.STATES.WAIT_ADS].includes(this.state)
        ? undefined : this.listPrize();

    this.unit.setPrizes(prizes);

    this.onUpdateState();
};

Bank.prototype.handlePrizes = function () {
    if (this.state === Bank.STATES.WATCHED) {
        return;
    }

    if ([Bank.STATES.WAIT, Bank.STATES.WAIT_ADS].includes(this.state)) {
        cleverapps.centerHint.createTextHint("Bank.waitAd");
        return true;
    }

    if (!Map2d.currentMap.countEmptySlots()) {
        cleverapps.centerHint.createTextHint("Spawn.nospace", { left: 1 });
        return true;
    }

    var ads = cleverapps.rewardedAdsManager.isRewardedSupported();

    cleverapps.eventLogger.logEvent("bank-start-" + (ads ? "ads" : "noads"));

    var callback = function () {
        cleverapps.adsLimits.watch(AdsLimits.TYPES.BANK);

        this.setState(Bank.STATES.WATCHED);
        this.unit.takePrizes();
    }.bind(this);

    if (!ads) {
        callback();
    } else {
        cleverapps.rewardedAdsManager.playRewarded(AdsLimits.TYPES.BANK, callback);
    }

    return true;
};

Bank.prototype.listPrize = function () {
    var index = Math.min(this.mined, Bank.PRIZES.length - 1);
    return [Bank.PRIZES[index]];
};

Bank.prototype.nextPosition = function () {
    var prize = this.listPrize()[0];
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.BANK_COINS, { value: Families[prize.code].units[prize.stage].value || 0.33 });

    this.lastHarvested = Date.now();
    this.mined++;
    this.setState(Bank.STATES.WAIT);
    this.unit.save();
    this.update();
};

Bank.prototype.save = function (data) {
    if (this.getHarvestedTimeLeft() > 0 || this.getClearTimeLeft() > 0) {
        data.lh = cleverapps.compactTime(this.lastHarvested);
    }
    if (this.mined) {
        data.mined = this.mined;
    }
    if (this.state === Bank.STATES.WATCHED) {
        data.state = this.state;
    }
};

Bank.prototype.clearTimeout = function () {
    if (this.timeout !== undefined) {
        clearTimeout(this.timeout);
        this.timeout = undefined;
    }
};

Bank.prototype.isAdsIconVisible = function () {
    return [Bank.STATES.READY, Bank.STATES.WAIT_ADS].includes(this.state);
};

Bank.prototype.isTimerVisible = function () {
    return this.state === Bank.STATES.WAIT;
};

Bank.STATES = {
    WAIT: 0,
    READY: 1,
    WATCHED: 2,
    WAIT_ADS: 3
};

Bank.CLEAR_TIMEOUT = cleverapps.parseInterval(cleverapps.config.debugMode ? "5 minutes" : "12 hour");
Bank.TIMEOUT = cleverapps.parseInterval(cleverapps.config.debugMode ? "1 minutes" : "3 hours");

Bank.PRIZES = [
    {
        code: "coins",
        stage: 2
    },
    {
        code: "coins",
        stage: 1
    },
    {
        code: "coins",
        stage: 0
    }
];
