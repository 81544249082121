/**
 * Created by spepa on 11.01.2024
 */

QuestsConfig.china = cleverapps.clone(QuestsConfig.universal, true);

QuestsConfig.china.rpproducer2.trigger = { fog: "fog1" };

delete QuestsConfig.china.rpcustomerbridge3_0;
delete QuestsConfig.china.rpcustomerbridge3_1;
delete QuestsConfig.china.rpcustomermain3_0;
delete QuestsConfig.china.rpcustomermain3_1;
delete QuestsConfig.china.rpproducer3;
delete QuestsConfig.china.rpproduct3;

delete QuestsConfig.china.rpcustomerbridge4_0;
delete QuestsConfig.china.rpcustomerbridge4_1;
delete QuestsConfig.china.rpcustomermain4_0;
delete QuestsConfig.china.rpcustomermain4_1;
delete QuestsConfig.china.rpproducer4;
delete QuestsConfig.china.rpproduct4;

CustomerRecipes.china = cleverapps.clone(CustomerRecipes.universal, true);

Ruins.TIERS.china = cleverapps.clone(Ruins.TIERS.universal, true);

GrowingsPlanner.CONFIG.china = cleverapps.clone(GrowingsPlanner.CONFIG.universal, true);
GrowingsPlanner.CONFIG.china.betweenTimeouts = ["24 hours"];
GrowingsPlanner.CONFIG.china.maxAmount = 1;

MissionTree.PRIZES.china = [];

Landmarks.WORLDS.china = {
    fog: "fog3",
    force: Forces.LANDMARK_DONOR_SLOT1,
    landmarks: [{
        code: "landmark",
        stage: 13,
        targetExpedition: "main",
        donors: []
    }, {
        code: "landmark",
        stage: 22,
        targetExpedition: "main",
        donors: ["rpcustomermain0", "rpcustomermain1", "rpcustomermain2"]
    }]
};

cleverapps.styles.china = {
    fogBlockerLock: {
        width: 100,
        height: 100,
        margin: 25,

        content: {
            width: 68,
            height: 68
        },
        position: {
            x: { align: "center" },
            y: { align: "center", dy: 50 }
        },
        text: {
            x: { align: "center", dx: 5 },
            y: { align: "center", dy: -28 }
        },
        icon: {
            x: { align: "center", dx: 5 },
            y: { align: "center", dy: -28 }
        },
        cloud: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -39 }
        },
        lock: {
            x: { align: "center" },
            y: { align: "center" }
        }
    },
    miningAnimation: {
        x: { align: "center", dx: 0, leftDx: 0 },
        y: { align: "center", dy: 0 }
    },
    fogAsColorForUnits: {
        color: new cc.Color(0, 150, 255, 255),
        opacity: 120
    }
};