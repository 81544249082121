/**
 * Created by mac on 12/30/20
 */

var Workers = function () {
    this.regular = [];
    this.instant = [];

    this.onAddWorker = function () {};

    this.onChangeStatusListener = function () {};
    this.onChangeTotalAmount = function () {};
    this.onWorkerBought = function () {};

    this.speedupCheckInterval = new cleverapps.Interval(this.assignSpeedUpBonus.bind(this, undefined), cleverapps.parseInterval(Workers.SPEEDUP_BONUS_CHECK_INTERVAL));
    cleverapps.rewardedAdsManager.onRewardedRefresh(this.changeRewardedState.bind(this), this);
};

Workers.prototype.getInfo = function () {
    var info = {
        bw: cleverapps.compactTime(this.bonusWorkerEnd, { seconds: true })
    };

    this.regular.forEach(function (worker) {
        var workerInfo = worker.save();
        if (workerInfo) {
            Object.assign(info, workerInfo);
        }
    });

    return info;
};

Workers.prototype.destructor = function () {
    this.regular.forEach(function (worker) {
        worker.destructor();
    });

    this.clearBonusWorkerTimeout();
    clearInterval(this.speedupCheckInterval);

    runCleaners(this);
};

Workers.prototype.save = function () {
    var slot = Game.currentGame.slot;

    cleverapps.dataLoader.save(DataLoader.TYPES.WORKERS + slot, this.getInfo());
    cleverapps.synchronizer.addUpdateTask("workers" + slot);
};

Workers.prototype.load = function (options) {
    var stored = cleverapps.dataLoader.load(DataLoader.TYPES.WORKERS + Game.currentGame.slot);

    var needSave = false;
    if (!stored && Game.currentGame.isMainGame()) {
        stored = needSave = cleverapps.GameSaver.loadProperty(Meta.SLOT_MAIN, "workers");
    }

    if (options && options.isNewGame || !stored || Array.isArray(stored)) {
        stored = {};
        needSave = true;
    }

    this.bonusWorkerEnd = cleverapps.expandTime(stored.bw, { seconds: true }) || stored.bonusWorkerEnd;

    var workers = stored.regular || [];

    Object.keys(stored).forEach(function (key) {
        if (!parseInt(key)) {
            return;
        }

        var info = {};
        info[key] = stored[key];
        workers.push(info);
    });

    this.regular = [];

    workers.forEach(function (worker) {
        this.regular.push(new GameWorker(this.chooseFreeSkin(), worker));
    }.bind(this));

    this.instant = [];

    if (needSave) {
        this.save();
    }

    this.assignSpeedUpBonus();
};

Workers.prototype.runSpecialWorkers = function () {
    this.runBonusWorkerTimeout();

    if (cleverapps.subscription && cleverapps.subscription.isActive()) {
        this.addSubscriptionWorker(true);
    }

    this.adjustRegularIfNeeded(true);
};

Workers.prototype.setBonusWorkerEnd = function (bonusWorkerEnd) {
    if (this.bonusWorkerEnd === bonusWorkerEnd) {
        return;
    }

    this.bonusWorkerEnd = bonusWorkerEnd;
    this.save();
};

Workers.prototype.removeBonusWorker = function () {
    this.setBonusWorkerEnd(undefined);
    this.clearBonusWorkerTimeout();
    this.adjustRegularIfNeeded();
};

Workers.prototype.bonusWorkerLeftTime = function () {
    if (!this.bonusWorkerEnd) {
        return 0;
    }
    return this.bonusWorkerEnd - Date.now();
};

Workers.prototype.clearBonusWorkerTimeout = function () {
    if (this.bonusWorkerTimeout) {
        clearTimeout(this.bonusWorkerTimeout);
        this.bonusWorkerTimeout = undefined;
    }
};

Workers.prototype.runBonusWorkerTimeout = function () {
    this.clearBonusWorkerTimeout();

    var leftTime = this.bonusWorkerLeftTime();
    if (leftTime > 0) {
        this.bonusWorkerTimeout = new cleverapps.LongTimeout(this.runBonusWorkerTimeout.bind(this), leftTime);
    } else {
        this.removeBonusWorker();
    }
};

Workers.prototype.isAdsWorkerAvailable = function () {
    return !this.isBonusWorkerBuyed() && this.countRegularTotal() <= this.countRegularLimit();
};

Workers.prototype.isFreeWorkerAvailable = function () {
    var level = cleverapps.user.getFloatLevel();
    return !this.isBonusWorkerBuyed() && level > 5 && level < 8 && !cleverapps.dataLoader.load(DataLoader.TYPES.FREE_WORKER + Game.currentGame.slot) && !cleverapps.skins.getSlot("noReceiveWorkerWindow");
};

Workers.prototype.freeWorkerGained = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.FREE_WORKER + Game.currentGame.slot, true);
};

Workers.prototype.isBonusWorkerBuyed = function () {
    return this.bonusWorkerLeftTime() > 0;
};

Workers.prototype.addBonusWorkerPeriod = function (period, silent) {
    if (!this.isBonusWorkerBuyed()) {
        this.setBonusWorkerEnd(Date.now() + period);
    } else {
        this.setBonusWorkerEnd(this.bonusWorkerEnd + period);
    }
    this.runBonusWorkerTimeout();
    this.adjustRegularIfNeeded(silent);
    this.save();
};

Workers.prototype.getProduct = function () {
    return Product.CreateByHardPrice(Workers.BONUS_WORKER_PRICE, {
        type: "worker",
        expedition: cleverapps.travelBook.getCurrentPage().id
    });
};

Workers.prototype.getPriceTag = function () {
    return this.getProduct().getCurrentPrice();
};

Workers.prototype.buyBonusWorker = function () {
    this.getProduct().buy(this.processWorkerBought.bind(this), { noRewardWindow: true });
};

Workers.prototype.processWorkerBought = function (success) {
    if (!success) {
        return;
    }

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.SPENT.WORKER);

    new RewardWindow({
        worker: Workers.BONUS_WORKER_PERIOD
    });

    if (cleverapps.skins.getSlot("walkingWorkersSkins")) {
        this.wantsToShowBoughtWorker = true;
    }

    this.onWorkerBought();
};

Workers.prototype.watchAdsBonusWorker = function () {
    cleverapps.rewardedAdsManager.playRewarded(AdsLimits.TYPES.WORKER, function () {
        cleverapps.adsLimits.watch(AdsLimits.TYPES.WORKER);

        cleverapps.focusManager.display({
            stack: true,
            focus: "watchAdsBonusWorker",
            action: function (f) {
                new RewardWindow({ worker: Workers.ADS_BONUS_WORKER_PERIOD });
                cleverapps.focusManager.onceNoWindowsListener = f;
            }
        });

        if (cleverapps.skins.getSlot("walkingWorkersSkins")) {
            this.wantsToShowBoughtWorker = true;
        }

        this.onWorkerBought();
    }.bind(this));
};

Workers.prototype.showBoughtWorker = function () {
    this.wantsToShowBoughtWorker = false;

    var freshWorker;

    for (var i = 0; i < this.regular.length; ++i) {
        var worker = this.regular[i];

        if (!worker.isBusy() && !worker.hasPosition()) {
            freshWorker = worker;
            break;
        }
    }

    if (!freshWorker) {
        return;
    }

    var centerCell = Map2d.currentMap.getScreenCenterCell();
    var workerCell = freshWorker.findNearestAvailableCell(centerCell);
    if (!workerCell) {
        return;
    }

    cleverapps.focusManager.display({
        focus: "showBoughtWorker",
        actions: [
            function (f) {
                freshWorker.move(workerCell);

                Map2d.currentMap.focusOnUnit(workerCell, {
                    allowScrollWithFocus: true,
                    callback: f
                });
            }
        ]
    });
};

Workers.prototype.addSubscriptionWorker = function (silent) {
    if (!cleverapps.travelBook.isExpedition() && Subscription.IsAvailable() && cleverapps.subscription.isActive()) {
        this.adjustRegularIfNeeded(silent);
        this.save();
    }
};

Workers.prototype.adjustRegularIfNeeded = function (silent) {
    var limit = this.countRegularLimit();

    var removed = false, added = false;

    for (var i = 0; i < this.regular.length && this.regular.length > limit; ++i) {
        var worker = this.regular[i];
        if (!worker.isBusy()) {
            worker.destructor();
            worker.onRemove();
            this.regular.splice(i, 1);
            removed = true;
            --i;
        }
    }

    while (this.regular.length < limit) {
        this.addRegular(silent);
        added = true;
    }

    if (removed || added) {
        this.save();
    }

    if (!silent) {
        if (removed || added) {
            this.onChangeTotalAmount();
        }
    }

    this.wantsToShowBonusWorkerWindow = !this.isBonusWorkerBuyed() && removed;
};

Workers.prototype.countRegularFree = function () {
    return this.regular.filter(function (worker) {
        return !worker.isBusy() && worker.isInitialized();
    }).length;
};

Workers.prototype.countRegularTotal = function () {
    return this.regular.length;
};

Workers.prototype.countRegularLimit = function () {
    var limit = 2;

    if (this.isBonusWorkerBuyed()) {
        limit += 1;
    }

    if (!cleverapps.travelBook.isExpedition() && cleverapps.subscription && cleverapps.subscription.isActive()) {
        limit += 1;
    }
    return limit;
};

Workers.prototype.addRegular = function (silent) {
    var worker = new GameWorker(this.chooseFreeSkin());
    this.regular.push(worker);
    this.onAddWorker(worker);
    if (!silent) {
        this.onChangeTotalAmount();
    }
};

Workers.prototype.addInstant = function (worker) {
    var index = this.instant.indexOf(worker);
    if (index === -1) {
        this.instant.push(worker);
    }
};

Workers.prototype.removeInstant = function (worker) {
    var index = this.instant.indexOf(worker);
    if (index !== -1) {
        this.instant.splice(index, 1);
    }
};

Workers.prototype.hasInstant = function () {
    return this.listInstantFree().length > 0;
};

Workers.prototype.isRetiredWorker = function (worker) {
    return !this.regular.includes(worker);
};

Workers.prototype.findAssigned = function (unit) {
    if (!unit) {
        return;
    }

    for (var i = 0; i < this.regular.length; i++) {
        var worker = this.regular[i];
        if (worker.unit === unit) {
            return worker;
        }
    }
};

Workers.prototype.findInstantAssigned = function (buildable) {
    for (var i = 0; i < this.instant.length; i++) {
        var worker = this.instant[i];
        if (worker.assignment === buildable) {
            return worker;
        }
    }
};

Workers.prototype.findFree = function (cell) {
    var free = [];

    for (var i = 0; i < this.regular.length; ++i) {
        var worker = this.regular[i];
        if (!worker.isBusy() && worker.isInitialized()) {
            free.push(worker);
        }
    }

    if (!cell || !free.length) {
        return free[0];
    }

    var minDistance = undefined;
    var minWorker = undefined;

    for (i = 0; i < free.length; ++i) {
        worker = free[i];

        var distance = worker.position && cc.pDistanceSQ(cell, worker.position);
        if (minDistance === undefined || distance < minDistance) {
            minDistance = distance;
            minWorker = worker;
        }
    }

    return minWorker;
};

Workers.prototype.findLeastBusy = function (Component) {
    var leastWorker, leastTime = Infinity;
    this.listBusy().forEach(function (worker) {
        if (Component && !worker.unit.findComponent(Component)) {
            return;
        }

        if (leastTime > worker.getTimeLeft()) {
            leastWorker = worker;
            leastTime = worker.getTimeLeft();
        }
    });
    return leastWorker;
};

Workers.prototype.listBusy = function () {
    return this.regular.filter(function (worker) {
        return worker.isBusy();
    });
};

Workers.prototype.listInstantFree = function () {
    return this.instant.filter(function (worker) {
        return !worker.isWorking();
    });
};

Workers.prototype.listInstant = function () {
    return this.instant;
};

Workers.prototype.assignSpeedUpBonus = function (worker) {
    if (cleverapps.adsLimits.state(AdsLimits.TYPES.SPEEDUP) === AdsLimits.STATE_READY) {
        var workers = worker ? [worker] : this.regular;
        worker = cleverapps.Random.choose(workers.filter(function (worker) {
            return worker.isSpeedUpBonusAvailable() && !worker.isSpeedUpBonusActive();
        }));

        if (worker) {
            worker.setSpeedUpBonus();
            cleverapps.adsLimits.watch(AdsLimits.TYPES.SPEEDUP);
        }
    }
};

Workers.prototype.changeRewardedState = function () {
    if (!cleverapps.rewardedAdsManager.isRewardedAvailable()) {
        this.regular.forEach(function (worker) {
            worker.clearBonus();
        });
    }
};

Workers.prototype.placeNotPositionedWorkers = function (fogId) {
    if (!cleverapps.skins.getSlot("walkingWorkersSkins")) {
        return;
    }

    var workers = [];
    for (var i = 0; i < this.regular.length; ++i) {
        var worker = this.regular[i];

        if (!worker.isBusy() && !worker.hasPosition()) {
            workers.push(worker);
        }
    }

    if (!workers.length) {
        return;
    }

    var map = Map2d.currentMap;

    var cells = (map.regions[fogId] && map.regions[fogId].positions || []).filter(function (cell) {
        return map.isGround(cell.x, cell.y) && !map.getUnit(cell.x, cell.y) && !map.getFog(cell.x, cell.y);
    }).map(function (cell) {
        return cc.p(cell);
    });
    if (!cells.length) {
        return;
    }

    var centroids = this.divideByClusters(cells, workers.length);
    for (i = 0; i < workers.length; i++) {
        if (centroids[i]) {
            workers[i].move(centroids[i]);
        }
    }

    this.save();
};

Workers.prototype.divideByClusters = function (cells, k) {
    var grouped = {};
    var map2dView = Map2d.currentMap.getMapView();

    cells.forEach(function (cell) {
        var x = Math.round(map2dView.toScreen(cell.x, cell.y).x);

        if (!grouped[x]) {
            grouped[x] = [];
        }

        grouped[x].push(cell);
    });

    var clusters = [];

    Object.keys(grouped).sort().forEach(function (x, index, groups) {
        index = Math.floor(index / groups.length * k);

        if (!clusters[index]) {
            clusters[index] = [];
        }

        clusters[index] = clusters[index].concat(grouped[x]);
    });

    return clusters.map(function (cluster) {
        return cc.getCenterPoint(cluster);
    });
};

Workers.prototype.tryMove = function (cell) {
    var worker = this.findFree(cell);
    if (!worker) {
        cleverapps.centerHint.createTextHint("Workers.busy");
        return GameWorker.canMove(cell);
    }

    if (!worker.move(cell)) {
        cleverapps.centerHint.createTextHint("Workers.cantMove");
        return false;
    }

    return true;
};

Workers.prototype.chooseFreeSkin = function () {
    var skins = cleverapps.skins.getSlot("walkingWorkersSkins");
    if (!skins) {
        return;
    }

    skins = skins.reduce(function (result, skin) {
        result[skin] = 0;
        return result;
    }, {});

    this.regular.forEach(function (worker) {
        skins[worker.getSkin()] += 1;
    });

    return Object.keys(skins).sort(function (a, b) {
        return skins[a] - skins[b];
    })[0];
};

CustomSyncers.registerBySlots("workers", function (slot) {
    return cleverapps.dataLoader.load(DataLoader.TYPES.WORKERS + slot) || {};
}, function (slot, serverData) {
    cleverapps.dataLoader.save(DataLoader.TYPES.WORKERS + slot, serverData);
});

Workers.reset = function (slot) {
    if (slot === undefined) {
        Meta.SLOTS.forEach(Workers.reset);
        return;
    }

    cleverapps.dataLoader.save(DataLoader.TYPES.WORKERS + slot, {});
    cleverapps.synchronizer.addUpdateTask("workers" + slot);
};

Workers.ShowFinishedWorker = function (f) {
    var worker = Map2d.currentMap.workers.findLeastBusy();
    if (!worker || worker.getTimeLeft() > 0) {
        f();
        return;
    }

    var controls = ["MenuBarGameLevelItem", "MenuBarCoinsItem"];
    var questsGroupIcon = cleverapps.sideBar.findIconByClassName(QuestsGroupIcon);
    if (questsGroupIcon) {
        controls.push("QuestsGroupIcon");
    }

    cleverapps.focusManager.showControlsWhileFocused(controls);

    Map2d.currentMap.focusOnUnit(worker.unit, {
        allowScrollWithFocus: true
    });
    Game.currentGame.counter.setTimeout(f, questsGroupIcon ? 3500 : 1500);
};

Workers.BONUS_WORKER_PERIOD = "7 day";
Workers.ADS_BONUS_WORKER_PERIOD = "15 minutes";
Workers.FREE_BONUS_WORKER_PERIOD = "1 day";
Workers.BONUS_WORKER_PRICE = 225;

Workers.SPEEDUP_BONUS_CHECK_INTERVAL = "1 minute";
Workers.SPEEDUP_BONUS_DURATION = "15 minutes";
Workers.SPEEDUP_BONUS_TIME = "15 minutes";
Workers.SPEEDUP_BONUS_MINIMUM_REQUIRED_TIME = "1 minutes";

if (cleverapps.config.debugMode) {
    Workers.SPEEDUP_BONUS_DURATION = "30 seconds";
}

Workers.BOOST_ANIMATE_DURATION = 1250;

Restorable.BY_TYPE["worker"] = new Restorable({
    location: Restorable.LocationContextExpedition,
    listActions: function (product, context, consume) {
        if (!Map2d.currentMap || !Map2d.currentMap.workers) {
            return;
        }

        return [
            function (f) {
                consume();
                Map2d.currentMap.workers.processWorkerBought(true);
                cleverapps.focusManager.onceNoWindowsListener = f;
            }
        ];
    }
});