/**
 * Created by spepa on 26.09.2022
 */

QuestsConfig.blackfriday = cleverapps.clone(QuestsConfig.universal, true);

delete QuestsConfig.blackfriday.rpcustomerbridge4_0;
delete QuestsConfig.blackfriday.rpcustomerbridge4_1;
delete QuestsConfig.blackfriday.rpcustomermain4_0;
delete QuestsConfig.blackfriday.rpcustomermain4_1;
delete QuestsConfig.blackfriday.rpproducer4;
delete QuestsConfig.blackfriday.rpproduct4;

QuestsConfig.blackfriday.drfruit_3 = {
    trigger: { unit: { code: "dr2dragonblue", stage: 1 } },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "drfruit", stage: 3 }
    }
};

QuestsConfig.blackfriday.dr2dragonblue_1 = {
    trigger: { unit: { code: "dr2dragonblue", stage: 1 } },
    quest: {
        type: Map2d.FEED_HERO,
        unit: { code: "dr2dragonblue", stage: 1 }
    },
    dynamic: {
        getIcon: function () {
            return UnitView.getUnitImage({ code: "wands", stage: 0 }, { alignAnchorX: true, preferStatic: true });
        },
        getTitle: function () {
            return Messages.get("Quest.wands.title");
        }
    }
};

CustomerRecipes.blackfriday = cleverapps.clone(CustomerRecipes.universal, true);

Ruins.TIERS.blackfriday = cleverapps.clone(Ruins.TIERS.universal, true);

MissionTree.PRIZES.blackfriday = cleverapps.clone(MissionTree.PRIZES.universal, true);

GrowingsPlanner.CONFIG.blackfriday = cleverapps.clone(GrowingsPlanner.CONFIG.universal, true);

Landmarks.WORLDS.blackfriday = {
    fog: "fog3",
    force: Forces.LANDMARK_DONOR_SLOT1,
    landmarks: [{
        code: "landmark",
        stage: 20,
        targetExpedition: "main",
        donors: ["rpcustomermain0", "rpcustomermain1", "rpcustomermain2", "rpcustomermain3"]
    }]
};
