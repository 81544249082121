/**
 * Created by anatoly on 20.12.2024
 */

var MergeComponentView = function (component, unitView) {
    this.unitView = unitView;
};

MergeComponentView.prototype.highlightMergeError = function (affected) {
    this.highlightUnits(affected, true);
};

MergeComponentView.prototype.highlightMerge = function (affected) {
    var target = affected[0];
    this.startAnimateMerge(this.unitView);

    this.highlightUnits(affected, false, function (affectedUnit) {
        var distance = this.calculateDistance(affectedUnit, target);
        if (affectedUnit.distanceTo(target) > 2) {
            distance.dx /= 2;
            distance.dy /= 2;
        }

        this.startAnimateMerge(affectedUnit.view, distance, this.targetKey !== Unit.GetPositionKey(target));
    }.bind(this));

    this.targetKey = Unit.GetPositionKey(target);
};

MergeComponentView.prototype.calculateDistance = function (affectedUnit, target) {
    var mapView = Map2d.currentMap.getMapView();
    var targetPosition = mapView.alignInGrid(target.x, target.y);

    var x = affectedUnit.x;
    var y = affectedUnit.y;

    var position = mapView.alignInGrid(x, y);

    var dx = targetPosition.x - position.x;
    var dy = targetPosition.y - position.y;

    var length = Math.sqrt(dx * dx + dy * dy);
    if (length <= 0) {
        return;
    }

    dx = dx / length * cleverapps.styles.UnitView.merge;
    dy = dy / length * cleverapps.styles.UnitView.merge;

    if (affectedUnit.distanceTo(target) > 2) {
        dx /= 2;
        dy /= 2;
    }

    return { dx: dx, dy: dy };
};

MergeComponentView.prototype.highlightUnits = function (affected, isErrorLight, additionalAnimation) {
    this.highlightedUnits = affected;

    affected.forEach(function (p) {
        var x = p.x;
        var y = p.y;

        var affectedUnit = Map2d.currentMap.getUnit(x, y);

        var light = affectedUnit.light = new Highlight(x, y, {
            isErrorLight: isErrorLight,
            unit: this.unit
        });

        additionalAnimation && additionalAnimation(affectedUnit);

        Map2d.currentMap.onAdd(Map2d.LAYER_UNITS, x, y, light);
    }, this);
};

MergeComponentView.prototype.unhighlightMerge = function () {
    delete this.targetKey;
    if (!this.highlightedUnits) {
        return;
    }

    this.stopAnimateMerge(this.unitView);
    cleverapps.centerHint.finishCenterHint();

    this.highlightedUnits.forEach(function (p) {
        var affectedUnit = Map2d.currentMap.getUnit(p.x, p.y);

        if (!affectedUnit) {
            return;
        }

        if (affectedUnit.light) {
            affectedUnit.light.remove();
            delete affectedUnit.light;
        }

        this.stopAnimateMerge(affectedUnit.view, p);
    }, this);

    this.highlightedUnits = undefined;
};

MergeComponentView.prototype.startAnimateMerge = function (unitView, distance, isNewMerge) {
    if (!unitView) {
        return;
    }

    if (unitView.mergeAnimateAction && !unitView.mergeAnimateAction.isDone() && !isNewMerge) {
        unitView.stopAction(unitView.mergeAnimateAction);
        delete unitView.mergeAnimateAction;
    }

    var actions = [];
    actions.push(new cc.Sequence(
        new cc.ScaleTo(0.325, 1.03),
        new cc.ScaleTo(0.325, 1),
        new cc.DelayTime(0.1)
    ));

    if (distance) {
        var dx = distance.dx;
        var dy = distance.dy;

        if (cleverapps.gameModes.automerge && !Game.currentGame.advice.hintActive) {
            actions.push(new cc.Sequence(
                new cc.MoveBy(0.33, -dx, -dy).easing(cc.easeBackOut()),
                new cc.DelayTime(0.2),
                new cc.MoveBy(0.22, dx, dy).easing(cc.easeBackIn())
            ));
        } else {
            actions.push(new cc.Sequence(
                new cc.PlaySound(bundles.merge.urls.merge_hint),
                new cc.MoveBy(0.450, dx, dy).easing(cc.easeBackIn()),
                new cc.MoveBy(0.2, -dx, -dy).easing(cc.easeOut(2)),
                new cc.DelayTime(0.1)
            ));
        }
    }

    var action = actions.length === 1 ? actions[0] : new cc.Spawn(actions);
    unitView.mergeAnimateAction = unitView.runAction(new cc.RepeatForever(action));
};

MergeComponentView.prototype.stopAnimateMerge = function (unitView, unitPosition) {
    if (!unitView) {
        return;
    }

    if (unitView.mergeAnimateAction && !unitView.mergeAnimateAction.isDone()) {
        unitView.stopAction(unitView.mergeAnimateAction);
        delete unitView.mergeAnimateAction;
    }

    var actions = [];

    if (unitPosition && (!unitView.mergeAction || unitView.mergeAction.isDone())) {
        actions.push(new cc.MoveTo(0.1, Map2d.currentMap.getMapView().alignInGrid(unitPosition.x, unitPosition.y)));
    }
    actions.push(new cc.ScaleTo(0.1, 1));

    unitView.mergeAnimateAction = unitView.runAction(actions.length > 1 ? new cc.Spawn(actions) : actions[0]);
};