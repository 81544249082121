/**
 * Created by Denis Kuzin on 17 february 2022
 */

var ClansJoinRow = ClansRow.extend({
    ctor: function (row, options) {
        this._super(row, options);

        cleverapps.UI.onClick(this, function () {
            new ClanInformationWindow(row.clan);
        }, {
            interactiveScale: false
        });
    },

    createButton: function () {
        var styles = cleverapps.styles.ClansJoinRow;

        var text = "Join";
        var tooltipText;
        if (this.row.clan.isFull()) {
            text = "ClansJoinRow.full";
            tooltipText = "ClanErrors.clanIsFull";
        } else if (!this.row.clan.isAppropriateByLevel()) {
            text = Messages.get("ClansJoinRow.level") + " " + this.row.clan.requiredLevel;
            tooltipText = Messages.get("AddClanWindow.requiredLevel") + " " + this.row.clan.requiredLevel;
        }

        var button = this.button = new cleverapps.UI.Button({
            type: styles.button.type,
            text: text,
            width: styles.button.width,
            height: styles.button.height,
            disabled: !this.row.clan.isAppropriate(),
            onClicked: function () {
                if (this.button) {
                    this.button.disable();
                }

                cleverapps.clans.join(this.row.clan.id);
            }.bind(this)
        });

        if (tooltipText) {
            cleverapps.tooltipManager.create(button, {
                text: tooltipText,
                location: cleverapps.UI.Tooltip.LOCATION_BELOW
            });
        }

        return button;
    },

    createScore: function () {
        var styles = cleverapps.styles.ClansJoinRow;

        var bg = cleverapps.UI.createScale9Sprite(bundles.clan_table.frames.clan_amount_bg_png, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setContentSize2(styles.bg.width, styles.bg.height);

        if (this.row.clan.isFull()) {
            cleverapps.tooltipManager.create(bg, {
                text: "ClanErrors.clanIsFull",
                location: cleverapps.UI.Tooltip.LOCATION_BELOW
            });
        }

        var icon = new cc.Sprite(bundles.clan_table.frames.players_icon);
        icon.setPositionRound(styles.icon);
        bg.addChild(icon);

        var text = cleverapps.UI.generateImageText(this.row.clan.membersCount + "/" + ClansConfig.MAX_SIZE, cleverapps.styles.FONTS.CLANS_ROW_SCORE_TEXT);
        text.fitTo(styles.text.width);
        text.setPositionRound(styles.text);
        bg.addChild(text);

        return bg;
    }
});

cleverapps.styles.ClansJoinRow = {
    icon: {
        x: { align: "left", dx: 20 },
        y: { align: "center", dy: 0 }
    },

    text: {
        width: 140,
        x: { align: "right", dx: -20 },
        y: { align: "center", dy: 0 }
    },

    bg: {
        width: 235,
        height: 74
    },

    button: {
        width: 196,
        height: 75,
        type: cleverapps.styles.UI.Button.Images.button_green
    }
};