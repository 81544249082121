/**
 * Created by Andrey Popov on 9/23/24.
 */

var FallSaleIcon = cc.Sprite.extend({
    ctor: function (position) {
        this._super(bundles.fall_sale_icons.frames.fallsale_badge);
        this.setLocalZOrder(3);

        this.setPositionRound(cleverapps.styles.FallSaleIcon.positions[position]);
    }
});

FallSaleIcon.POSITION_BUTTON = "button";
FallSaleIcon.POSITION_MENUBAR = "menubar";

cleverapps.styles.FallSaleIcon = {
    positions: {
        menubar: {
            x: { align: "center", dx: 165 },
            y: { align: "top", dy: 2 }
        },
        button: {
            x: { align: "center", dx: 115 },
            y: { align: "top", dy: 5 }
        }
    }
};

if (cleverapps.config.ui === "tropical") {
    cleverapps.overrideStyles(cleverapps.styles.FallSaleIcon, {
        positions: {
            menubar: {
                x: { align: "center", dx: 160 },
                y: { align: "top", dy: 3 }
            },
            button: {
                x: { align: "center", dx: 135 }
            }
        }
    });
}

if (cleverapps.config.ui === "heroes") {
    cleverapps.overrideStyles(cleverapps.styles.FallSaleIcon, {
        positions: {
            menubar: {
                x: { align: "center", dx: 140 },
                y: { align: "top", dy: 5 }
            },
            button: {
                x: { align: "center", dx: 125 }
            }
        }
    });
}

if (cleverapps.config.ui === "riddles") {
    cleverapps.overrideStyles(cleverapps.styles.FallSaleIcon, {
        positions: {
            menubar: {
                x: { align: "center", dx: 160 },
                y: { align: "top", dy: 4 }
            },
            button: {
                x: { align: "center", dx: 111 },
                y: { align: "top", dy: 0 }
            }
        }
    });
}