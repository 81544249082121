/**
 * Created by spepa on 11.01.2024
 */

cleverapps.SkinManager.SKINS.china_expedition = {
    bundle: "expedition_china",

    slots: Object.assign({}, cleverapps.SkinManager.SKINS.rose_energy.slots, {
        miningEffects: function () {
            return {
                animation: "chop",
                sound: bundles.mineable.urls.mining_wood_start_effect
            };
        },

        workerSkin: function (minable) {
            return minable && minable.unit.code === "drsapphiresource" || !["mergecraft"].includes(cleverapps.config.name) ? undefined : "xm";
        },

        worker_json: function (minable) {
            return minable && minable.unit.code === "drsapphiresource" && !["fairy"].includes(cleverapps.config.name) ? bundles.merge_china.jsons.fireworks_worker : undefined;
        },

        workerMiningAnimationStyles: function (minable) {
            return minable && minable.unit.code === "drsapphiresource" && !["fairy"].includes(cleverapps.config.name) ? cleverapps.styles.china.miningAnimation : undefined;
        },

        gameAudio: function () {
            return bundles.expedition_china.urls.china_music;
        },

        gameSceneBg: function () {
            return {
                horizontalPattern: bundles.expedition_china.urls.china_bg,
                parallax: true
            };
        },

        fogAsColorForUnits: function () {
            return cleverapps.styles.china.fogAsColorForUnits;
        },

        outOfBoundaryScale: function () {
            return 0;
        },

        unitIcon: function (unit) {
            return bundles.unit_icons_china.frames["small_icon_family_" + unit.code];
        },

        avatarFramesBundle: function () {
            return cleverapps.bundleLoader.isLoaded("snail_feast_china") ? "snail_feast_china" : undefined;
        },

        fogBlocker: function () {
            return cleverapps.styles.china.fogBlockerLock;
        },
        
        feed_icon_png: bundles.buttons_inlined_icons.frames.china_feed_icon_png,
        wand_icon_png: bundles.buttons_inlined_icons.frames.china_wand_icon_png,

        pass_star_png: bundles.reward_icons_pass.frames.china_pass_star_png,

        expedition_buildpass_icon_json: bundles.sidebar.jsons.china_expedition_buildpass_icon_json,

        snail_feast_icon: bundles.reward_icons.frames.china_snail_feast_icon,
        snail_feast_icon_json: bundles.sidebar.jsons.china_snail_feast_icon_json,
        snailhouse_offer_json: bundles.sidebar.jsons.china_snailhouse_offer_json
    })
};