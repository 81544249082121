/**
 * Created by razial on 18.12.2024.
 */

Ruins.TIERS.universal = {
    cheap: {
        soft: 59,
        rewards: [
            { code: "crystal", stage: 0, amount: 1 },
            { code: "worker", stage: 0, amount: 1 },
            { code: "drfreechest", stage: 0, amount: 1 }
        ]
    },
    costly: {
        hard: 249,
        rewards: [
            { code: "crystal", stage: 3, amount: 1 },
            { code: "worker", stage: 4, amount: 1 },
            { code: "drshopchest", stage: 1, amount: 1 },
            { code: "bppointsstar", stage: 0, amount: 3 }
        ]
    }
};

MissionTree.PRIZES.universal = [{
    amount: 5,
    units: [
        { code: "drgrowing", stage: 1 },
        { code: "drgrowing", stage: 1 },
        { code: "drgrowing", stage: 1 },
        { code: "drgrowing", stage: 1 },
        { code: "drgrowing", stage: 2 }
    ]
}];

GrowingsPlanner.CONFIG.universal = {
    fog: "fog5",
    maxAmount: 7,
    betweenTimeouts: ["5 minutes", "10 minutes", "15 minutes", "30 minutes", "1 hour"],
    units: [{
        code: "drgrowing",
        stage: 1
    }]
};
