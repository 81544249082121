/**
 * Created by dmitry on 09.01.2025
 */
var ClickComponent = function (unit) {
    UnitComponent.call(this, unit);
};

ClickComponent.prototype = Object.create(UnitComponent.prototype);
ClickComponent.prototype.constructor = ClickComponent;

ClickComponent.prototype.handleClick = function () {
    var data = this.unit.getData().click;

    if (data.windowOptions && data.windowOptions.window === OrdersWindow) {
        var customer = Map2d.currentMap.customers.listCustomers().find(function (customer) {
            return customer.isProcessingCustomOrder();
        });
               
        cleverapps.focusManager.display({
            focus: "openOrdersWindow",
            action: function (f) {
                new OrdersWindow({ maker: customer });
                cleverapps.focusManager.onceNoWindowsListener = f;
            }
        });
    }

    return true;
};