/**
 * Created by r4zi4l on 04.07.2021
 */

var UnitsShopTile = cc.Node.extend({
    ctor: function (tileModel, options) {
        this._super();
        this.tileModel = tileModel;
        this.options = options;

        this.setAnchorPoint(0.5, 0.5);
        this.createContent();
        this.updateTileState();

        this.tileModel.onBuy = this.createListener(this.onBuyProduct.bind(this));
        this.tileModel.onGetView = this.createListener(function () {
            return this;
        }.bind(this));
        this.tileModel.onUpdateTileModel = this.createListener(this.updateTileState.bind(this));
        this.tileModel.onBuyVideoProduct = this.createListener(this.buyVideoProduct.bind(this));
        this.tileModel.onUpdateAvailability = this.createListener(this.updateAvailability.bind(this));
    },

    isSmall: function () {
        return false;
    },

    updateAvailability: function () {
        if (this.tileModel.isAvailable()) {
            this.button.enable();
        } else {
            this.button.disable();
        }
    },

    buyVideoProduct: function () {
        BaseProductTile.prototype.buyVideoProduct.apply(this, arguments);
    },

    createContent: function () {
        var styles = cleverapps.styles.UnitsShopTile;

        this.setContentSize2(styles);

        var background = cleverapps.UI.createScale9Sprite(bundles.tile_shop.frames.shop_tile_bg_png, cleverapps.UI.Scale9Rect.TwoPixelXY);
        background.setContentSize2(this.width, this.height);
        background.setPositionRound(styles.background);
        this.addChild(background);

        this.createTitle();

        var unit = this.tileModel.product.unit;
        var unitData = Families[unit.code] && Families[unit.code].units[unit.stage];

        var energy = false;
        if (unitData && unitData.chest && unitData.energyPrize) {
            var chestTime = cleverapps.missionManager.findByType(Mission.TYPE_CHESTTIME);
            if (Chest.AlwaysWithEnergy(unit) || chestTime && chestTime.isRunning()) {
                energy = unitData.energyPrize;
            }
        }

        if (bundles.tile_shop.frames.icon_bg) {
            var iconBg = new cc.Scale9Sprite(bundles.tile_shop.frames.icon_bg);
            iconBg.setContentSize2(styles.icon.bg);
            iconBg.setAnchorPoint(0.5, 0.5);
            iconBg.setPositionRound(styles.icon.bg);
            this.addChild(iconBg);
        }

        var leftText = this.leftText = cleverapps.UI.generateOnlyText("", cleverapps.styles.FONTS.SHOPTILE_LEFT);
        leftText.setPositionRound(styles.leftText);
        if (cleverapps.config.ui === "heroes") {
            leftText.setOpacity(120);
        }
        this.addChild(leftText);

        var icon = this.icon = new UnitOnCell(this.tileModel.product.unit, {
            points: this.tileModel.product.salePassPoints,
            energy: energy,
            unitImgOptions: {
                preferStatic: true
            }
        });
        icon.setScale(styles.icon.scale);
        icon.setPositionRound(styles.icon);
        this.addChild(icon);

        var name = cleverapps.unitsLibrary.getUnitName(this.tileModel.product.unit);
        name = cleverapps.splitHalfByWord(name).join("\n");

        var description = cleverapps.UI.generateOnlyText(name, cleverapps.styles.FONTS.UNITSSHOPTILE_NAME);
        description.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        description.fitTo(styles.description.width, styles.description.height);
        description.setPositionRound(styles.description);
        this.addChild(description);

        this.createButton();

        var hasBonus = cleverapps.paymentsHistory.classify() === cleverapps.PaymentsHistory.BRACKET_NONE;

        if (hasBonus && this.tileModel.product.videoBonus && this.tileModel.isAvailable() && cleverapps.rewardedAdsManager.isRewardedAvailable()) {
            var buyParams = {
                videoBonus: true
            };

            this.adBubble = new AdBubble({
                skin: "x2",
                limit: AdsLimits.TYPES.FREEX2,
                onSuccess: function () {
                    setTimeout(function () {
                        this.options.onClicked(this.tileModel, buyParams);
                    }.bind(this), 0);
                }.bind(this),
                canClick: function () {
                    return this.tileModel.canSpawnUnits(buyParams);
                }.bind(this)
            });

            this.adBubbleView = new AdBubbleView(this.adBubble);
            this.addChild(this.adBubbleView);
            this.adBubbleView.setScale(1.3);
            this.adBubbleView.baseScale = this.adBubbleView.scale;
            this.adBubbleView.setPositionRound(styles.adBubble);
        }

        if (this.tileModel.isFree()) {
            this.button.adviceTarget = Map2d.SPAWN;
        }

        var helpAction = this.tileModel.getHelpButtonAction();
        if (helpAction && !this.tileModel.isLocked()) {
            var guideButton = new cleverapps.UI.HelpButton({
                type: cleverapps.styles.UI.Button.Images.help_button_unit,
                callback: helpAction 
            });
            guideButton.setPositionRound(styles.guideButton);
            this.addChild(guideButton);
        }

        if (styles.decors) {
            styles.decors.forEach(function (styles) {
                var decor = new cc.Sprite(styles.image);
                decor.setPositionRound(styles);

                if (styles.fitToBox) {
                    cleverapps.UI.fitToBox(decor, styles.fitToBox);
                }

                background.addChild(decor);
            });
        }
    },

    createButton: function () {
        var styles = cleverapps.styles.UnitsShopTile;

        var button = this.button = new TileButton(this.tileModel, {
            type: this.tileModel.isFree() || cleverapps.config.ui === "heroes" ? cleverapps.styles.UI.Button.Images.button_green : cleverapps.styles.UI.Button.Images.button_blue,
            width: styles.button.width,
            height: styles.button.height,
            onClicked: this.options.onClicked
        });

        if (!this.tileModel.isAvailable()) {
            button.disable();
        }

        button.setPositionRound(styles.button);
        this.addChild(button);
    },

    createTitle: function () {
        var styles = cleverapps.styles.UnitsShopTile;

        var title = this.title = new cc.Scale9Sprite(this.tileModel.isLocked() ? bundles.tile_shop.frames.shop_title_off : bundles.tile_shop.frames.shop_title);
        title.setContentSize2(styles.title);
        title.setPositionRound(styles.title);
        this.addChild(title);

        var amount = cleverapps.UI.generateImageText("x" + this.tileModel.product.amount, cleverapps.styles.FONTS.UNITSSHOPTILE_AMOUNT);
        amount.setPositionRound(styles.title.amount);
        title.addChild(amount);
    },

    updateLeftText: function () {
        this.leftText.setString(Messages.get("UnitsShop.left", {
            left: this.tileModel.product.left
        }));
        this.leftText.fitTo(cleverapps.styles.UnitsShopTile.leftText.width);

        this.leftText.setVisible(this.tileModel.product.left > 0);
    },

    updateTileState: function () {
        this.updateLeftText();

        var styles = cleverapps.styles.UnitsShopTile;

        if (this.button) {
            this.button.removeFromParent();

            this.createButton();
        }

        if (this.tileModel.isLocked()) {
            this.title.removeFromParent();
            this.createTitle();

            this.icon.removeFromParent();

            this.icon = new cc.Sprite(bundles.tile_shop.frames.lock_png);
            this.icon.setPositionRound(styles.blockIcon);
            this.addChild(this.icon);

            this.button.disable();
        }
    },

    onBuyProduct: function (withVideoBonus) {
        var amount = this.tileModel.product.amount;
        if (withVideoBonus) {
            amount *= 2;
        }
        for (var i = 0; i < amount; i++) {
            this.flyUnitToCart(i);
        }
        this.updateTileState();

        if (this.adBubbleView) {
            this.adBubbleView.removeFromParent();
        }
    },

    flyUnitToCart: function (order) {
        var styles = cleverapps.styles.UnitsShopTile;

        var flyNode = new cc.Node();
        flyNode.setAnchorPoint(0.5, 0.5);
        flyNode.setPositionRound(this.icon.getPosition());
        this.addChild(flyNode);

        var flyUnit = UnitView.getUnitImage(this.tileModel.product.unit);
        flyUnit.setScale(styles.icon.scale);
        flyNode.addChild(flyUnit);

        flyNode.setVisible(false);
        flyNode.runAction(new cc.Sequence(
            new cc.DelayTime(order * 0.1),
            new cc.Show(),
            AnimationsLibrary.animateCollect(flyNode, this.options.cart.target, {
                path: true,
                duration: 0.7,
                sound: bundles.fruit.urls.fruit_fly_finish_effect,
                collectEffect: true
            }),
            new cc.CallFunc(function () {
                this.options.cart.incAmount(flyUnit);
            }.bind(this)),
            new cc.RemoveSelf()
        ));
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    UNITSSHOPTILE_AMOUNT: {
        name: "default",
        size: 58
    },

    UNITSSHOPTILE_NAME: {
        size: 32,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    }
});

cleverapps.styles.UnitsShopTile = {
    width: 460,
    height: 750,

    background: {
        x: { align: "center" },
        y: { align: "center" }
    },

    icon: {
        x: { align: "center" },
        y: { align: "bottom", dy: 420 },
        scale: 1.3
    },

    adBubble: {
        x: {
            align: "right",
            dx: 7
        },
        y: {
            align: "bottom",
            dy: 245
        }
    },

    blockIcon: {
        x: { align: "center" },
        y: { align: "center", dy: 130 }
    },

    title: {
        x: { align: "center", dx: 7 },
        y: { align: "center", dy: -55 },
        width: 470,
        height: 138,

        amount: {
            x: { align: "center" },
            y: { align: "center", dy: 10 }
        }
    },

    description: {
        x: { align: "center" },
        y: { align: "center", dy: -160 },
        width: 350,
        height: 210
    },

    button: {
        x: { align: "center" },
        y: { align: "center", dy: -255 },

        width: 280,
        height: 96
    },

    leftText: {
        x: { align: "center" },
        y: { align: "center", dy: 285 },
        width: 350
    },

    guideButton: {
        x: { align: "center", dx: -170 },
        y: { align: "center", dy: 310 }
    },

    energyIcon: {
        x: {
            align: "center",
            dx: 140
        },
        y: {
            align: "center",
            dy: 210
        }
    },

    attention: {
        x: {
            align: "center",
            dx: 210
        },
        y: {
            align: "top",
            dy: 10
        }
    }
};
