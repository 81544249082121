/**
 * Created by andrey on 03.01.2021.
 */

var MakesOrder = function (unit, options) {
    UnitComponent.call(this, unit);

    if (cleverapps.config.adminMode || cleverapps.config.editorMode || cleverapps.config.wysiwygMode) {
        return;
    }

    this.restore(options);

    Map2d.currentMap.customers.addCustomer(this);

    Map2d.currentMap.customers.on("onRecipesUpdated", this.handleIngredientsUpdate.bind(this), this);
};

MakesOrder.prototype = Object.create(UnitComponent.prototype);
MakesOrder.prototype.constructor = MakesOrder;

MakesOrder.prototype.onPrizeHarvested = function () {
    this.reset();
};

MakesOrder.prototype.save = function (data) {
    data.state = this.state; // saved for compatibility with old version
    data.recipe = this.recipe && this.recipe.code;
    data.appearTime = this.appearTime;
    data.timeToReady = this.cookingEndTime;
};

MakesOrder.prototype.restore = function (data) {
    var recipe = data.recipe && this.chooseFreeRecipe(data.recipe);
    var cookingEndTime = data.timeToReady;
    var appearTime = data.appearTime;

    if (recipe && this.unit.prizesCount) {
        this._setState(MakesOrder.STATE_READY, recipe);
    } else if (recipe && cookingEndTime) {
        this._setState(MakesOrder.STATE_COOKING, recipe, cookingEndTime);
    } else if (recipe) {
        this._setState(MakesOrder.STATE_RECIPE, recipe);
    } else {
        this._setState(MakesOrder.STATE_EMPTY, undefined, appearTime || Date.now());
    }
};

MakesOrder.prototype.isCooking = function () {
    return this.state === MakesOrder.STATE_COOKING;
};

MakesOrder.prototype.destructor = function () {
    clearTimeout(this.timeout);
    runCleaners(this);
    Map2d.currentMap.customers.removeCustomer(this);
};

MakesOrder.prototype._setState = function (state, recipe, time) {
    if (cleverapps.config.editorMode || cleverapps.config.wysiwygMode) {
        return;
    }

    clearTimeout(this.timeout);
    delete this.timeout;

    delete this.recipe;
    delete this.cookingEndTime;
    delete this.appearTime;

    if (recipe) {
        this._canSwitchToCustomRecipe = !recipe.customerCode;
    }

    switch (state) {
        case MakesOrder.STATE_RECIPE:
            this.state = state;
            this.recipe = recipe;
            this.unit.setPrizes({
                sprite: this.canCook() ? bundles.merge.frames.prize_exclamation : bundles.merge.frames.prize_exclamation_off
            });

            Map2d.mapEvent(Map2d.RECIPE_NEW, { recipe: recipe.code, unit: this.unit });

            if (this.recipe.customerCode) {
                cleverapps.playSession.set(cleverapps.EVENTS.HUNGRY_DAU, true);
            }
            break;

        case MakesOrder.STATE_COOKING:
            this.state = state;
            this.recipe = recipe;
            this.cookingEndTime = time;
            this.timeout = new cleverapps.LongTimeout(this.enjoy.bind(this), this.getCookingTimeLeft());
            this.unit.setPrizes({
                waiting: true
            });
            break;

        case MakesOrder.STATE_READY:
            this.state = state;
            this.recipe = recipe;
            this.unit.setPrizes(this.recipe.listPrize());

            Map2d.mapEvent(Map2d.RECIPE_READY, { recipe: recipe.code });

            if (this.recipe.customerCode) {
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.HUNGRY_END);
            }
            break;

        default:
            this.state = MakesOrder.STATE_EMPTY;
            this.appearTime = time;
            this.timeout = new cleverapps.LongTimeout(this.order.bind(this), this.getCooldownTimeLeft());
            this.unit.setPrizes();
    }

    if (this.view) {
        this.view.restoreState();
    }

    this.canCookCache = this.canCook();

    Map2d.currentMap.customers.onUpdateOrder(this);

    cleverapps.toolbar.resetByType(ToolbarItem.ORDERS);
};

MakesOrder.prototype.getCooldownTimeLeft = function () {
    var cooldownDuration = Game.currentGame.tutorial.isActive() ? MakesOrder.BEGINNER_TIMEOUT : MakesOrder.ORDER_TIMEOUT;
    return Math.max(0, this.appearTime + cooldownDuration - Date.now());
};

MakesOrder.prototype.handleIngredientsUpdate = function () {
    var canCook = this.canCook();
    if (this.canCookCache !== canCook) {
        this.canCookCache = canCook;
        this.unit.setPrizes({
            sprite: this.canCookCache ? bundles.merge.frames.prize_exclamation : bundles.merge.frames.prize_exclamation_off
        });
    }
};

MakesOrder.prototype.order = function () {
    var recipe = this.chooseFreeRecipe();
    if (recipe) {
        this._setState(MakesOrder.STATE_RECIPE, recipe);
        cleverapps.audio.playSound(bundles.merge.urls.new_order_effect);
    } else {
        this.reset();
    }
};

MakesOrder.prototype.canCook = function () {
    return this.state === MakesOrder.STATE_RECIPE && Map2d.currentMap.customers.canTakeIngredients(this.recipe.ingredients);
};

MakesOrder.prototype.isReady = function () {
    return this.state === MakesOrder.STATE_READY;
};

MakesOrder.prototype.cook = function () {
    if (this.canCook()) {
        Map2d.currentMap.customers.takeIngredients(this.recipe.ingredients);
        this._setState(MakesOrder.STATE_COOKING, this.recipe, Date.now() + this.recipe.cookingDuration);
        Game.currentGame.pushes.updateOrders();
        return true;
    }
    return false;
};

MakesOrder.prototype.enjoy = function () {
    if (this.state === MakesOrder.STATE_COOKING) {
        this._setState(MakesOrder.STATE_READY, this.recipe);
        Game.currentGame.pushes.updateOrders();
        return true;
    }
    return false;
};

MakesOrder.prototype.reset = function () {
    this._setState(MakesOrder.STATE_EMPTY, undefined, Date.now());
};

MakesOrder.prototype.handlePrizes = function () {
    if ([MakesOrder.STATE_EMPTY, MakesOrder.STATE_READY].indexOf(this.state) !== -1) {
        return false;
    }

    cleverapps.focusManager.display({
        stack: Game.currentGame.tutorial.checkTargets(this.unit),
        focus: "openOrdersWindow",
        action: function (f) {
            new OrdersWindow({
                maker: this
            });
            cleverapps.focusManager.onceNoWindowsListener = f;
        }.bind(this)
    });

    return true;
};

MakesOrder.prototype.getCookingTimeLeft = function () {
    return Math.max((this.cookingEndTime - Date.now()) || 0, 0);
};

MakesOrder.prototype.chooseFreeRecipe = function (code) {
    var recipeTemplate = MakesOrder.selectTemplate(this, code);
    if (recipeTemplate) {
        return new CustomerRecipe(recipeTemplate);
    }
};

MakesOrder.prototype.canSwitchToCustomRecipe = function () {
    return cleverapps.unitsLibrary.isOpened(UnitsLibrary.LastStage("king"))
        && this._canSwitchToCustomRecipe !== false
        && !Map2d.currentMap.customers.listCustomers().find(function (customer) {
            return customer.isProcessingCustomOrder();
        });
};

MakesOrder.prototype.isProcessingCustomOrder = function () {
    return this.recipe && this.recipe.customerCode && this.state !== MakesOrder.STATE_READY;
};

MakesOrder.selectTemplate = function (maker, code) {
    var recipes = [];
    var customRecipes = [];

    CustomerRecipes.main[maker.unit.getType()].forEach(function (recipe, id) {
        if (recipe.level > cleverapps.gameLevel.getLevel()) {
            return;
        }

        if (Map2d.currentMap.customers.listCustomers().some(function (maker) {
            return maker.recipe && maker.recipe.code === recipe.code;
        })) {
            return;
        }

        if (recipe.customerCode) {
            if (recipe.customerCode === maker.unit.code) {
                customRecipes.push(recipe);
            }
            return;
        }

        if (id > 0 && recipe.ingredients.every(function (ingredient) {
            return cleverapps.unitsLibrary.hasOpened(ingredient.ingredient);
        })) {
            recipes.push(recipe);
        }
    });

    if (code) {
        return recipes.concat(customRecipes).find(function (recipe) {
            return recipe.code === code;
        });
    }

    if (["mergecraft", "hustlemerge"].indexOf(cleverapps.config.name) !== -1) {
        if (customRecipes.length && maker.canSwitchToCustomRecipe() && Math.random() <= MakesOrder.CUSTOM_RECIPE_PROBABILITY) {
            return cleverapps.Random.choose(customRecipes);
        }
    }

    if (recipes.length === 0 && cleverapps.gameLevel.getLevel() > 1) {
        return CustomerRecipes.main[maker.unit.getType()][0];
    }

    if (recipes.length <= 3) {
        return cleverapps.Random.choose(recipes);
    }

    var id = recipes.length - 1;
    while (id > 0 && cleverapps.Random.nextDouble() < 0.6) {
        id--;
    }
    return recipes[id];
};

MakesOrder.CUSTOM_RECIPE_PROBABILITY = cleverapps.config.debugMode ? 0.5 : 0.1;

MakesOrder.BEGINNER_TIMEOUT = cleverapps.parseInterval("120 seconds");
MakesOrder.ORDER_TIMEOUT = cleverapps.parseInterval("30 seconds");

MakesOrder.STATE_EMPTY = 0;
MakesOrder.STATE_RECIPE = 1;
MakesOrder.STATE_COOKING = 2;
MakesOrder.STATE_READY = 3;
