/**
 * Created by r4zi4l on 16.05.2022
 */

var Prizes = {};

Prizes.Generate = function (config, options) {
    options = options || {};
    var prizes = [];

    config.forEach(function (block) {
        var amount = block.amount || 1;
        var p = block.probabilities || [];
        var units = block.units || [block];

        if (block.resource || block.ingredient) {
            block = Object.assign({}, block);

            if (Array.isArray(block.amount)) {
                block.amount = cleverapps.Random.random(block.amount[0], block.amount[1]);
            }

            amount = 1;
            p = [];
            units = [block];
        }

        prizes = prizes.concat(options.listOnly ? units : cleverapps.Random.probability(amount, p, units));
    });

    prizes = prizes.map(function (unit) {
        if (unit.resource || unit.ingredient || unit.lock) {
            return unit;
        }

        var code = unit.code;
        var stage = unit.stage;
        var maxStage = unit.maxStage;

        if (unit.currentType) {
            code = cleverapps.unitsLibrary.getCurrentOfType(unit.currentType);
            if (code && unit.currentType.indexOf("hero") !== -1 && Families[code].units.length === 1) {
                code = undefined;
            }
        } else if (unit.openedType) {
            var availableUnits = [];

            cleverapps.unitsLibrary.listCodesByType(unit.openedType).forEach(function (code) {
                cleverapps.unitsLibrary.listOpened(code).forEach(function (unit) {
                    availableUnits.push(unit);
                });
            });

            var selectedUnit = cleverapps.Random.choose(availableUnits);
            code = selectedUnit.code;
            stage = selectedUnit.stage;
        } else if (unit.type) {
            code = cleverapps.Random.choose(cleverapps.unitsLibrary.listAvailableByType(unit.type));
        }

        if (!code) {
            code = "coins";
            stage = stage && Math.min(3, stage);
            maxStage = maxStage && Math.min(3, maxStage);
        }

        if (stage === undefined && maxStage) {
            stage = Math.min(Math.floor(cleverapps.Random.nextDouble() * maxStage), Families[code].units.length - 1);
        }

        if (!Families[code]) {
            return;
        }

        stage = Unit.RepairDeletedStages(code, stage);

        return Prizes.decreasePrizeStageIfNeeded({
            code: code,
            stage: stage
        });
    });

    return prizes.filter(function (unit) {
        if (!unit) {
            return false;
        }

        if (unit.resource) {
            return true;
        }

        if (unit.ingredient) {
            return Game.currentGame.harvested.isIngredientAvailable(unit.ingredient);
        }

        var type = Families[unit.code].type;

        if (type === "hero" && cleverapps.gameLevel.getLevel() < 3) {
            return false;
        }

        if (type === "snailfeast") {
            var page = cleverapps.travelBook.getCurrentPage();
            if (!cleverapps.missionManager.findRunningMission(Mission.CompoundType(Mission.TYPE_SNAIL_FEAST, page.missionType))) {
                return false;
            }
        }

        if (unit.code === "magicplant" && cleverapps.gameLevel.getLevel() < 6) {
            return false;
        }

        if (unit.code === "cinema") {
            if (!cleverapps.rewardedAdsManager.isRewardedSupported() || cleverapps.user.level < 7 || Map2d.currentMap.listAvailableUnits({ code: "cinema", stage: 3 }).length) {
                return false;
            }
        }

        return true;
    });
};

Prizes.decreasePrizeStageIfNeeded = function (prize) {
    var code = prize.code;
    var stage = prize.stage;

    if (!Families[code]) {
        cleverapps.throwAsync("Prizes.decreasePrizeStageIfNeeded wrong code - " + code);
        return {
            code: code,
            stage: stage
        };
    }

    if (code && stage > 0) {
        if (["hero", "drhero", "seahero"].indexOf(Families[code].type) !== -1) {
            stage = Math.min(stage, Families[code].units.length - 2);
        }

        if (["resource"].indexOf(Families[code].type) !== -1) {
            stage = Math.min(stage, Families[code].units.length - 3);
        }

        if (["resource", "hero"].indexOf(Families[code].type) !== -1) {
            while (stage > 0 && !cleverapps.unitsLibrary.isOpened({ code: code, stage: stage })) {
                stage--;
            }
        }

        while (stage > 0 && Families[code].units[stage].deleted) {
            stage--;
        }

        if (stage < 0) {
            stage = 0;
        }
    }

    return {
        code: code,
        stage: stage
    };
};
