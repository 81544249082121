/**
 * Created by r4zi4l on 17.09.2021
 */

var IngredientOrderIcon = cc.Node.extend({
    ctor: function (ingredient, maker) {
        var styles = cleverapps.styles.IngredientOrderIcon;
        this._super();

        this.ingredient = ingredient;
        this.maker = maker;

        this.needed = ingredient && ingredient.amount || 0;

        if (this.ingredient && this.maker && this.maker.state === MakesOrder.STATE_RECIPE) {
            this.amount = Map2d.currentMap.customers.getStockAmount(this.ingredient);
        }

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(styles);

        this.contentRect = cc.rectSubPadding(cc.rect(0, 0, this.width, this.height), cc.padding(styles.padding));

        if (this.ingredient) {
            var icon = this.icon = CustomerRecipe.getIngredientIcon(this.ingredient);
            cleverapps.UI.fitToBox(icon, {
                width: this.contentRect.width,
                height: this.contentRect.height,
                maxScale: styles.icon.scale
            });
            icon.setPositionRound(this.contentRect.x + this.contentRect.width / 2, this.contentRect.y + this.contentRect.height / 2);
            this.addChild(icon);
        }

        this.createText();
        this.createHelp();
        this.setRecCascadeColorEnabled(true);
        this.setCascadeOpacityEnabledRecursively(true);

        this.setEnabled(!this.amount || this.amount >= this.needed);
    },

    createText: function (hideAmount) {
        if (!this.amount && !this.needed) {
            return;
        }

        if (this.amount === undefined || hideAmount) {
            this.neededTxt = cleverapps.UI.generateImageText("x" + this.needed, cleverapps.styles.FONTS.INGREDIENT_ORDER_ICON_TEXT);
        } else {
            this.amountTxt = cleverapps.UI.generateImageText(this.amount, cleverapps.styles.FONTS.INGREDIENT_ORDER_ICON_TEXT);
            this.neededTxt = cleverapps.UI.generateImageText("/" + this.needed, cleverapps.styles.FONTS.INGREDIENT_ORDER_ICON_TEXT);
        }

        this.textNode = new cleverapps.Layout([this.amountTxt, this.neededTxt].filter(Boolean), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: 0
        });
        this.textNode.setLocalZOrder(2);
        cleverapps.UI.fitToBox(this.textNode, { width: this.contentRect.width, height: this.contentRect.height, maxScale: 1 });
        this.textNode.setAnchorPoint(1, 0);
        this.textNode.setPositionRound(this.contentRect.x + this.contentRect.width, this.contentRect.y);
        this.addChild(this.textNode);
    },

    setEnabled: function (enabled) {
        enabled = this.ingredient && enabled;

        [this.icon, this.amountTxt, this.neededTxt].forEach(function (node) {
            if (!node) {
                return;
            }
            if (enabled) {
                node.setOpacity(255);
                node.setColor(new cc.Color(255, 255, 255, 255));
                if (node === this.amountTxt && this.amount >= this.needed) {
                    node.setColor(cleverapps.styles.COLORS.GREEN);
                }
            } else {
                node.setOpacity(200);
                node.setColor(new cc.Color(180, 180, 180, 255));
            }
        }.bind(this));
    },

    beforeRemoveAnimated: function () {
        this.setEnabled(true);
        if (this.text) {
            this.text.removeFromParent();
            delete this.text;
        }
    },

    updateAmount: function () {
        var newAmount = Map2d.currentMap.customers.getStockAmount(this.ingredient);
        if (this.amount === newAmount) {
            return;
        }

        var delta = newAmount - this.amount;
        this.amount = newAmount;

        if (this.icon) {
            this.icon.runAction(new cc.Sequence(
                new cc.CallFunc(function () {
                    if (this.amount && this.amountTxt) {
                        this.amountTxt.setString(this.amount);
                    }
                    cleverapps.aims.showDelta(delta, {
                        target: this,
                        font: cleverapps.styles.FONTS.INGREDIENT_ORDER_ICON_TEXT
                    });
                }.bind(this)),
                new cc.ScaleTo(0.3, this.icon.scale * 1.1).easing(cc.easeIn(2)),
                new cc.CallFunc(function () {
                    if (this.textNode) {
                        delete this.amountTxt;
                        delete this.neededTxt;
                        this.textNode.removeFromParent();
                        this.createText(delta < 0);
                        this.setEnabled(true);
                    }
                }.bind(this)),
                new cc.ScaleTo(0.3, this.icon.scale * 1).easing(cc.easeOut(2)),
                new cc.ScaleTo(0.2, this.icon.scale * 1.05).easing(cc.easeIn(2)),
                new cc.ScaleTo(0.2, this.icon.scale * 1).easing(cc.easeOut(2))
            ));
        }
    },
    
    createHelp: function () {
        if (this.maker && this.maker.recipe.customerCode) {
            this.helpButton = cleverapps.UI.createScale9Sprite(bundles.orderswindow.frames.ow_magnifier);
            cleverapps.UI.applyHover(this.helpButton);
            cleverapps.UI.onClick(this.helpButton, function () {
                Map2dScroller.currentScroller.cancelZoomOut();
                cleverapps.windows.currentWindow().close();

                UnitLocator.find(Map2d.MERGE, { code: this.ingredient.ingredient || this.ingredient.unit.code }, {
                    resource: true,
                    allowScrollWithFocus: true,
                    searchSources: true
                });
            }.bind(this));
            this.addChild(this.helpButton);
            this.helpButton.setPositionRound(cleverapps.styles.IngredientOrderIcon.helpButton);
        }
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    INGREDIENT_ORDER_ICON_TEXT: {
        size: 44,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        shadow: cleverapps.styles.COLORS.LIGHT_TEXT_SHADOW,
        stroke: cleverapps.styles.COLORS.LIGHT_TEXT_STROKE
    }
});

cleverapps.styles.IngredientOrderIcon = {
    width: 160,
    height: 140,

    padding: {
        x: 8,
        y: 2
    },

    icon: {
        x: { align: "center", dx: -10 },
        y: { align: "bottom", dy: 0 },
        scale: 1.2
    },

    text: {
        width: 130,
        x: { align: "right", dx: 0 },
        y: { align: "bottom", dy: -10 }
    },

    helpButton: {
        x: { align: "right" },
        y: { align: "top" }
    }
};
