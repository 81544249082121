/**
 * Created by Reda on 24.01.2025
 */

QuestsConfig.spring = cleverapps.clone(QuestsConfig.universal, true);

QuestsConfig.spring.rpproducer2.trigger = { fog: "fog1" };

delete QuestsConfig.spring.rpcustomerbridge3_0;
delete QuestsConfig.spring.rpcustomerbridge3_1;
delete QuestsConfig.spring.rpcustomermain3_0;
delete QuestsConfig.spring.rpcustomermain3_1;
delete QuestsConfig.spring.rpproducer3;
delete QuestsConfig.spring.rpproduct3;

delete QuestsConfig.spring.rpcustomerbridge4_0;
delete QuestsConfig.spring.rpcustomerbridge4_1;
delete QuestsConfig.spring.rpcustomermain4_0;
delete QuestsConfig.spring.rpcustomermain4_1;
delete QuestsConfig.spring.rpproducer4;
delete QuestsConfig.spring.rpproduct4;

CustomerRecipes.spring = cleverapps.clone(CustomerRecipes.universal, true);

Ruins.TIERS.spring = cleverapps.clone(Ruins.TIERS.universal, true);

GrowingsPlanner.CONFIG.spring = cleverapps.clone(GrowingsPlanner.CONFIG.universal, true);
GrowingsPlanner.CONFIG.spring.betweenTimeouts = ["24 hours"];
GrowingsPlanner.CONFIG.spring.maxAmount = 1;

MissionTree.PRIZES.spring = [];

Landmarks.WORLDS.spring = {
    fog: "fog3",
    force: Forces.LANDMARK_DONOR_SLOT1,
    landmarks: [{
        code: "landmark",
        stage: 13,
        targetExpedition: "main",
        donors: []
    }, {
        code: "landmark",
        stage: 22,
        targetExpedition: "main",
        donors: ["rpcustomermain0", "rpcustomermain1", "rpcustomermain2"]
    }]
};

cleverapps.styles.spring = {
};