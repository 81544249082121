/**
 * Created by dmitry on 12.12.2024
 */

var IngredientsInfoView = cc.Node.extend({
    ctor: function (info) {
        var styles = cleverapps.styles.IngredientsInfoView;

        this.customerIngredientViews = info.ingredients.map(function (ingredient) {
            return new CustomerIngredientView(ingredient, CustomerIngredientView.TYPE_INFOVIEW);
        });

        var content = new cleverapps.Layout(this.customerIngredientViews, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin,
            padding: styles.padding
        });

        this._super();
        this.setContentSize2(content.getContentSize());

        var background = InfoView.CreateInfoBackground(content.getContentSize());
        this.addChild(background);

        content.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(content);

        Map2d.currentMap.customers.on("onRecipesUpdated", this.onRecipesUpdated.bind(this), this);
    },

    onRecipesUpdated: function () {
        var justGotReady = false;
        this.customerIngredientViews.forEach(function (ingredientView) {
            if (ingredientView.onAmountChanged()) {
                justGotReady = true;
            }
        });

        if (justGotReady) {
            this.runAction(new cc.Sequence(
                new cc.ScaleTo(0.26, 0.93, 0.85).easing(cc.easeInOut(1)),
                new cc.ScaleTo(0.2, 1.05).easing(cc.easeInOut(1)),
                new cc.ScaleTo(0.067, 1).easing(cc.easeInOut(1))
            ));
        }
    }
});

cleverapps.styles.IngredientsInfoView = {
    margin: 24,

    padding: {
        x: 12,
        top: 12,
        bottom: 16
    }
};