/**
 * Created by Denis Kuzin on 15 december 2021
 */

cleverapps.SkinManager.SKINS.xmas_expedition = {
    bundle: "expedition_xmas",

    slots: Object.assign({}, cleverapps.SkinManager.SKINS.rose_energy.slots, {
        miningEffects: function () {
            return {
                animation: "chop",
                sound: bundles.mineable.urls.mining_wood_start_effect
            };
        },

        workerSkin: function () {
            return cleverapps.config.name === "mergecraft" ? "xm" : undefined;
        },

        windowDecors: function (options) {
            return cleverapps.SkinManager.SKINS.xmas.slots.windowDecors(options);
        },

        ruinsWindow: function () {
            return {
                bundle: "ruinswindow_xmas",
                dictionaryKey: "RuinsWindow.xmas.",
                person: {
                    role: "worker",
                    skin: cleverapps.config.name === "mergecraft" ? "xmas" : undefined
                }
            };
        },

        gameAudio: function () {
            return bundles.expedition_xmas.urls.xmas_music;
        },

        unitIcon: function (unit) {
            return bundles.unit_icons_xmas.frames["small_icon_family_" + unit.code];
        },

        unitOnCell: function () {
            return cleverapps.styles.UnitOnCellExpedition;
        },

        gameSceneBg: function () {
            return {
                surfaceAnimation: bundles.expedition_xmas.jsons.xmas_bg_animation_top
            };
        },

        fogBlocker: function () {
            return cleverapps.styles.xmas.fogBlockerLock;
        },

        feed_icon_png: bundles.buttons_inlined_icons.frames.xmas_feed_icon_png,
        wand_icon_png: bundles.buttons_inlined_icons.frames.xmas_wand_icon_png,

        whirlwind: bundles.fog_animations.jsons.xmas_whirlwind,

        merge_wand_png: bundles.menubar.frames.xmas_merge_wand_png,

        expedition_buildpass_icon_json: bundles.sidebar.jsons.xmas_expedition_buildpass_icon_json,

        reward_wand_png: bundles.reward_icons.frames.xmas_reward_wand_png,
        reward_wand_small_png: bundles.reward_icons.frames.xmas_reward_wand_small_png,

        snail_feast_icon_json: bundles.sidebar.jsons.xmas_snail_feast_icon_json,
        snailhouse_offer_json: bundles.sidebar.jsons.xmas_snailhouse_offer_json,
        snail_feast_icon: bundles.reward_icons.frames.xmas_snail_feast_icon,

        pass_star_png: bundles.reward_icons_pass.frames.xmas_pass_star_png,

        landmarkGuideOptions: function () {
            return {
                name: "xmas_product_guidewindow",
                bundle: bundles.xmas_product_guidewindow,
                title: "LandmarkGuideWindow.title"
            };
        },

        fog_disappear_effect: function () {
            return bundles.fog_xmas.urls.open_effect;
        },

        avatarFramesBundle: function () {
            return cleverapps.bundleLoader.isLoaded("snail_feast_xmas") ? "snail_feast_xmas" : undefined;
        }
    })
};
