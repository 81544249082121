/**
 * Created by mac on 2/25/20
 */

var Merge = function (level) {
    cleverapps.EventEmitter.call(this);

    var episodeNo = level && level.episodeNo || 0;
    var levelNo = level && level.levelNo || 0;

    level = this.level = new Level(episodeNo, levelNo);
    level.load();

    this.slot = cleverapps.GameSaver.getStoreSlot(level.episodeNo, level.levelNo);

    var page = cleverapps.travelBook.getCurrentPage();

    Game.currentGame = this;

    UnitsLibrary.Switch(level.meta.expedition);

    this.counter = new Counter();

    cleverapps.Random.randomSeed();

    this.onChangeWandsListener = function () {};
    this.onShowMergeBonus = function () {};

    var stored = this.loadSave();

    console.log("Stored", stored);

    cleverapps.config.saveUnitsEnabled = false;

    var options = {};
    if (!cleverapps.config.editorMode) {
        if (this.isMainGame()) {
            if (cleverapps.user.level < Merge.MIN_LEVEL_FOR_SKIP_TUTORIAL) {
                stored = {};
                cleverapps.unitsLibrary.reset();
                options.isNewGame = true;
            }
        } else if (stored.expedition !== page.id) {
            delete stored.wands;
            options.isNewGame = true;
        }
    }

    this.map = new Map2d(level.content.map, {
        tiles: level.meta.tiles,
        units: level.meta.units,
        families: level.families,
        visibleBox: level.content.visibleBox,
        tilesVisibleRectFrame: level.content.tilesVisibleRectFrame,
        regions: level.content.regions,
        decorators: level.content.decorators,
        terrains: level.content.terrains,
        field: level.content.field,
        isNewGame: options.isNewGame
    });

    this.unitSaver = cleverapps.unitSavers.getInstance(this.slot);
    this.fogsSaver = cleverapps.fogsSavers.getInstance(this.slot);

    Map2d.currentMap.on("saveUnit", this.unitSaver.update.bind(this.unitSaver), this);
    Map2d.currentMap.on("removeUnit", this.unitSaver.remove.bind(this.unitSaver), this);
    Map2d.currentMap.on("saveFogs", this.fogsSaver.update.bind(this.fogsSaver), this);

    var saverUnits = this.unitSaver.loadUnits(level.families, page.id);

    if (options.isNewGame) {
        saverUnits.forEach(function (unit) {
            Map2d.currentMap.removeUnit(unit);
        }, this);

        saverUnits = [];
    }

    this.map.onUnitAvailableListener = this.onUnitAvailable.bind(this);
    this.map.onUnitFreshListener = this.onUnitFresh.bind(this);

    this.pocket = new Pocket(this.slot, options);
    this.pocket.validate(level);

    cleverapps.Lives.Switch(page.slot, options.isNewGame);

    cleverapps.gameLevel.load(stored.ownLevel);

    Exp.Switch(page.slot);
    if (cleverapps.gameLevel.withOwnLevel) {
        cleverapps.exp.load(stored.exp, options.isNewGame);
    }

    if (stored.gameLevel) {
        cleverapps.user.level = stored.gameLevel.value;
        cleverapps.exp.setExp(stored.gameLevel.exp);
        cleverapps.user.save();
    }

    this.wands = stored.wands || 0;
    if (this.wands < 0) {
        this.wands = 0;
    }

    this.harvested = new Harvested(this.slot, options);
    this.advice = new MergeAdvice();
    this.tutorial = new MergeTutorial();

    if (this.isMainGame()) {
        this.energyLottery = new EnergyLottery(stored.lottery);
    }

    if (this.isMainGame()) {
        this.season = new Season(stored.season);
    }

    if (page.id === "collections") {
        this.pawBox = new PawBox(stored.paws);
    }

    if (!cleverapps.config.editorMode) {
        var comparator = Map2d.LOAD_UNITS_COMPARATOR();
        saverUnits.sort(function (unit1, unit2) {
            return comparator(unit1.data, unit2.data);
        }).forEach(function (info) {
            this.map.loadUnit(info.x, info.y, info.data);
        }, this);

        this.map.workers.load(options);

        this.loadStaticUnits();

        var storedFogs = this.fogsSaver.load(this.map.fogs);
        if (options.isNewGame) {
            storedFogs = [];
        } else if (!storedFogs && this.isMainGame()) {
            storedFogs = cleverapps.GameSaver.loadProperty(Meta.SLOT_MAIN, "fogs") || [];
        } else if (!storedFogs) {
            storedFogs = [];
        }

        this.map.initFogs(storedFogs);
        this.map.fogs.setWands(this.wands);
        this.map.fogs.on("takeWands", this.takeWands.bind(this), this);
        this.map.fogs.on("open", function () {
            this.counter.setTimeout(function () {}, 0);
        }.bind(this));
    } else {
        Map2d.InsertDefaults(this.map, level.content.field);
    }

    if (!cleverapps.config.editorMode || cleverapps.config.wysiwygMode) {
        this.removeUnitsInFog();
    }

    if (!this.map.getFocusCell() || options.isNewGame) {
        this.map.initFocusCell();
    }

    this.pushingComponent = new PushingComponent();

    this.pixelsPlanner = new PixelsPlanner(options);
    this.barrelPlanner = new BarrelPlanner();
    this.monstersPlanner = new MonstersPlanner();
    this.growingsPlanner = new GrowingsPlanner(options);
    this.bpPointsPlanner = new BattlePassPointsPlanner();
    this.shipsPlanner = new ShipsPlanner();
    this.thirdElementPlanner = new ThirdElementPlanner(options);

    this.unitStories = new UnitStories();
    this.landmarks = new Landmarks();
    this.pushes = new MergePushes();
    this.quests = new Quests(options);

    this.specialEnergyOffer = new SpecialEnergyOffer();
    this.specialEnergyOffer.init();

    this.thirdElements = [];

    if (cleverapps.config.debugMode && !cleverapps.config.editorMode) {
        this.orangery = new Orangery(MergeOrangery);
    }

    if (cleverapps.config.wysiwygMode) {
        this.counter.registerStage(0, this.initStage.bind(this));
    } else if (!cleverapps.config.adminMode && !cleverapps.config.editorMode) {
        this.counter.registerStage(0, this.initStage.bind(this));
        this.counter.registerStage(6, cleverapps.userStatus.reportUserAction.bind(cleverapps.userStatus));
        this.counter.registerStage(10, this.quests.finish.bind(this.quests));
        this.counter.registerStage(20, cleverapps.gameLevel.checkLevelUp.bind(cleverapps.gameLevel));
        this.counter.registerStage(21, this.map.blockedGrounds.processFresh.bind(this.map.blockedGrounds));
        this.counter.registerStage(24, this.monstersPlanner.processFresh.bind(this.monstersPlanner));
        this.counter.registerStage(25, this.unitStories.processFresh.bind(this.unitStories));
        this.counter.registerStage(30, this.tutorial.processFresh.bind(this.tutorial));
        this.counter.registerStage(40, this.quests.processFresh.bind(this.quests));
        this.counter.registerStage(41, this.map.fogs.calcFogStatesStage.bind(this.map.fogs));
        this.counter.registerStage(42, this.thirdElementPlanner.spawnPending.bind(this.thirdElementPlanner));
        this.counter.registerStage(43, this.map.customers.process.bind(this.map.customers));
        if (this.season) {
            this.counter.registerStage(45, this.season.processFresh.bind(this.season));
        }
        this.counter.registerStage(47, this.map.unitGreeters.processFresh.bind(this.map.unitGreeters));
        this.counter.registerStage(48, this.landmarks.process.bind(this.landmarks));
        this.counter.registerStage(50, this.tutorial.nextStep.bind(this.tutorial));
        if (this.pawBox) {
            this.counter.registerStage(55, this.pawBox.giveReward.bind(this.pawBox));
        }
        this.counter.registerStage(61, this.pocket.showHint.bind(this.pocket));
        this.counter.registerStage(62, this.harvested.showHint.bind(this.harvested));

        cleverapps.config.saveUnitsEnabled = true;
        this.counter.setTimeout(this.updateAvailableUnits.bind(this), 0);

        this.map.counter.registerStage(0, function () {
            this.counter.trigger();
        }.bind(this));

        if (cleverapps.config.subtype === "merge2") {
            cleverapps.tutorial.register(Merge2BaseTutorial());
            this.counter.registerStage(10000, cleverapps.tutorial.gameReady.bind(cleverapps.tutorial), cleverapps.tutorial);
        }
    }

    this.shipsPlanner.init();

    if (options.isNewGame || this.needSave) {
        delete this.needSave;

        this.storeSave();
    }

    if (options.isNewGame) {
        this.unitSaver.resetKickOuts();
    }

    cleverapps.offerManager.refreshAll();
};

var Game = Merge;

Merge.prototype = Object.create(cleverapps.EventEmitter.prototype);
Merge.constructor = Merge;

Merge.prototype.isMainGame = function () {
    return this.level.episodeNo === 0 && this.level.levelNo === 0 && cleverapps.environment.isMainScene();
};

Merge.prototype.initStats = function () {
    var collections = cleverapps.travelBook.getPageById("collections");
    if (collections && collections.isActive()) {
        cleverapps.playSession.set(cleverapps.EVENTS.COLLECTIONS_DAU, true);
    }

    var logFamily = function (type, progressEvent, completeEvent) {
        var current = cleverapps.unitsLibrary.getLastOpenCode(type);
        if (current) {
            var list = cleverapps.unitsLibrary.listCodesByType(type);
            var last = list[list.length - 1];

            var event = progressEvent + current;
            if (current === last && cleverapps.unitsLibrary.isOpened({ code: last, stage: Families[last].units.length - 1 })) {
                event = completeEvent;
            }
            cleverapps.eventLogger.logEvent(event);
        }
    };

    var logFamilies = function (resourceType, heroType, eventPref) {
        eventPref = eventPref || "";

        cleverapps.eventLogger.logEvent(eventPref + cleverapps.EVENTS.MERGE_START);
        logFamily(resourceType, eventPref + cleverapps.EVENTS.CURRENT_RESOURCE, eventPref + cleverapps.EVENTS.COMPLETE_RESOURCES);

        var currentHero = cleverapps.unitsLibrary.getCurrentHero(heroType);
        if (["rapunzel", "rapunzel2", "easter"].includes(cleverapps.travelBook.getCurrentPage().id)) {
            if (currentHero) {
                var stage = cleverapps.unitsLibrary.getLastOpenStage(currentHero);
                stage = stage === undefined ? "" : "_" + stage;
                cleverapps.eventLogger.logEvent(eventPref + cleverapps.EVENTS.CURRENT_HERO + currentHero + stage);
            } else {
                cleverapps.eventLogger.logEvent(eventPref + cleverapps.EVENTS.COMPLETE_HEROES);
            }
        } else {
            var heroEvent = currentHero ? (cleverapps.EVENTS.CURRENT_HERO + currentHero) : cleverapps.EVENTS.COMPLETE_HEROES;
            cleverapps.eventLogger.logEvent(eventPref + heroEvent);
        }
    };

    if (cleverapps.travelBook.isExpedition()) {
        var expedition = cleverapps.travelBook.getCurrentPage().id;
        var resourceType = cleverapps.unitsLibrary.getExpeditionUnitType("resource");
        var heroType = cleverapps.unitsLibrary.getExpeditionUnitType("hero");
        var prefix = cleverapps.travelBook.getCurrentPage().prefix;
        logFamilies(resourceType, heroType, prefix + "_");
        if (cleverapps.paymentsHistory.isPayer()) {
            logFamilies(resourceType, heroType, "payer_" + prefix + "_");
        }

        var logCampaign = function (type) {
            cleverapps.unitsLibrary.listCodesByType(type).forEach(function (code) {
                var openedStage = cleverapps.unitsLibrary.getLastOpenStage(code);
                if (openedStage !== undefined && Families[code].units[openedStage].climbable) {
                    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.CAMPAIGN + code + "_" + openedStage);
                }
            });
        };

        if (["rapunzel", "rapunzel2"].includes(expedition)) {
            var currentOrder = cleverapps.unitsLibrary.getLastOpenCode("rporder");
            if (currentOrder) {
                var ordersList = cleverapps.unitsLibrary.listCodesByType("rporder");
                var lastOrder = ordersList[ordersList.length - 1];

                var orderEvent = cleverapps.EVENTS.CURRENT_ORDER + currentOrder;
                if (currentOrder === lastOrder && cleverapps.unitsLibrary.isOpened({ code: lastOrder, stage: Families[lastOrder].units.length - 1 })) {
                    orderEvent = cleverapps.EVENTS.COMPLETE_ORDERS;
                }

                cleverapps.eventLogger.logEvent(prefix + "_" + orderEvent);
            }
            logCampaign("rpcustomer");
        } else if (expedition === "collections") {
            var logPets = function (payer) {
                var prefix = payer ? "payer_" : "";
                logFamily("clpet", prefix + cleverapps.EVENTS.CURRENT_PET, prefix + cleverapps.EVENTS.COMPLETE_PET);
                logFamily("clpetrare", prefix + cleverapps.EVENTS.CURRENT_PETRARE, prefix + cleverapps.EVENTS.COMPLETE_PETRARE);
                logFamily("clpetlegend", prefix + cleverapps.EVENTS.CURRENT_PETLEGEND, prefix + cleverapps.EVENTS.COMPLETE_PETLEGEND);
            };
            logPets();
            cleverapps.paymentsHistory.isPayer() && logPets(true);

            var petStats = {};
            Map2d.currentMap.listAvailableUnits([{ type: "clpet" }, { type: "clpetrare" }, { type: "clpetlegend" }]).forEach(function (pet) {
                var type = pet.getType();
                petStats[type] = petStats[type] ? petStats[type] + 1 : 1;
            });
            cleverapps.playSession.set(cleverapps.EVENTS.PET_RATE, petStats);
        }

        if (!cleverapps.travelBook.getCurrentExpedition().isCompleted()) {
            cleverapps.playSession.set(cleverapps.EVENTS.EXPEDITION_DAU, true, expedition);
        }

        var availableFog = this.map.fogs.findAvailableFogBlock();
        if (availableFog) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.EXPEDITION_FOG + prefix + "_" + availableFog.id);
        }
    }

    if (this.isMainGame()) {
        logFamilies("resource", "hero");
        if (cleverapps.paymentsHistory.isPayer()) {
            logFamilies("resource", "hero", "payer_");
        }

        if (this.season && Season.isRunning()) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.SEASON_START);

            if (this.season.getSeasonItems().length === this.season.opened) {
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.COMPLETE_SEASON + this.season.runningSeason);
            } else {
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.CURRENT_SEASON + this.season.runningSeason + "_" + this.season.opened);
            }
        }

        if (cleverapps.eventManager.isActive("thanksgiving")) {
            logFamily("thanksgiving", cleverapps.EVENTS.CURRENT_EVENT, cleverapps.EVENTS.COMPLETE_EVENT + "thanksgiving");
        }

        var units = [{
            code: "magicplant",
            stage: 8
        }, {
            code: "crystal",
            stage: 3
        }, {
            code: "coinsplant"
        }, {
            code: "rubiesplant"
        }, {
            code: "energyplant"
        }, {
            code: "worker",
            stage: 4
        }];

        var unitsAmount = {};

        Map2d.currentMap.listAvailableUnits(units).forEach(function (unit) {
            var key = Unit.GetKey(unit);
            unitsAmount[key] = (unitsAmount[key] || 0) + 1;
        });

        cleverapps.playSession.set(cleverapps.EVENTS.UNITS_AMOUNT, {
            amount: unitsAmount,
            level: cleverapps.user.level
        });
    }
};

Merge.prototype.workersBusyHint = function (unit) {
    if (!this.map.workers.isBonusWorkerBuyed() && !cleverapps.focusManager.isFocused()) {
        if (this.map.workers.isFreeWorkerAvailable()) {
            cleverapps.focusManager.display({
                focus: "BonusWorkerWindow",
                control: "MenuBarWorkersItem",
                actions: [
                    function (f) {
                        new ReceiveWorkerWindow();
                        cleverapps.focusManager.onceNoWindowsListener = f;
                    },
                    function (f) {
                        var tutorial = cleverapps.clone(MergeTutorials.free_worker);

                        if (unit.findComponent(Buildable)) {
                            tutorial.steps[0].type = Map2d.START_BUILDING;
                        } else if (unit.findComponent(Mineable)) {
                            tutorial.steps[0].type = Map2d.START_MINING;
                        }

                        tutorial.steps[0].preferCell = { x: unit.x, y: unit.y };

                        this.tutorial.showTutorial(tutorial, f);
                    }.bind(this)
                ]
            });
            return;
        }

        if (!this.workersWindowShown || this.workersWindowShown < Date.now() - cleverapps.parseInterval(Merge.WORKERS_WINDOW_INTERVAL)) {
            cleverapps.focusManager.display({
                focus: "BonusWorkerWindow",
                control: ["MenuBarGoldItem", "MenuBarWorkersItem"],
                action: function (f) {
                    this.workersWindowShown = Date.now();
                    new BonusWorkerWindow();
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }.bind(this)
            });
            return;
        }
    }

    cleverapps.centerHint.createTextHint("Workers.busy");

    var worker = this.map.workers.findLeastBusy();
    if (worker) {
        this.map.focusOnUnit(worker.unit, {
            skipFocusReport: true
        });
        FingerView.hintClick({ x: worker.unit.x, y: worker.unit.y }, { runOnce: true });
    }
};

Merge.prototype.removeUnitsInFog = function () {
    var removeUnits = [];
    for (var y = 0; y < this.map.getHeight(); y++) {
        for (var x = 0; x < this.map.getWidth(); x++) {
            var unit = this.map.getUnit(x, y);
            var fog = this.map.getFog(x, y);
            if (unit && fog) {
                if (unit.isMultiCellBody()) {
                    unit = unit.head;
                }

                if (unit.isMovable() && !unit.getData().important) {
                    removeUnits.push({
                        code: unit.code,
                        stage: unit.stage
                    });
                }
                if (unit.code !== "unknown" && !unit.isGrounded()) {
                    unit.remove(true);
                }
            }
        }
    }

    if (removeUnits.length) {
        this.pocket.addUnits(removeUnits);
    }
};

Merge.prototype.initStage = function () {
    if (this.initStageRunned) {
        return;
    }
    this.initStageRunned = true;

    this.map.fogs.initStage();

    if (!cleverapps.config.wysiwygMode) {
        this.initStats();
    }

    this.repairDeletedStages();
    this.map.initWorkers();
    this.restoreImportantUnits();
    this.restoreMultiCells();

    if (!cleverapps.config.wysiwygMode) {
        this.restoreKickOuts();

        if (!cleverapps.config.adminMode && !cleverapps.config.editorMode && !cleverapps.synchronizer._clientChecksumDifferent) {
            this.checkReset();
            this.checkReset2();
        }

        this.updateExpedition();
    }

    this.landmarks.restoreLandmarks();
    this.monstersPlanner.removeWrongMonsters();

    this.map.listAvailableUnits().forEach(function (unit) {
        if (unit.wantsSave) {
            unit.save();
            delete unit.wantsSave;
        }
    });
};

Merge.prototype.repairDeletedStages = function () {
    for (var y = 0; y < this.map.getHeight(); y++) {
        for (var x = 0; x < this.map.getWidth(); x++) {
            var unit = this.map.getUnit(x, y);
            if (unit && unit.getData().deleted) {
                var newStage = Unit.calcNewStageThenDeleted(unit.code, unit.stage);
                unit.remove();
                var newUnit = new Unit({
                    code: unit.code,
                    stage: newStage
                });
                newUnit.setPosition(unit.x, unit.y);
                Map2d.currentMap.add(Map2d.LAYER_UNITS, newUnit.x, newUnit.y, newUnit);
                Map2d.currentMap.onAddUnit(newUnit.x, newUnit.y, newUnit);
            }
        }
    }
};

Merge.prototype.restoreImportantUnits = function () {
    if (cleverapps.gameModes.multipleHeroes) {
        return;
    }

    var fakeImportantUnits = [], playerImportantUnits = [], x, y, unit;
    for (var row in this.map.fogs.fakeUnits) {
        for (var col in this.map.fogs.fakeUnits[row]) {
            x = parseInt(row);
            y = parseInt(col);
            if (this.map.fogs.fakeUnits[x][y].important && !this.map.getFog(x, y)) {
                fakeImportantUnits.push({ x: x, y: y, fakeUnit: this.map.fogs.fakeUnits[x][y] });
            }
        }
    }

    for (y = 0; y < this.map.getHeight(); y++) {
        for (x = 0; x < this.map.getWidth(); x++) {
            unit = this.map.getUnit(x, y);
            if (unit && unit.getData().important) {
                playerImportantUnits.push(unit);
            }
        }
    }

    var importantBubbles = !this.pocket ? [] : this.pocket.bubbles.filter(function (bubble) {
        return Families[bubble.code].units[bubble.stage].important;
    });

    fakeImportantUnits.forEach(function (fakeData) {
        var fakeUnit = fakeData.fakeUnit;
        x = fakeData.x;
        y = fakeData.y;

        var bestUnit = undefined, bestId = undefined;
        playerImportantUnits.forEach(function (playerUnit, id) {
            if (playerUnit.code === fakeUnit.code) {
                if (!bestUnit || bestUnit.stage < playerUnit.stage || bestUnit.stage === playerUnit.stage && cc.pDistanceSQ(cc.p(x, y), bestUnit) > cc.pDistanceSQ(cc.p(x, y), playerUnit)) {
                    bestUnit = playerUnit;
                    bestId = id;
                }
            }
        });

        if (bestUnit) {
            playerImportantUnits.splice(bestId, 1);

            if (!bestUnit.isMovable() && (bestUnit.x !== x || bestUnit.y !== y)) {
                bestUnit.move(x, y);
            }
            return;
        }

        importantBubbles.forEach(function (bubble, id) {
            if (bubble.code === fakeUnit.code) {
                if (!bestUnit || bestUnit.stage < bubble.stage) {
                    bestUnit = { code: bubble.code, stage: bubble.stage };
                    bestId = id;
                }
            }
        });

        if (bestUnit) {
            importantBubbles[bestId].remove();
            importantBubbles.splice(bestId, 1);
            fakeUnit = bestUnit;
        }

        if (Families[fakeUnit.code].units[fakeUnit.stage].climbable) {
            fakeUnit.stage = cleverapps.unitsLibrary.getLastOpenStage(fakeUnit.code) || 0;
        }

        unit = new Unit(fakeUnit);
        unit.move(x, y);
        this.map.onAddUnit(x, y, unit);
    }, this);

    importantBubbles.forEach(function (bubble) {
        bubble.remove();
    });

    playerImportantUnits.forEach(function (playerUnit) {
        playerUnit.remove();
    });
};

Merge.prototype.restoreMultiCells = function () {
    this.map.listAvailableUnits().forEach(function (unit) {
        var multiCell = unit.findComponent(MultiCell);
        if (multiCell) {
            multiCell.restore();
        }
    });
};

Merge.prototype.restoreKickOuts = function () {
    var units = this.unitSaver.loadKickOuts(this.level.families);

    var remains = [];

    units.forEach(function (info) {
        if (info.data.code === "unknown") {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.MERGE.RESTORE_KICKOUT + "unknown");
            return;
        }

        var empty = this.map.findEmptySlot(info.x, info.y);
        if (!empty) {
            remains.push(info.data);
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.MERGE.RESTORE_KICKOUT + "fail");
            return;
        }

        info.x = empty.x;
        info.y = empty.y;

        var unit = this.map.loadUnit(info.x, info.y, info.data);
        if (unit) {
            this.map.onUnitAvailable(unit);

            var code = unit.code;
            if (unit.getData().makesorder || unit.getData().important) {
                code += "_" + unit.stage;
            }

            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.MERGE.RESTORE_KICKOUT + code);
        }
    }, this);

    if (remains.length > 0) {
        this.pocket.addUnits(remains);
    }

    this.unitSaver.resetKickOuts();
};

Merge.prototype.loadStaticUnits = function () {
    for (var y = 0; y < this.map.groundMap.length; y++) {
        for (var x = 0; x < this.map.groundMap[y].length; x++) {
            if (this.map.isImpassableGround(x, y) && !this.map.getFog(x, y)) {
                var unit = this.map.getUnit(x, y);
                if (unit) {
                    var empty = this.map.findEmptySlot(x, y);
                    if (empty) {
                        unit.move(empty.x, empty.y);
                    }
                }
            }
        }
    }
};

Merge.prototype.checkReset = function () {
    var units = cleverapps.createSet(this.map.listAvailableUnits().map(Unit.GetKey));

    var types = [cleverapps.unitsLibrary.getExpeditionUnitType("hero")];
    if (this.isMainGame()) {
        types.push("resource");
    }

    var codes = cleverapps.unitsLibrary.listCodesByType(types);

    var resetedCodes = codes.filter(function (code) {
        var unit = {
            code: code,
            stage: Families[code].units.length - 1
        };
        return cleverapps.unitsLibrary.isOpened(unit) && !units[Unit.GetKey(unit)];
    });

    var reseted = resetedCodes.length > 0;

    var storeKey = DataLoader.TYPES.RESET_REPORT + this.slot;

    if (!reseted) {
        cleverapps.dataLoader.remove(storeKey);
    } else if (!cleverapps.dataLoader.load(storeKey)) {
        cleverapps.dataLoader.save(storeKey, Date.now());

        console.log("save reset event", resetedCodes);

        cleverapps.RestClient.post("/units/saveresetevent/" + encodeURIComponent(connector.platform.getUserID()), {
            source: connector.info.source,
            codes: resetedCodes
        });

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.MERGE.UNITS_RESET);
        resetedCodes.forEach(function (code) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.MERGE.UNITS_RESET + "_" + code);
        });
    }
};

Merge.prototype.checkReset2 = function () {
    var resetFogs = {};

    Object.keys(this.map.fogs.config).forEach(function (fogId) {
        var block = this.map.fogs.blocks[fogId];
        if (block) {
            return;
        }

        var region = this.map.regions[fogId];
        if (!region || !region.positions) {
            return;
        }

        region.positions.forEach(function (cell) {
            if (this.map.getFog(cell.x, cell.y)) {
                return;
            }

            var fakeUnit = this.map.fogs.getFakeUnit(cell.x, cell.y);
            if (fakeUnit && !fakeUnit.head && !cleverapps.unitsLibrary.isOpened(fakeUnit) && !cleverapps.unitsLibrary.isHidden(fakeUnit)) {
                resetFogs[fogId] = true;

                console.error("resets unit", fogId, fakeUnit.code, fakeUnit.stage);
            }
        }.bind(this));
    }.bind(this));

    resetFogs = Object.keys(resetFogs);

    if (resetFogs.length) {
        var expedition = cleverapps.travelBook.getCurrentPage().id;

        cleverapps.playSession.set(cleverapps.EVENTS.DEBUG.MERGE.UNITS_RESET2, true, expedition);

        resetFogs.forEach(function (fogId) {
            cleverapps.playSession.set(cleverapps.EVENTS.DEBUG.MERGE.UNITS_RESET2, true, expedition + "_" + fogId);
        });
    }
};

Merge.prototype.updateExpedition = function () {
    if (!cleverapps.travelBook.isExpedition()) {
        return;
    }

    var mission = cleverapps.missionManager.findLocalExpedition();
    if (mission) {
        mission.logic.resetPush();
        mission.logic.sendPeriodicPush();
    }

    cleverapps.travelBook.onUpdateExpedition(mission);

    Feedable.processFeedable();
};

Merge.prototype.listIntroActions = function () {
    return [
        this.showScreen.bind(this),
        this.restoreProgressUpdate.bind(this),
        this.startTutorial.bind(this),
        this.runFPS.bind(this)
    ];
};

Merge.prototype.restoreProgressUpdate = function (f) {
    cleverapps.restoreProgress.update();
    f();
};

Merge.prototype.runFPS = function (f) {
    levels.FPS.run(this.level.episodeNo, this.level.levelNo);
    f();
};

Merge.prototype.listHustlemergeMainTutorialActions = function () {
    return [
        function (f) {
            if (!cleverapps.unitsLibrary.isHeroAvailable("dwarf")) {
                var region = this.map.regions.hero_tutorial.positions[0];

                var unit = new Unit({
                    x: region.x,
                    y: region.y,
                    code: "dwarf",
                    stage: 1,
                    heroTutorial: true
                });
                unit.setPosition(region.x, region.y);
                Map2d.currentMap.add(Map2d.LAYER_UNITS, region.x, region.y, unit);
                Map2d.currentMap.onAddUnit(unit.x, unit.y, unit);
                Map2d.currentMap.onUnitAvailable(unit);

                unit.findComponent(HeroTutorial).start(f);
            } else {
                f();
            }
        }.bind(this),

        function (f) {
            if (this.map.fogs.blocks.fog1) {
                this.map.fogs.blocks.fog1.unlock(f);
            } else {
                f();
            }
        }.bind(this)
    ];
};

Merge.prototype.listMainTutorialActions = function () {
    return [
        function (f) {
            if (this.map.fogs.blocks.fog0) {
                this.map.fogs.blocks.fog0.unlock(f);
            } else {
                f();
            }
        }.bind(this),

        function (f) {
            if (!cleverapps.unitsLibrary.isHeroAvailable("dwarf")) {
                this.tutorial.showTutorial(MergeTutorials.dwarf, f);
            } else {
                f();
            }
        }.bind(this),

        function (f) {
            if (this.map.fogs.blocks.fog1) {
                this.map.fogs.blocks.fog1.unlock(f);
            } else {
                f();
            }
        }.bind(this),

        function (f) {
            if (cleverapps.config.subtype === "merge2") {
                cleverapps.tutorial.startScenario(cleverapps.tutorial.scenarios.find(function (scenario) {
                    return scenario.name === "merge2_base_tutorial";
                }));
            }
            f();
        }

    ];
};

Merge.prototype.startTutorial = function (f) {
    var actions = [];

    var page = cleverapps.travelBook.getCurrentPage();

    if (page.isMain()) {
        if (cleverapps.config.name === "hustlemerge") {
            if (this.map.fogs.blocks.fog1) {
                cleverapps.silentIntro = false;

                actions = this.listHustlemergeMainTutorialActions(f);
            }
        } else if (this.map.fogs.blocks.fog0 || this.map.fogs.blocks.fog1) {
            cleverapps.silentIntro = false;

            actions = this.listMainTutorialActions(f);
        }
    }

    if (!page.isMain() && this.map.fogs.blocks.fog0) {
        cleverapps.silentIntro = false;

        actions = [
            function (f) {
                if (this.map.fogs.blocks.fog0) {
                    this.map.fogs.blocks.fog0.unlock(f);
                } else {
                    f();
                }
            }.bind(this),

            function (f) {
                var page = cleverapps.travelBook.getCurrentPage();
                if (MergeTutorials[page.id]) {
                    this.tutorial.showTutorial(MergeTutorials[page.id], f);
                    this.map.fogs.calcFogStates();
                } else {
                    f();
                }
            }.bind(this)
        ];
    }

    cleverapps.focusManager.compound(f, actions);
};

Merge.prototype.loadSave = function () {
    return cleverapps.GameSaver.load(this.slot) || {};
};

Merge.prototype.storeSave = function () {
    if (!cleverapps.config.saveUnitsEnabled) {
        return;
    }

    var info = this.getInfo();
    console.log("storeSave");
    console.log(info);

    cleverapps.GameSaver.saveInfo(this.slot, info);
};

Merge.prototype.getInfo = function () {
    var saveData = {
        lottery: this.energyLottery && this.energyLottery.save(),
        wands: this.wands > 0 ? this.wands : undefined,
        season: this.season && this.season.save(),
        paws: this.pawBox && this.pawBox.getPoints()
    };

    if (!this.isMainGame()) {
        saveData.expedition = cleverapps.travelBook.getCurrentPage().id;

        if (cleverapps.gameLevel.withOwnLevel) {
            saveData.ownLevel = cleverapps.gameLevel.level;
            saveData.exp = cleverapps.exp.getExp();
        }
    }

    return saveData;
};

Merge.prototype.setTimeout = function (callback, timeout) {
    return cleverapps.timeouts.setTimeout(function () {
        if (this.stopped) {
            return;
        }

        callback();
    }.bind(this), timeout);
};

Merge.prototype.stop = function () {
    this.counter.turnOff();

    levels.FPS.stop();

    runCleaners(this);

    cleverapps.userStatus.destructor();
    cleverapps.menuBar.stopItems();

    this.stopped = true;
    this.trigger("stop");
    this.purge();

    this.tutorial.destructor();
    this.advice.destructor();
    this.pixelsPlanner && this.pixelsPlanner.destructor();
    this.barrelPlanner.destructor();
    this.monstersPlanner.destructor();
    this.growingsPlanner.destructor();
    this.thirdElementPlanner.destructor();
    this.quests.destructor();
    this.unitStories.destructor();

    this.orangery && this.orangery.destructor();

    this.pushingComponent.destructor();

    this.energyLottery && this.energyLottery.destructor();

    this.map.stop();

    InfoView.Clear();

    this.level.unload();
};

Merge.prototype.confirmEnoughEnergy = function (energy) {
    if (cleverapps.lives.amount < energy) {
        if (cleverapps.flags.monetization === cleverapps.Flags.MONETIZATION_DISABLED && !(Game.currentGame.energyLottery && Game.currentGame.energyLottery.isReady())) {
            cleverapps.focusManager.display({
                focus: "restoreLives",
                action: function (f) {
                    new RewardWindow({ lives: cleverapps.lives.getMaxLives() - cleverapps.lives.amount });
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }
            });
        } else {
            cleverapps.focusManager.display({
                focus: "buy_energy",
                control: ["MenuBarGoldItem", "MenuBarGameLevelItem", "MenuBarLivesItem", "MenuBarCoinsItem"],
                action: function (f) {
                    new LivesShopWindow();
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }
            });
        }
        return false;
    }
    return true;
};

Merge.prototype.useEnergy = function (energy, unit) {
    if (!this.confirmEnoughEnergy(energy)) {
        return false;
    }

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.USE_ENERGY + cleverapps.lives.slot, { value: energy });
    cleverapps.lives.setAmount(cleverapps.lives.amount - energy);

    if (cleverapps.travelBook.getCurrentPage().isMain() && cleverapps.missionManager.findRunningMission(Mission.TYPE_LIVESFEAST)) {
        var reward = new Reward("mission", { amount: energy, missionType: Mission.TYPE_LIVESFEAST });
        reward.collectRewardsAnimation(unit, { delay: 300, withoutDelta: true });
    } else if (!unit.getData().simpleInfo) {
        cleverapps.aims.showDelta(-energy, {
            target: unit.onGetView(),
            type: "energy",
            font: cleverapps.styles.FONTS.SCENE_ANIMATE_DELTA_TEXT
        });
    }

    return true;
};

Merge.prototype.setWands = function (wands) {
    this.wands = wands;
    this.map.fogs.setWands(wands);
    this.onChangeWandsListener();
    this.storeSave();
};

Merge.prototype.takeWands = function (wands) {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.SPEND_WANDS, { value: wands });
    this.setWands(this.wands - wands);
    this.storeSave();
};

Merge.prototype.addReward = function (type, amount, source, options) {
    options = options || {};
    options.event = options.event || cleverapps.EVENTS.EARN.OTHER;

    var reward = new Reward(type, amount, options);
    reward.receiveReward();
    reward.collectRewardsAnimation(source, options);
};

Merge.prototype.copyUnit = function (unitToCopy) {
    if (["thirdelement"].includes(unitToCopy.code)) {
        return;
    }
    var position = this.map.findEmptySlot(unitToCopy.x, unitToCopy.y, unitToCopy);
    if (position) {
        var unit = new Unit(unitToCopy);
        unit.setPosition(position.x, position.y);
        this.map.add(Map2d.LAYER_UNITS, unit.x, unit.y, unit);
        this.map.onAddUnit(unit.x, unit.y, unit);
        this.map.onUnitAvailable(unit);
        unit.onAdd();
    }
};

Merge.prototype.spawn = function (units, parentUnit, options) {
    this.advice.boo();

    var spawned = [];
    var remains = [];

    units = cleverapps.toArray(units) || [];

    if (units.length === 0 || cleverapps.config.editorMode) {
        return remains;
    }

    var parentCell = parentUnit;

    if (options.fromNode) {
        var mapView = this.map.getMapView();
        var pos = parentUnit.convertToWorldSpaceAR();
        parentCell = mapView.getCellByCoordinates(mapView.convertToNodeSpace(pos));
    }

    units.sort(function (a, b) {
        if (a.code !== b.code) {
            if (a.code < b.code) {
                return -1;
            }
            return 1;
        }
        return a.stage - b.stage;
    });

    units = units.map(function (unit) {
        if (this.needToReplaceWithCoins(unit) && unit.stage < Families[unit.code].units.length - 1) {
            return this.getUnitReplacer(unit);
        }
        return unit;
    }.bind(this));

    var targetCell = options.targetCell || {
        x: parentCell.x,
        y: parentCell.y
    };

    units.forEach(function (unit) {
        if (remains.length) {
            remains.push(unit);
            return;
        }

        var slot = this.map.findEmptySlot(targetCell.x, targetCell.y, unit, options);
        if (!slot) {
            remains.push(unit);
            return;
        }

        unit = new Unit(unit);
        unit.setPosition(slot.x, slot.y);
        this.map.add(Map2d.LAYER_UNITS, slot.x, slot.y, unit);
        spawned.push(unit);
    }, this);

    this.spawnAction(spawned, remains, parentUnit, options);

    return remains;
};

Merge.prototype.showNoSpaceHint = function (remains) {
    if (remains.some(function (unit) {
        return Unit.GetShape(unit).length > 1;
    })) {
        cleverapps.centerHint.createTextHint("Spawn.nospace", { left: "2x2" });
    } else {
        cleverapps.centerHint.createTextHint("Spawn.nospace", { left: remains.length });
    }
};

Merge.prototype.spawnAction = function (spawned, remains, parentUnit, options) {
    if (remains.length) {
        cleverapps.audio.playSound(bundles.merge.urls.spawn_fail_effect);
        this.showNoSpaceHint(remains);
    } else if (!options.noSound) {
        cleverapps.audio.playSound(options.sound || bundles.merge.urls.spawn_start_effect);
    }

    var delay = options.delay || 100;

    this.counter.setTimeout(function () {
        if (!options.fromNode) {
            spawned.sort(function (a, b) {
                var d1 = Math.sqrt((a.x - parentUnit.x) * (a.x - parentUnit.x) + (a.y - parentUnit.y) * (a.y - parentUnit.y));
                var d2 = Math.sqrt((b.x - parentUnit.x) * (b.x - parentUnit.x) + (b.y - parentUnit.y) * (b.y - parentUnit.y));
                if (d1 === 0) {
                    d1 = 10000;
                }
                if (d2 === 0) {
                    d2 = 10000;
                }
                return d1 - d2;
            });
        }
        spawned.forEach(function (unit, id) {
            if (!unit.onGetView()) {
                this.map.onAddUnit(unit.x, unit.y, unit);
            }
            this.map.onUnitAvailable(unit);

            var pulsing = unit.findComponent(Pulsing);
            if (pulsing) {
                pulsing.beforeSpawn();
            }

            unit.onSpawned(parentUnit, id, options);

            Map2d.mapEvent(Map2d.SPAWN, {
                unit: unit,
                affected: options.fromNode ? undefined : parentUnit
            });
        }, this);
    }.bind(this), delay);

    this.counter.setTimeout(function () {
    }, 700);
};

Merge.prototype.replace = function (target, unit, silent) {
    if (target === this.map.dragging) {
        this.map.stopDragging();
    }

    if (!silent) {
        target.onDestruction();
        target.remove(true);

        this.spawn(unit, target, {
            radius: 0
        });
    } else {
        target.remove();

        unit = new Unit(unit);
        unit.setPosition(target.x, target.y);
        this.map.add(Map2d.LAYER_UNITS, unit.x, unit.y, unit);
        this.map.onAddUnit(unit.x, unit.y, unit);
        this.map.onUnitAvailable(unit);
        this.counter.setTimeout(Map2d.mapEvent.bind(this, Map2d.SPAWN, { unit: unit }), 0);
        return unit;
    }
};

Merge.prototype.getUnitReplacer = function (unit) {
    return this.landmarks.getDonorReplacer(unit) || { code: "coins", stage: Math.min(unit.stage, 3) };
};

Merge.prototype.needToReplaceWithCoins = function (unit) {
    if (cleverapps.gameModes.multipleHeroes) {
        return false;
    }

    if (Families[unit.code].units[unit.stage] && Families[unit.code].units[unit.stage].heroitem && cleverapps.unitsLibrary.isHeroAvailable(unit.code)) {
        return true;
    }

    if ((unit.code === "cinema" || Families[unit.code].type === "herofood") && this.map.listAvailableUnits(UnitsLibrary.LastStage(unit.code)).length) {
        return true;
    }

    if (this.landmarks.needToReplaceDonor(unit)) {
        return true;
    }

    return false;
};

Merge.prototype.replaceUnit = function (unitToReplace, replacer) {
    this.counter.setTimeout(function () {
        var family = Families[unitToReplace.code];
        var units = this.map.listAvailableUnits();
        var lastStageUnits = [];

        for (var i = 0; i < units.length; ++i) {
            var unit = units[i];

            if (unit.code === family.code && unit.stage === family.units.length - 1
                && (family.units[unit.stage].heroitem
                    || unit.code === "cinema"
                    || Families[unit.code].type === "herofood" && Map2d.currentMap.customers.isIngredientRequired(unit))) {
                lastStageUnits.push(unit);
            } else if (unit.code === "thirdelement" && unit.findComponent(ThirdElement).prize.code === family.code) {
                unit.remove();
            } else if (unit.code === family.code) {
                this.replace(unit, replacer(unit));
            } else if (unit.prizes && unit.prizes.length) {
                var prizes = unit.prizes.filter(function (prize) {
                    return prize.code !== family.code;
                });

                if (unit.prizes.length !== prizes.length) {
                    if (prizes.length === 0) {
                        prizes = [{ code: "coins", stage: 0 }];
                    }
                    unit.setPrizes(prizes, unit.prizesExp);
                }
            }
        }

        if (lastStageUnits.length > 1) {
            var index = lastStageUnits.indexOf(unitToReplace);
            if (index !== -1) {
                var tmp = lastStageUnits[0];
                lastStageUnits[0] = lastStageUnits[index];
                lastStageUnits[index] = tmp;
            }

            for (i = 0; i < lastStageUnits.length - 1; ++i) {
                this.replace(lastStageUnits[i], replacer(lastStageUnits[i]));
            }
        }
    }.bind(this), 100);
};

Merge.prototype.merge = function (affected, unit, mergeInfoUnit) {
    var affectedUnits = cleverapps.gameModes.automerge ? [] : [unit];

    affected.forEach(function (pos) {
        var merging = this.map.getUnit(pos.x, pos.y);
        var worker = this.map.workers.findAssigned(merging);
        if (worker) {
            worker.clearAssignment();
        }
        merging.claimPoints();
        merging.onMerge(affected[0]);
        merging.remove(true);
        affectedUnits.push(merging);
    }.bind(this));

    var res = mergeInfoUnit.findComponent(MergeComponent).mergeBonus(affectedUnits.length);

    var worker = this.map.workers.findAssigned(unit);
    if (worker) {
        worker.clearAssignment();
    }
    unit.onMerge(affected[0]);
    unit.remove(true);

    var resultUnits = [];

    for (var i = 0; i < res.bonus + res.keepLast; i++) {
        var newUnit = {
            code: mergeInfoUnit.code,
            stage: mergeInfoUnit.stage
        };

        if (i < res.bonus) {
            newUnit.unbuilt = !cleverapps.gameModes.skipBuildingStage;
            Object.assign(newUnit, mergeInfoUnit.getMergeUnit());
        }

        resultUnits.push(newUnit);
    }

    var bonusUnit = this.landmarks.getMergeBonusUnit(mergeInfoUnit);
    if (bonusUnit) {
        for (i = 0; i < res.bonus; ++i) {
            resultUnits.push(bonusUnit);
        }
    }

    var newUnits = [];

    for (i = 0; i < resultUnits.length; i++) {
        var pos = affected[i] || affected[affected.length - 1];
        newUnit = resultUnits[i];

        var newPos = this.map.findEmptySlot(pos.x, pos.y, newUnit, {
            skipCheckScreen: true,
            skipCheckEqual: true
        });

        if (newPos) {
            newUnit = new Unit(newUnit);
            newUnit.setPosition(newPos.x, newPos.y);
            this.map.add(Map2d.LAYER_UNITS, newPos.x, newPos.y, newUnit);
            newUnits.push(newUnit);
        } else {
            this.pocket.addUnits(newUnit, pos);
        }
    }

    if (!newUnits.length) {
        return;
    }

    newUnits.forEach(function (newUnit, index) {
        this.map.onAddUnit(newUnit.x, newUnit.y, newUnit);
        newUnit.didMerged(newUnits[0], index);
    }.bind(this));

    newUnits[0].onStartDidMerged();

    this.counter.setTimeout(function () {
        this.playMergeSound({
            amount: affectedUnits.length,
            stage: unit.stage
        });
        this.map.onUnitFresh(newUnits[0]);
    }.bind(this), 300);

    var unitForInfo = !this.suggestOffer(newUnits) && newUnits.find(function (newUnit) {
        return !newUnit.isBuilt();
    });

    if (unitForInfo) {
        this.counter.setTimeout(function () {
            if (!cleverapps.focusManager.isFocused()) {
                InfoView.DisplayInfo(unitForInfo);
            }
        }, 1300 + 100 * newUnits.length);
    }

    this.counter.setTimeout(function () {
        newUnits.forEach(function (newUnit, index) {
            this.map.onUnitAvailable(newUnit);
            if (index < res.bonus) {
                Map2d.mapEvent(Map2d.SPAWN, { unit: newUnit, affected: affectedUnits });
            }
        }.bind(this));

        var sound = bundles.merge.urls["merge_effect_" + unit.code];
        if (sound) {
            cleverapps.audio.playSound(sound);
        }

        Map2d.mapEvent(Map2d.MERGE, { affected: affectedUnits });
    }.bind(this), 1000 + 100 * newUnits.length);

    if (res.next > 5 && res.next === 2 * affectedUnits.length) {
        this.counter.setTimeout(function () {
            this.onShowMergeBonus(newUnits);
        }.bind(this), 1000 + 100 * newUnits.length);
    }

    if (cleverapps.gameModes.axemerge) {
        var targets = this.axeTargets(newUnits);

        this.counter.setTimeout(function () {
            targets.forEach(function (tuple) {
                tuple.target.onDestruction(false, tuple.origin);
            });
        }, 700);

        this.counter.setTimeout(function () {
            targets.forEach(function (tuple) {
                tuple.target.remove(true);
                this.map.blockedGrounds.updateBlockedGrounds();
            }.bind(this));
        }.bind(this), 1400);
    }

    var tutorialStep = cleverapps.tutorial.getCurrentActiveStep();
    if (tutorialStep && tutorialStep.name === "merge") {
        tutorialStep.execute();
    }

    this.advice.boo();
};

Merge.prototype.suggestOffer = function (newUnits) {
    var offerType;
    if (ThirdElement.IsAvailable(ThirdElement.TYPE_ADS, newUnits[0]) && Math.random() <= 0.3) {
        offerType = ThirdElement.TYPE_ADS;
    } else if (ThirdElement.IsAvailable(ThirdElement.TYPE_RUBY, newUnits[0])) {
        offerType = ThirdElement.TYPE_RUBY;
    } else if (ThirdElement.IsAvailable(ThirdElement.TYPE_ANIMALS, newUnits[0])) {
        offerType = ThirdElement.TYPE_ANIMALS;
    }

    if (offerType) {
        this.thirdElementPlanner.planNext({
            type: offerType,
            delay: 2200,
            target: {
                code: newUnits[0].code,
                stage: newUnits[0].stage,
                x: newUnits[0].x,
                y: newUnits[0].y
            }
        });
    }

    return offerType;
};

Merge.prototype.playMergeSound = function (options) {
    var total = options.stage * options.amount;
    var sound = 0;
    if (total >= 80) {
        sound = 4;
    } else if (total >= 40) {
        sound = 3;
    } else if (total >= 20) {
        sound = 2;
    } else if (total >= 10) {
        sound = 1;
    }

    cleverapps.audio.playSound(bundles.merge.urls["merge_effect_" + sound]);
};

Merge.prototype.axeTargets = function (units) {
    var directions = [{ x: 1, y: 0 }, { x: 0, y: 1 }, { x: -1, y: 0 }, { x: 0, y: -1 }];
    var cells = [];
    var used = {};
    var targets = [];
    var maxRadius = 6;
    var maxTargets = 3;

    units.forEach(function (unit) {
        var cell = {
            x: unit.x,
            y: unit.y,
            unit: unit
        };

        cells.push(cell);
        used[cell.x * 1000 + cell.y] = true;
    });

    for (var i = 0; i < cells.length && targets.length < maxTargets; ++i) {
        var origin = cells[i];

        for (var j = 0; j < directions.length && targets.length < maxTargets; ++j) {
            var delta = directions[j];
            var cell = {
                x: origin.x + delta.x,
                y: origin.y + delta.y,
                unit: origin.unit
            };

            if (used[cell.x * 1000 + cell.y]) {
                continue;
            }
            used[cell.x * 1000 + cell.y] = true;

            if (Math.abs(cell.x - cell.unit.x) > maxRadius || Math.abs(cell.y - cell.unit.y) > maxRadius) {
                continue;
            }

            cells.push(cell);

            var unit = this.map.getUnit(cell.x, cell.y);
            var isSource = unit && ["source", "hlsource", "drsource", "easource", "rpsource", "seasource", "advsource"].indexOf(Families[unit.code].type) !== -1;

            if (isSource) {
                targets.push({
                    target: unit,
                    origin: cell.unit
                });
            }
        }
    }

    return targets;
};

Merge.prototype.updateAvailableUnits = function () {
    this.counter.inc();

    this.map.listAvailableUnits().sort(function (a, b) {
        return b.stage - a.stage;
    }).forEach(this.map.onUnitAvailable.bind(this.map));

    var reportOnce = cleverapps.once(function (message) {
        cleverapps.throwAsync(message);
    });

    for (var col in this.map.fogs.fakeUnits) {
        for (var row in this.map.fogs.fakeUnits[col]) {
            var x = parseInt(col), y = parseInt(row);

            if (!cleverapps.isNumber(col) || !cleverapps.isNumber(row) || !this.map.fogs.fakeUnits[x] || !this.map.fogs.fakeUnits[x][y]) {
                reportOnce("fakeUnit coordinate is not number - " + JSON.stringify({
                    col: col,
                    row: row,
                    x: x,
                    y: y
                }));
                continue;
            }

            var unit = this.map.fogs.fakeUnits[x][y].head || this.map.fogs.fakeUnits[x][y];
            if (!this.map.getFog(x, y) && Families[unit.code] && Families[unit.code].type === "fruit") {
                cleverapps.unitsLibrary.openUnit(unit);
            }
        }
    }

    this.counter.dec();
};

Merge.prototype.onUnitAvailable = function (unit) {
    if (!Buildable.IsBuilt(unit)) {
        this.tutorial.triggerBuildTutorial({ unit: unit });
        return;
    }

    if (!Creatable.IsCreated(unit)) {
        return;
    }

    this.season && this.season.onUnitAvailable(unit);

    if (cleverapps.unitsLibrary.openUnit(unit)) {
        this.map.fogs.wantsCalcFogStates = true;
    }

    if (this.needToReplaceWithCoins(unit)) {
        this.replaceUnit(unit, this.getUnitReplacer.bind(this));
    }

    if (unit.findComponent(HeroItem) && cleverapps.unitsLibrary.isHeroAvailable(unit.code)) {
        this.quests.finishQuestsByUnit(unit, Map2d.SPAWN);
        this.quests.finishQuestsByUnit(unit, Map2d.BUILD);
    }

    if (unit.findComponent(Customer)) {
        this.quests.finishQuestsByUnit(unit, Map2d.SPAWN);
    }

    if (unit.isLast() && unit.isBuilt()) {
        this.quests.finishQuestsByUnit(unit, Map2d.BUILD);
    }

    if (unit.findComponent(ResourceCollectible) || unit.findComponent(Upgradable)) {
        this.updatePulsing();
    }

    if (unit.getData().feedableTarget || unit.findComponent(Feedable)) {
        Feedable.processFeedable();
    }

    if (this.map.fogs.wantsCalcFogStates) {
        this.counter.setTimeout(function () {}, 0);
    }
};

Merge.prototype.onUnitFresh = function (unit) {
    if (cleverapps.aims.getTarget("unitsLibrary", { noDefault: true })
        && unit.isBuilt() && !cleverapps.gameModes.skipFlyToUnitsLibrary
        && !cleverapps.unitsLibrary.isOpened(unit) && !cleverapps.unitsLibrary.isHidden(unit)
        && !this.tutorial.isActive()) {
        unit.onShowFresh();
        this.counter.setTimeout(function () {}, 1700);
    }
};

Merge.prototype.updatePulsing = cleverapps.accumulate(0, function () {
    if (this.stopped) {
        return;
    }

    var upgradables = {};
    var resources = [];

    this.map.listAvailableUnits(function (unit) {
        if (unit.isUpgradable() && unit.isBuilt()) {
            upgradables[unit.code] = true;
        }

        var resource = unit.findComponent(ResourceCollectible);
        if (resource) {
            resources.push(unit);
        }
    });

    resources.forEach(function (unit) {
        var pulsing = unit.findComponent(Pulsing);
        if (pulsing) {
            pulsing.setActive(unit.isBuilt() && upgradables[pulsing.unit.code]);
        }
    });
});

Merge.prototype.showScreen = function (f, silent) {
    cleverapps.focusManager.compound(f, [
        function (f) {
            this.introZoom(f, silent);
        }.bind(this),
        function (f) {
            this.map.fogs.showBalloons(silent);
            f();
        }.bind(this),
        Workers.ShowFinishedWorker,
        MiniGame.ProcessRewardsStage,
        function (f) {
            this.showUpFinished = true;
            this.map.onPositionChanged();
            f();
        }.bind(this)
    ]);
};

Merge.prototype.introZoom = function (f, silent) {
    if (silent) {
        f();
        return;
    }

    var zoom = Map2dScroller.currentScroller.getBasicZoom();
    var scene = cleverapps.scenes.getRunningScene();
    scene.animateZoom(zoom, 1, f);
};

Merge.prototype.collectAndRemoveAllUnits = function (f) {
    Object.keys(this.map.fogs.blocks).forEach(function (fogBlock) {
        this.map.fogs.blocks[fogBlock].trigger("updateState", FogBlock.NOTREADY, true);
    }, this);

    var units = [];

    for (var y = 0; y < this.map.getHeight(); y++) {
        for (var x = 0; x < this.map.getWidth(); x++) {
            var unit = this.map.getUnit(x, y);
            if (unit && !unit.getData().important && !unit.head) {
                unit.components.forEach(function (component) {
                    Object.keys(component).filter(function (fieldName) {
                        return component[fieldName] instanceof cleverapps.CountDown;
                    }).forEach(function (timeoutFieldName) {
                        component[timeoutFieldName].stop();
                    });
                });
                units.push(unit);
                continue;
            }

            var fog = this.map.getFog(x, y);
            if (fog && (fog.state === FogBlock.CANTOPEN || fog.state === FogBlock.CANOPEN)) {
                var fakeUnit = fog.getFakeUnit();
                if (fakeUnit) {
                    fakeUnit = fakeUnit.head || fakeUnit;
                    if (!fakeUnit.important) {
                        units.push(fog);
                    }
                }
            }
        }
    }

    if (!units.length) {
        f();
        return;
    }

    units.sort(function (a, b) {
        return a.y - b.y || a.x - b.x;
    });

    this.map.showAllUnits(units, this.createRemoveUnitAction.bind(this), f);
};

Merge.prototype.createRemoveUnitAction = function (duration, units) {
    var first = units[0];
    var last = units[units.length - 1];
    var lines = last.y - first.y;
    var easing = cc.easeQuadraticActionInOut();

    return new cc.Spawn(units.map(function (unit) {
        var timeout = duration * easing.easing((unit.y - first.y) / lines);

        if (unit.onDestructFog) {
            return new cc.Sequence(
                new cc.DelayTime(timeout / 1000),
                new cc.CallFunc(unit.onDestructFog.bind(unit))
            );
        }

        var collectible = unit.findComponent(Collectible);
        if (collectible && ["drfruit", "seafruit", "clfruit"].indexOf(unit.getType()) === -1) {
            return new cc.Sequence(
                new cc.DelayTime(timeout / 1000),
                new cc.CallFunc(collectible.collect.bind(collectible))
            );
        }

        return new cc.Sequence(
            new cc.DelayTime(timeout / 1000),
            new cc.CallFunc(function () {
                var worker = this.map.workers.findAssigned(unit);
                if (worker) {
                    worker.clearAssignment();
                }

                unit.onDestruction();
                try {
                    unit.remove(true);
                } catch (e) {
                    var msg = e.message + " " + Unit.GetKey(unit) + " pos" + Unit.GetPositionKey(unit);
                    msg += " time " + duration + "x" + timeout;
                    msg += " units " + units.length + " y: " + unit.y + " " + first.y;
                    throw new Error(msg);
                }
            }.bind(this))
        );
    }, this));
};

Merge.prototype.showLargestGroup = function (params) {
    var units = Map2d.currentMap.listAvailableUnits({ code: params.itemCode, stage: params.itemStage });

    var fakeUnits = Map2d.currentMap.fogs.listFakeUnits({ code: params.itemCode, stage: params.itemStage }).filter(function (fakeUnit) {
        return fakeUnit.pointOfInterest;
    });

    if (units.length === 0 && fakeUnits.length === 0) {
        if (cleverapps.unitsLibrary.listTabCodes(cleverapps.travelBook.getCurrentPage().id).indexOf(params.itemCode) === -1) {
            cleverapps.centerHint.createTextHint(params.text);
            return;
        }

        cleverapps.focusManager.display({
            focus: "largestGroupUnitsLibrary",
            action: function (f) {
                var searchUnit = { code: params.itemCode, stage: params.itemStage || 0 };

                if ((["hero", "drhero", "seahero",
                    "resource", "drresource", "searesource"].indexOf(Families[searchUnit.code].type) !== -1)) {
                    searchUnit.stage = Families[params.itemCode].units.length - 1;
                }

                new UnitsLibraryWindow(searchUnit);
                cleverapps.focusManager.onceNoWindowsListener = f;

                if (params.text) {
                    cleverapps.centerHint.createTextHint(params.text);
                }
            }
        });
        return;
    }

    units.forEach(function (unit) {
        unit.highlight({ duration: 2 * Highlight.ONE_PULSE });
    });

    var target = units.length ? Map2d.currentMap.findLargestGroup(units)[0] : fakeUnits[0];
    Map2dScroller.currentScroller.zoomIn(target, {
        zoom: 1.35,
        callback: function () {}
    });
    Map2dScroller.currentScroller.cancelZoomOut();
    Map2d.currentMap.focusOnUnit(target, {
        skipFocusReport: true,
        visibleBox: {
            left: 0.1,
            right: 0.1,
            top: 0.1,
            bottom: 0.1
        }
    });

    if (params.text) {
        cleverapps.centerHint.createTextHint(params.text);
    }
};

Merge.deleteStagesForAbtest = function () {
    var toDelete = {
        wands: [8, 9],
        rubies: [8, 9],
        coins: [12]
    };

    Object.keys(toDelete).forEach(function (family) {
        toDelete[family].forEach(function (stage) {
            if (Families[family] && Families[family].units[stage]) {
                Families[family].units[stage].deleted = !cleverapps.ABTest.NEW_VALUABLES();
            }
        });
    });
};

Merge.calcLocations = function () {
    var locations = [];

    Object.keys(bundles).forEach(function (bundleName) {
        var bundle = bundles[bundleName];
        if (!bundle) {
            return;
        }

        if (bundle.meta.location) {
            locations.push({
                episodeNo: bundle.meta.location,
                levelNo: 0
            });
        }
    });

    return locations;
};

Merge.WORKERS_WINDOW_INTERVAL = "1 minute";
Merge.MIN_LEVEL_FOR_SKIP_TUTORIAL = 1;