/**
 * Created by r4zi4l on 12.09.2022
 */

var ClansCupIcon = function () {
    SideBarIcon.call(this, {
        animation: bundles.sidebar.jsons.clans_cup_icon_json,
        targets: "cup" + CupsConfig.TYPE_CLAN,
        control: "ClansCupIcon",
        resetOnFreeFocus: true
    });

    cleverapps.clanCup.on("update", this.resetState.bind(this));
};

ClansCupIcon.prototype = Object.create(SideBarIcon.prototype);
ClansCupIcon.prototype.constructor = ClansCupIcon;

ClansCupIcon.prototype.resetState = function () {
    this.available = !cleverapps.config.editorMode && cleverapps.clanCup.isActive() && cleverapps.clanCup.isAvailable()
        && cleverapps.environment.isMainScene() && !cleverapps.travelBook.isExpedition();
    if (!this.available) {
        return;
    }

    if (!cleverapps.clanCup.hasConnection()) {
        this.setAttention(false);
        this.setLeftTime(0);
        this.setTitle("ClansCupIcon.noConnection");
        this.setDisabled(true);
        return;
    }

    this.setDisabled(false);
    this.setAttention(cleverapps.clanCup.needsAttention());

    var leftTime = cleverapps.clanCup.getTimeLeft();
    if (leftTime > 0) {
        this.setLeftTime(leftTime);
        this.setTitle(undefined);
    } else {
        this.setLeftTime(0);
        this.setTitle("Finished");
    }
};

ClansCupIcon.prototype.onPressed = function () {
    cleverapps.focusManager.display({
        focus: "ClansCupIcon",
        action: function (f) {
            new ClansCupWindow();
            cleverapps.focusManager.onceNoWindowsListener = f;
        }
    });
};

ClansCupIcon.prototype.getForce = function () {
    return cleverapps.clanCup.getForce();
};

ClansCupIcon.prototype.getToolTip = function () {
    if (this.disabled) {
        return {
            text: "ClansCupIcon.noConnectionTip"
        };
    }
};