/**
 * Created by evgenijsenkevic on 26.12.2024
 */

var CrystalUnmergeableUnitHintContent = function (options) {
    this.unit = options.unit;
    var pos = options.wrongCrystalMerged;
    this.errorUnit = Map2d.currentMap.getUnit(pos.x, pos.y);

    this.doNotBlock = {
        x: this.unit.dragX,
        y: this.unit.dragY
    };
};

CrystalUnmergeableUnitHintContent.prototype.hasSameContent = function (options) {
    return Unit.Equals(this.unit, options.unit);
};

CrystalUnmergeableUnitHintContent.isApplicable = function (options) {
    return options.wrongCrystalMerged && !Game.currentGame.tutorial.isActive();
};

CrystalUnmergeableUnitHintContent.prototype.getMessages = function () {
    return [
        { text: Messages.get("CenterHint.merge.crystalUnmergeableUnit1") },
        { icon: UnitView.getUnitImage(this.unit, { preferStatic: true }) },
        { text: Messages.get("CenterHint.merge.crystalUnmergeableUnit2") },
        { icon: UnitView.getUnitImage(this.errorUnit, { preferStatic: true }) }
    ];
};