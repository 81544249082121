/**
 * Created by Aleksandr on 22.10.2022
 */

FieldComponent.RECIPES.dragonia2 = [{
    prize: { code: "dr2wheat", stage: 0 },
    duration: cleverapps.parseInterval("5 minute"),
    fog: "fog0"
}];

Telly.PRIZES.dragonia2 = Telly.PRIZES.dragonia;

MissionTree.PRIZES.dragonia2 = cleverapps.clone(MissionTree.PRIZES.universal, true);

Ruins.TIERS.dragonia2 = {
    cheap: {
        soft: 59,
        rewards: [
            { code: "drfreechest", stage: 0, amount: 1 },
            { code: "worker", stage: 0, amount: 1 }
        ]
    },
    costly: {
        hard: 249,
        rewards: [
            { code: "drfreechest", stage: 2, amount: 3 },
            { code: "crystal", stage: 2, amount: 3 },
            { code: "worker", stage: 4, amount: 1 }
        ]
    }
};

GrowingsPlanner.CONFIG.dragonia2 = cleverapps.clone(GrowingsPlanner.CONFIG.universal, true);

var Dragonia2FruitMine = {
    prize: function (level, unit) {
        return [{
            amount: 5,
            probabilities: [0.166, 0.166, 1],
            units: [
                { code: unit.code, stage: 1 },
                { code: "coins", stage: 0 },
                { code: unit.code, stage: 0 }
            ]
        }];
    }
};

Landmarks.WORLDS.dragonia2 = {
    fog: "fog5",
    force: Forces.LANDMARK_DONOR_SLOT2,
    landmarks: [{
        code: "landmark",
        stage: 7,
        targetExpedition: "main",
        donors: "dr2dragonpurple"
    }]
};

Mines.drfruit = Dragonia2FruitMine.prize;

CustomerRecipes.dragonia2 = {
    dr2blacksmith_0: [
        {
            ingredients: [
                {
                    code: "coins",
                    stage: 1,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "treasuremap",
                    stage: 0,
                    amount: 1
                }
            ]
        }
    ],
    dr2blacksmith_1: [
        {
            ingredients: [
                {
                    code: "drwood",
                    stage: 1,
                    amount: 4
                }
            ],
            order: [
                {
                    type: "campaignorder",
                    stage: 0,
                    amount: 2
                }
            ]
        }
    ],
    dr2blacksmith_2: [
        {
            ingredients: [
                {
                    code: "dr2wheat",
                    stage: 0,
                    amount: 3
                },
                {
                    code: "dr2coal",
                    stage: 0,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "campaignorder",
                    stage: 1,
                    amount: 3
                }
            ]
        }
    ],
    dr2blacksmith_3: [
        {
            ingredients: [
                {
                    type: "campaignorder",
                    stage: 2,
                    amount: 4
                },
                {
                    code: "dr2wheat",
                    stage: 0,
                    amount: 5
                },
                {
                    code: "dr2coal",
                    stage: 0,
                    amount: 5
                }
            ],
            order: [
                {
                    type: "campaignorder",
                    stage: 3,
                    amount: 1
                }
            ]
        }
    ],
    dr2blacksmith_4: [
        {
            ingredients: [
                {
                    code: "dr2wheat",
                    stage: 0,
                    amount: 5
                },
                {
                    code: "dr2coal",
                    stage: 0,
                    amount: 5
                },
                {
                    code: "dr2barrel",
                    stage: 0,
                    amount: 3
                }
            ],
            order: [
                {
                    type: "campaignorder",
                    stage: 4,
                    amount: 1
                }
            ],
            cookingDuration: "1 hours"
        }
    ],
    dr2blacksmith_5: [
        {
            ingredients: [
                {
                    code: "dr2wheat",
                    stage: 0,
                    amount: 5
                },
                {
                    code: "dr2coal",
                    stage: 0,
                    amount: 5
                },
                {
                    code: "dr2barrel",
                    stage: 0,
                    amount: 3
                }
            ],
            order: [
                {
                    type: "campaignorder",
                    stage: 4,
                    amount: 1
                }
            ],
            cookingDuration: "1 hours"
        }
    ],
    dr2blacksmith_6: [
        {
            ingredients: [
                {
                    code: "dr2wheat",
                    stage: 0,
                    amount: 5
                },
                {
                    code: "dr2coal",
                    stage: 0,
                    amount: 5
                },
                {
                    code: "dr2barrel",
                    stage: 0,
                    amount: 3
                }
            ],
            order: [
                {
                    type: "campaignorder",
                    stage: 4,
                    amount: 1
                }
            ],
            cookingDuration: "1 hours"
        }
    ],
    dr2blacksmith_7: [
        {
            ingredients: [
                {
                    code: "dr2wheat",
                    stage: 0,
                    amount: 5
                },
                {
                    code: "dr2coal",
                    stage: 0,
                    amount: 5
                },
                {
                    code: "dr2barrel",
                    stage: 0,
                    amount: 3
                }
            ],
            order: [
                {
                    type: "campaignorder",
                    stage: 4,
                    amount: 1
                }
            ],
            cookingDuration: "1 hours"
        }
    ],
    dr2blacksmith_8: [
        {
            ingredients: [
                {
                    code: "dr2wheat",
                    stage: 0,
                    amount: 5
                },
                {
                    code: "dr2coal",
                    stage: 0,
                    amount: 5
                },
                {
                    code: "dr2barrel",
                    stage: 0,
                    amount: 3
                }
            ],
            order: [
                {
                    type: "campaignorder",
                    stage: 4,
                    amount: 1
                }
            ],
            cookingDuration: "1 hours"
        }
    ],

    dr2airship_0: [
        {
            ingredients: [
                {
                    type: "campaignorder",
                    stage: 0,
                    amount: 2
                }
            ],
            order: [
                {
                    code: "coins",
                    stage: 1,
                    amount: 2
                }
            ]
        }
    ],
    dr2airship_1: [
        {
            ingredients: [
                {
                    code: "dr2wheat",
                    stage: 0,
                    amount: 5
                },
                {
                    code: "drwood",
                    stage: 2,
                    amount: 3
                }
            ],
            order: [
                {
                    code: "coins",
                    stage: 1,
                    amount: 2
                }
            ]
        }
    ],
    dr2airship_2: [
        {
            ingredients: [
                {
                    type: "campaignorder",
                    stage: 1,
                    amount: 3
                },
                {
                    code: "drwood",
                    stage: 2,
                    amount: 4
                },
                {
                    code: "drstone",
                    stage: 2,
                    amount: 3
                }
            ],
            order: [
                {
                    code: "coins",
                    stage: 1,
                    amount: 2
                }
            ]
        }
    ],
    dr2airship_3: [
        {
            ingredients: [
                {
                    type: "campaignorder",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    code: "coins",
                    stage: 1,
                    amount: 2
                }
            ]
        }
    ],
    dr2airship_4: [
        {
            ingredients: [
                {
                    code: "dr2wheat",
                    stage: 0,
                    amount: 10
                },
                {
                    type: "campaignorder",
                    stage: 4,
                    amount: 5
                }
            ],
            order: [
                {
                    code: "coins",
                    stage: 1,
                    amount: 2
                }
            ]
        }
    ],

    dr2mine_0: [
        {
            ingredients: [
                {
                    code: "dr2wheat",
                    stage: 0,
                    amount: 5
                },
                {
                    code: "coins",
                    stage: 1,
                    amount: 1
                }
            ],
            order: [
                {
                    code: "dr2coal",
                    stage: 0,
                    amount: 1
                }
            ],
            cookingDuration: "15 minutes"
        }
    ],

    dr2sawmill_0: [
        {
            ingredients: [
                {
                    code: "drwood",
                    stage: 2,
                    amount: 1
                },
                {
                    code: "drstone",
                    stage: 1,
                    amount: 2
                },
                {
                    code: "dr2coal",
                    stage: 0,
                    amount: 3
                }
            ],
            order: [
                {
                    code: "dr2barrel",
                    stage: 0,
                    amount: 1
                }
            ],
            cookingDuration: "5 minutes"
        }
    ],

    dr2elf_0: [
        {
            ingredients: [
                {
                    code: "dr2wheat",
                    stage: 0,
                    amount: 1
                },
                {
                    code: "dr2coal",
                    stage: 0,
                    amount: 1
                },
                {
                    code: "dr2barrel",
                    stage: 0,
                    amount: 1
                }
            ],
            order: [
                {
                    code: "worker",
                    stage: 1,
                    amount: 1
                }
            ]
        },
        {
            ingredients: [
                {
                    code: "dr2wheat",
                    stage: 0,
                    amount: 1
                },
                {
                    code: "dr2coal",
                    stage: 0,
                    amount: 1
                },
                {
                    code: "dr2barrel",
                    stage: 0,
                    amount: 1
                }
            ],
            order: [
                {
                    code: "crystal",
                    stage: 0,
                    amount: 1
                }
            ]
        },
        {
            ingredients: [
                {
                    code: "dr2wheat",
                    stage: 0,
                    amount: 1
                },
                {
                    code: "dr2coal",
                    stage: 0,
                    amount: 1
                },
                {
                    code: "dr2barrel",
                    stage: 0,
                    amount: 1
                }
            ],
            order: [
                {
                    code: "drwoodchest",
                    stage: 1,
                    amount: 1
                }
            ]
        },
        {
            ingredients: [
                {
                    code: "dr2wheat",
                    stage: 0,
                    amount: 1
                },
                {
                    code: "dr2coal",
                    stage: 0,
                    amount: 1
                },
                {
                    code: "dr2barrel",
                    stage: 0,
                    amount: 1
                }
            ],
            order: [
                {
                    code: "drmagicplant",
                    stage: 2,
                    amount: 1
                }
            ]
        }
    ]
};
