/**
 * Created by spepa on 26.12.2022
 */

Climbable.TRIGGERS.rapunzel2 = {
    rpcentaur_0: { code: "rptrolley", stage: 0 },
    rpcentaur_4: { code: "rpcrane", stage: 1 },
    rpcrane_0: { code: "rptrolley", stage: 4 },
    rppress_0: { code: "rpcrane", stage: 2 }
};

Landmarks.WORLDS.rapunzel2 = {
    fog: "fog2",
    force: Forces.LANDMARK_DONOR_SLOT2,
    landmarks: [{
        code: "landmark",
        stage: 5,
        targetExpedition: "main",
        donors: "rpprincehero"
    }]
};

CustomerRecipes.rapunzel2 = {
    rpdoorway_0: [
        {
            ingredients: [
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 2
                },
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 2
                }
            ],
            order: [
                {
                    code: "coins",
                    stage: 1,
                    amount: 2
                }
            ]
        }
    ],

    rpdoorway_1: [
        {
            ingredients: [
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 3
                },
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 3
                },
                {
                    type: "rporder",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    code: "coins",
                    stage: 2,
                    amount: 1
                }
            ]
        }
    ],

    rpdoorway_2: [
        {
            ingredients: [
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 4
                },
                {
                    type: "rporder",
                    stage: 3,
                    amount: 2
                },
                {
                    type: "rporder",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    code: "coins",
                    stage: 3,
                    amount: 1
                }
            ]
        }
    ],

    rpdoorway_3: [
        {
            ingredients: [
                {
                    type: "rporder",
                    stage: 3,
                    amount: 4
                },
                {
                    type: "rporder",
                    stage: 3,
                    amount: 2
                },
                {
                    type: "rporder",
                    stage: 3,
                    amount: 3
                }
            ],
            order: [
                {
                    code: "coins",
                    stage: 3,
                    amount: 2
                }
            ]
        }
    ],

    rptrolley_0: [
        {
            ingredients: [
                {
                    type: "campaignorder",
                    stage: 0,
                    amount: 1
                }
            ],
            order: [
                {
                    code: "coins",
                    stage: 1,
                    amount: 1
                }
            ]
        }
    ],

    rptrolley_1: [
        {
            ingredients: [
                {
                    type: "campaignorder",
                    stage: 1,
                    amount: 1
                }
            ],
            order: [
                {
                    code: "coins",
                    stage: 1,
                    amount: 2
                }
            ]
        }
    ],

    rptrolley_2: [
        {
            ingredients: [
                {
                    type: "campaignorder",
                    stage: 2,
                    amount: 1
                },
                {
                    type: "rporder",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    code: "coins",
                    stage: 2,
                    amount: 1
                }
            ]
        }
    ],

    rptrolley_3: [
        {
            ingredients: [
                {
                    type: "campaignorder",
                    stage: 3,
                    amount: 5
                }
            ],
            order: [
                {
                    code: "coins",
                    stage: 2,
                    amount: 2
                }
            ]
        }
    ],

    rpcrane_1: [
        {
            ingredients: [
                {
                    type: "campaignorder",
                    stage: 4,
                    amount: 1
                }
            ],
            order: [
                {
                    code: "coins",
                    stage: 3,
                    amount: 1
                }
            ]
        }
    ],

    rppress_1: [
        {
            ingredients: [
                {
                    type: "campaignorder",
                    stage: 5,
                    amount: 4
                }
            ],
            order: [
                {
                    type: "campaignorder",
                    stage: 6,
                    amount: 1
                }
            ]
        }
    ],

    rptelescope_0: [
        {
            ingredients: [
                {
                    type: "campaignorder",
                    stage: 6,
                    amount: 1
                }
            ],
            order: [
                {
                    code: "coins",
                    stage: 3,
                    amount: 2
                }
            ]
        }
    ],

    rpcentaur_1: [
        {
            ingredients: [
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 2
                },
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "campaignorder",
                    stage: 0,
                    amount: 1
                }
            ]
        }
    ],

    rpcentaur_2: [
        {
            ingredients: [
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 3
                },
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 2
                }
            ],
            order: [
                {
                    type: "campaignorder",
                    stage: 1,
                    amount: 1
                }
            ]
        }
    ],

    rpcentaur_3: [
        {
            ingredients: [
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 3
                },
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 2
                },
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "campaignorder",
                    stage: 2,
                    amount: 1
                }
            ]
        }
    ],

    rpcentaur_5: [
        {
            ingredients: [
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 3
                },
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 3
                },
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 3
                }
            ],
            order: [
                {
                    type: "campaignorder",
                    stage: 4,
                    amount: 1
                }
            ]
        }
    ],

    rprapuncust_1: [
        {
            ingredients: [
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "rporder",
                    stage: 0,
                    amount: 5
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "rporder",
                    stage: 0,
                    amount: 10
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "rporder",
                    stage: 0,
                    amount: 5
                },
                {
                    type: "rporder",
                    stage: 1,
                    amount: 6
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "rporder",
                    stage: 0,
                    amount: 5
                },
                {
                    type: "rporder",
                    stage: 0,
                    amount: 5
                },
                {
                    type: "rporder",
                    stage: 1,
                    amount: 8
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "rporder",
                    stage: 0,
                    amount: 10
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 2
                },
                {
                    type: "rpresource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "rporder",
                    stage: 0,
                    amount: 5
                },
                {
                    type: "rporder",
                    stage: 1,
                    amount: 6
                }
            ]
        }
    ],
    rpthrone_0: [
        {
            ingredients: [
                {
                    type: "rporder",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "rphero",
                    stage: 0,
                    amount: 3
                },
                {
                    type: "rphero",
                    stage: 1,
                    amount: 1
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "rporder",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "rphero",
                    stage: 0,
                    amount: 2
                },
                {
                    type: "rphero",
                    stage: 1,
                    amount: 2
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "rporder",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "rporder",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "rphero",
                    stage: 0,
                    amount: 5
                },
                {
                    type: "rphero",
                    stage: 1,
                    amount: 3
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "rporder",
                    stage: 3,
                    amount: 3
                },
                {
                    type: "rporder",
                    stage: 3,
                    amount: 3
                }
            ],
            order: [
                {
                    type: "rphero",
                    stage: 0,
                    amount: 1
                },
                {
                    type: "rphero",
                    stage: 1,
                    amount: 6
                }
            ]
        }
    ]
};
