/**
 * Created by Andrey Popov on 29.08.22
 */

var SnailFeastWindow = CleverappsWindow.extend({
    onWindowLoaded: function (mission) {
        this.mission = mission;
        var offer = this.offer = cleverapps.offerManager.findOffer({ mission: this.mission.type });

        this._super({
            name: "SnailFeastWindow",
            content: this.createContent(),
            noBackground: true,
            contentPadding: cleverapps.styles.SnailFeastWindow.contentPadding,
            styles: cleverapps.styles.SnailFeastWindow.window,
            openSound: bundles.snail_feast.urls.snail_window_sfx,
            notCloseByTouchInShadow: true,
            offerWindowButton: offer ? {
                text: "FeastMissionWindow." + this.mission.getName() + ".offer",
                offerType: offer.type,
                onClicked: this.onOfferClicked.bind(this),
                bundle: "snail_feast",
                badgeText: "FeastMissionWindow." + this.mission.getName() + ".offerBadge"
            } : undefined
        });

        cleverapps.focusManager.showControlsWhileFocused("MenuBarGoldItem");

        this.mission.updateCompetitionResults();
    },

    getPerson: function () {
        if (["dragonia2", "dragonia3", "halloween"].indexOf(cleverapps.travelBook.getCurrentPage().id) !== -1) {
            return "narrator";
        }

        return "capitannemo";
    },

    onOfferClicked: function () {
        cleverapps.windows.currentWindow().close();
        new MissionOfferWindow(this.offer);
    },

    createContent: function () {
        var styles = cleverapps.styles.SnailFeastWindow;

        var content = this.content = new cleverapps.Spine(bundles.snail_feast.jsons.snail_window);
        content.setAnchorPoint(0.5, 0.5);
        content.setAnimation(0, "animation");

        if (this.mission.isRunning()) {
            var timeLeft = this.mission.getTimeLeft();
            if (timeLeft > 0) {
                timeLeft = new cleverapps.CountDown(timeLeft, {
                    onFinish: function () {
                        var StartWindow = this.mission.startWindow;
                        new StartWindow(this.mission);
                        this.close();
                    }.bind(this)
                });
            }

            var timer = new cleverapps.CountDownView(timeLeft, {
                font: cleverapps.styles.FONTS.FEAST_MISSION_TIMER_TEXT
            });
            timer.setAnchorPoint(0.5, 0.5);
            timer.setPositionRound(styles.timer);
            content.addChild(timer);
        } else {
            var finishButton = new cleverapps.UI.Button({
                width: styles.finishButton.width,
                height: styles.finishButton.height,
                type: cleverapps.styles.UI.Button.Images.button_blue,
                text: this.mission.getPrize() ? "ClaimPrize.Button.text" : "Close",
                onClicked: this.close.bind(this)
            });
            finishButton.setPositionRound(styles.finishButton);
            content.addChild(finishButton);
        }

        var mainTitle = cleverapps.UI.generateOnlyText(this.mission.isRunning() ? "SnailFeastWindow.title" : "SnailFeastWindow.finished", cleverapps.styles.FONTS.SNAILFEAST_MISSION_MAIN_TITLE_TEXT);
        mainTitle.setAnchorPoint(0.5, 0.5);
        mainTitle.setPositionRound(styles.mainTitle);
        mainTitle.fitTo(styles.mainTitle.width, styles.mainTitle.height);
        content.addChild(mainTitle);

        var helpButton = new cleverapps.UI.HelpButton({
            type: cleverapps.styles.UI.Button.Images.help_button_nobg,
            callback: function () {
                new GuideWindow({ name: "SnailFeastGuideWindow", bundle: bundles.snailfeastguidewindow });
            }
        });
        helpButton.setAnchorPoint(0.5, 0.5);
        helpButton.setPositionRound(styles.helpButton);
        content.addChild(helpButton);

        this.showResults(this.mission.getResults());

        return content;
    },

    showResults: function (results) {
        var styles = cleverapps.styles.SnailFeastWindow;

        var resultData = results.sort(function (a, b) {
            if (a.player || b.player) {
                return a.player ? -1 : 1;
            }
            return cleverapps.hashCode(a.name || a.id) - cleverapps.hashCode(b.name || b.id);
        });

        var avatars = new cleverapps.Layout(resultData.map(function (userData, index) {
            return this.createAvatars(userData, index);
        }.bind(this)), {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.avatars.margin
        });
        avatars.setPositionRound(styles.avatars);
        this.content.addChild(avatars);

        var sum = 0;
        resultData.forEach(function (userData) {
            sum += userData.amount;
        });
        sum = Math.max(sum, 100);

        var racetracks = new cleverapps.Layout(resultData.map(function (userData) {
            return this.createTracks(userData, sum);
        }.bind(this)), {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.racetrack.margin
        });
        racetracks.setPositionRound(styles.racetrack);
        this.content.addChild(racetracks);
    },

    createAvatars: function (userData, index) {
        var styles = cleverapps.styles.SnailFeastWindow;
        var avatar = new cleverapps.UI.Avatar(userData.avatar, undefined, styles.avatars.image);

        var textString = userData.text || "";
        if (!textString && typeof userData.id === "string") {
            textString = "Player_" + userData.id.substr(-3);
        }
        textString = cleverapps.UI.cutPlayerName(textString, 12);

        var font = userData.player ? cleverapps.styles.FONTS.AVATAR_PLAYER_TEXT : cleverapps.styles.FONTS.AVATAR_TEXT;
        var text = cleverapps.UI.generateOnlyText(textString, font);
        text.fitTo(styles.name.width);
        text.setLocalZOrder(2);
        avatar.addChild(text);
        avatar.setAnchorPoint(0, 0.5);
        text.setPositionRound(styles.name);

        if (userData.place && userData.place <= 3 && userData.amount) {
            var cupSprite = new cc.Sprite(bundles.snail_feast.frames["cup_" + userData.place]);
            cleverapps.tooltipManager.create(cupSprite, {
                text: "PrizeTooltip",
                rewards: RewardsConfig.SnailFeast.places[userData.place - 1],
                location: index < 5 ? cleverapps.UI.Tooltip.LOCATION_BELOW : cleverapps.UI.Tooltip.LOCATION_ABOVE
            });

            var node = new cleverapps.Layout([avatar, cupSprite], {
                direction: cleverapps.UI.HORIZONTAL,
                margin: (styles.cup.width - cupSprite.width) / 2
            });
            node.setAnchorPoint(0, 0.5);
            return node;
        }
        return avatar;
    },

    createTracks: function (userData, sum) {
        var styles = cleverapps.styles.SnailFeastWindow.racetrack;

        var amount = Math.max(0, userData.amount);
        var racetrack = new cc.Node();
        racetrack.setAnchorPoint(0.5, 0.5);
        racetrack.setContentSize(styles);

        var snail = new cleverapps.Spine(bundles.snail_feast.jsons.snail_animation);
        snail.setSkin(userData.player ? "blue" : "red");
        snail.setTimeScale(1.2 - userData.place * 0.1);
        snail.setAnimation(0, "animation", true);

        var font = userData.player ? cleverapps.styles.FONTS.AVATAR_PLAYER_TEXT : cleverapps.styles.FONTS.AVATAR_TEXT;
        var amountText = cleverapps.UI.generateImageText(amount, font);

        var amountWithIcon = new cleverapps.Layout([new cc.Sprite(bundles.snail_feast.frames.speed), amountText], {
            direction: cleverapps.UI.HORIZONTAL
        });
        snail.addChild(amountWithIcon);
        amountWithIcon.setPositionRound(styles.amount);

        racetrack.addChild(snail);
        snail.setPositionRound(amount / sum * racetrack.width, styles.snailY);

        return racetrack;
    },

    onClose: function () {
        this.mission.runningCompetition.updateShownPlace();
        this.mission.updateMissionIcon();
    },

    listBundles: function () {
        return ["snail_feast_" + cleverapps.skins.getSlot("skinName")];
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    AVATAR_PLAYER_TEXT: {
        name: "default",
        size: 30,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.BLUE_STROKE
    },
    AVATAR_TEXT: {
        name: "default",
        size: 30,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.RED_STROKE
    },
    SNAILFEAST_MISSION_MAIN_TITLE_TEXT: {
        size: 78,
        color: new cc.Color(255, 201, 39, 255),
        stroke: cleverapps.styles.DECORATORS.FEAST_MISSION_STROKE,
        shadow: cleverapps.styles.DECORATORS.FEAST_MISSION_SHADOW
    }
});

cleverapps.styles.SnailFeastWindow = {
    margin: 20,

    person: "capitannemo",

    contentPadding: {
        bottom: 0
    },

    mainTitle: {
        x: { align: "center", dx: 20 },
        y: { align: "center", dy: 584 },
        width: 580,
        height: 90
    },

    timer: {
        x: { align: "center", dx: 20 },
        y: { align: "center", dy: 498 }
    },

    helpButton: {
        x: { align: "center", dx: -715 },
        y: { align: "center", dy: 548 }
    },

    avatars: {
        x: { align: "left", dx: 100 },
        y: { align: "center", dy: -10 },
        margin: 2,

        image: {
            x: 20,
            top: 20,
            bottom: 20
        }
    },

    name: {
        x: { align: "center" },
        y: { align: "bottom", dy: -3 },
        width: 170
    },

    racetrack: {
        width: 800,
        height: 120,

        margin: 46,

        x: { align: "center", dx: 50 },
        y: { align: "center", dy: -40 },

        snailY: 84,

        amount: {
            x: { align: "center", dx: -36 },
            y: { align: "bottom", dy: -10 }
        }
    },

    cup: {
        width: 140
    },

    window: {
        CloseButton: {
            x: { align: "right", dx: -234 },
            y: { align: "top", dy: -48 }
        }
    },

    finishButton: {
        width: 400,
        height: 95,
        x: { align: "center" },
        y: { align: "bottom", dy: -30 }
    }

};
