/**
 * Created by andrey on 17.12.2021.
 */

var Season = function (stored) {
    this.runningSeason = Season.getCurrentSeason();
    this.opened = 0;
    this.fresh = 0;

    this.load(stored);
};

Season.getCurrentSeason = function () {
    return new Date().getMonth();
};

Season.getCurrentItemCode = function () {
    return "season" + Season.getCurrentSeason();
};

Season.isRunning = function () {
    return cleverapps.user.checkAvailable(Season.AVAILABLE) && Families["season" + Season.getCurrentSeason()] !== undefined;
};

Season.getTimeLeft = function () {
    return Season.isRunning() ? new Date(new Date().getFullYear(), Season.getCurrentSeason() + 1) - Date.now() : 0;
};

Season.prototype.startSeason = function () {
    this.runningSeason = Season.getCurrentSeason();
    this.opened = 0;
    this.fresh = 0;
    Game.currentGame.storeSave();
};

Season.prototype.onUnitAvailable = function (unit) {
    if (this.runningSeason !== Season.getCurrentSeason()) {
        this.startSeason();
    }

    if (!Season.isRunning() || unit.code !== Season.getCurrentItemCode() || this.isOpened(unit.stage)) {
        return;
    }

    var opened = unit.stage + 1;
    if (Game.currentGame.initStageRunned) {
        this.fresh += opened - this.opened;
        Game.currentGame.counter.trigger();
    }
    this.opened = opened;

    Game.currentGame.storeSave();
};

Season.prototype.processFresh = function () {
    if (cleverapps.focusManager.isFocused()) {
        return;
    }

    if (!this.hasFresh()) {
        return;
    }

    cleverapps.focusManager.display({
        focus: "SeasonWindow",
        actions: [
            function (f) {
                setTimeout(f, 300);
            },

            function (f) {
                var bestUnit = undefined, seasonCode = Season.getCurrentItemCode();
                var units = Map2d.currentMap.listAvailableUnits();
                for (var i = 0; i < units.length; i++) {
                    if (units[i].code === seasonCode && this.isFresh(units[i].stage) && (!bestUnit || bestUnit.stage < units[i].stage)) {
                        bestUnit = units[i];
                    }
                }
                if (bestUnit) {
                    Map2dScroller.currentScroller.zoomIn(bestUnit, {
                        zoom: 1.35,
                        callback: f
                    });
                } else {
                    f();
                }
            }.bind(this),

            function (f) {
                new SeasonWindow(this);
                cleverapps.focusManager.onceNoWindowsListener = f;
            }.bind(this),

            function (f) {
                Map2dScroller.currentScroller.zoomOut();
                f();
            },

            function (f) {
                var stages = this.getFreshStages();

                var actions = stages.map(function (stage) {
                    return function (f) {
                        var reward = this.getReward(stage);
                        if (reward.unit) {
                            new RewardWindow(reward);
                            cleverapps.focusManager.onceNoWindowsListener = f;
                        } else {
                            f();
                        }
                    }.bind(this);
                }.bind(this));

                cleverapps.focusManager.compound(f, actions);

                this.resetFresh();
            }.bind(this)
        ]
    });
};

Season.prototype.hasFresh = function () {
    return Season.isRunning() && this.fresh !== 0;
};

Season.prototype.getSeasonItems = function () {
    return Season.isRunning() ? UnitsLibrary.ListByCode(Season.getCurrentItemCode()) : [];
};

Season.prototype.getSeasonChests = function () {
    return Season.isRunning() ? UnitsLibrary.ListByCode("seasonchest") : [];
};

Season.prototype.save = function () {
    if (this.opened) {
        return this.fresh * 10000 + this.runningSeason * 100 + (this.opened || 0);
    }
};

Season.prototype.load = function (stored) {
    stored = stored || 0;

    var fresh = Math.floor(stored / 10000);
    var season = Math.floor((stored % 10000) / 100);
    var opened = stored % 100;

    var family = Families[Season.getCurrentItemCode()];

    if (season === this.runningSeason && family) {
        this.opened = Math.min(Math.max(opened, 0), family.units.length);
        this.fresh = Math.min(Math.max(fresh, 0), this.opened);
    } else if (stored) {
        Game.currentGame.needSave = true;
    }
};

Season.prototype.isOpened = function (stage) {
    return stage <= this.opened - 1;
};

Season.prototype.getFreshStages = function () {
    var result = [];

    for (var stage = this.opened - this.fresh; stage < this.opened; stage++) {
        result.push(stage);
    }

    return result;
};

Season.prototype.isFresh = function (stage) {
    return stage >= this.opened - this.fresh;
};

Season.prototype.resetFresh = function () {
    this.fresh = 0;
    Game.currentGame.storeSave();
};

Season.prototype.getReward = function (stage) {
    return Season.REWARDS.default[stage];
};

Season.AVAILABLE = {
    level: 5
};