/**
 * Created by Reda on 24.01.2025
 */

cleverapps.SkinManager.SKINS.spring_expedition = {
    bundle: "expedition_spring",

    slots: Object.assign({}, cleverapps.SkinManager.SKINS.rose_energy.slots, {

    })
};