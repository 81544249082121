/**
 * Created by vladislav on 15.07.2022
 */

RuinsWindow.prototype.createContent = function () {
    var cheap = this.createTier("cheap");
    var costly = this.createTier("costly");

    var styles = cleverapps.styles.RuinsWindow;

    var tiers = new cleverapps.Layout([cheap, costly], {
        margin: styles.margin,
        direction: cleverapps.UI.HORIZONTAL
    });

    var rainbow = this.createRainbow();

    return new cleverapps.Layout([rainbow, tiers], {
        margin: styles.rainbowMargin,
        direction: cleverapps.UI.VERTICAL
    });
};

RuinsWindow.prototype.createTier = function (tierName) {
    var styles = cleverapps.styles.RuinsWindow;

    var iconNode = new cc.Node();
    iconNode.setAnchorPoint(0.5, 0.5);
    iconNode.setContentSize2(styles.icon);

    if (cleverapps.travelBook.getCurrentPage().id === "xmas") {
        var icon = new cc.Sprite(tierName === "cheap" ? this.bundle.frames.tier1_tool : this.bundle.frames.tier2_tool);
        iconNode.addChild(icon);
        icon.setPositionRound(iconNode.width / 2, iconNode.height / 2);
    } else {
        var animation = new cleverapps.Spine(tierName === "cheap" ? this.bundle.jsons.bottle_cheap : this.bundle.jsons.bottle_costly);
        animation.setAnimationAndIdleAfter("open", "idle");
        iconNode.addChild(animation);
        animation.setPositionRound(tierName === "cheap" ? styles.icon.cheap : styles.icon.costly);
    }

    var tierTitle = cleverapps.UI.createScale9Sprite(this.bundle.frames.title_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
    tierTitle.setContentSize(styles.title.bg);

    var text = cleverapps.UI.generateOnlyText(this.getDictionaryKey("plateName." + tierName), cleverapps.styles.FONTS.RUINS_PLATE_TITLE);
    text.fitTo(styles.title.text.width);
    text.setPositionRound(styles.title.text);
    tierTitle.addChild(text);

    var helpButton = new cleverapps.UI.HelpButton({
        callback: function () {
            var rewards = this.ruins.getRelevantTier(tierName).rewards;
            new RuinsPrizeWindow(rewards, tierName, this.bundle);
        }.bind(this) 
    });
    helpButton.setPositionRound(styles.title.helpButton);
    tierTitle.addChild(helpButton);

    var button = this.createPlateButton(tierName);

    return new cleverapps.Layout([iconNode, tierTitle, button], {
        margin: styles.tierMargin,
        direction: cleverapps.UI.VERTICAL
    });
};

RuinsWindow.prototype.createRainbow = function () {
    var styles = cleverapps.styles.RuinsWindow;

    var rainbow = new cc.Sprite(this.bundle.frames.rainbow);
    var text = cleverapps.UI.generateOnlyText(this.getDictionaryKey("cta"), cleverapps.styles.FONTS.RAINBOW_TEXT);
    text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
    text.setDimensions(styles.rainbowText.width, 0);
    rainbow.addChild(text);
    text.setPositionRound(styles.rainbowText);

    return rainbow;
};

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    RAINBOW_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.overrideStyles(cleverapps.styles.RuinsWindow, {
    margin: 260,
    tierMargin: 30,
    rainbowMargin: -400,

    noBackground: true,

    plate: {
        button: {
            height: 110
        }
    },

    icon: {
        width: 420,
        height: 550,

        cheap: {
            x: { align: "center", dx: 260 },
            y: { align: "center", dy: 43 }
        },
        costly: {
            x: { align: "center", dx: -337 },
            y: { align: "center", dy: 40 }
        }
    },

    title: {
        bg: {
            width: 350,
            height: 84
        },

        text: {
            width: 200,
            x: { align: "center" },
            y: { align: "center", dy: 5 }
        },

        helpButton: {
            x: { align: "center", dx: -125 },
            y: { align: "center", dy: 45 }
        }
    },

    rainbowText: {
        x: { align: "center" },
        y: { align: "center", dy: 140 },
        width: 350
    }
});
