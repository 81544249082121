/**
 * Created by Ivan on 18.01.2023
 */

var EditorFogHeadView = cc.Node.extend({
    ctor: function (editorFogHead) {
        this._super();

        this.fogBlock = editorFogHead.block;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(cleverapps.styles.Map2dView.cell);

        this.createHead();
        this.createTitle();

        editorFogHead.moveTo = this.createListener(this.moveTo.bind(this));
        editorFogHead.onDragMove = this.createListener(this.onDragMove.bind(this));
        editorFogHead.onUpdateTitle = this.createListener(this.updateTitle.bind(this));
    },

    moveTo: function (x, y) {
        var position = Map2d.currentMap.getMapView().alignInGrid(x, y);
        this.runAction(new cc.MoveTo(0.05, position.x, position.y));
    },

    onDragMove: function (touch) {
        this.setPositionRound(this.parent.convertTouchToNodeSpace(touch));
    },

    createHead: function () {
        var head;

        if (this.fogBlock.isMoney()) {
            head = new cc.Sprite(bundles.merge.frames.seller_icon);
        } else {
            head = new cc.Node();
            head.setAnchorPoint(0.5, 0.5);
            head.setContentSize2(cleverapps.styles.FogBlockView.opener);
            if (!this.fogBlock.isFree()) {
                var bar = FogBlockView.prototype.createBar.call(this);
                bar.setPositionRound({ align: "center" }, { align: "center" });
                bar.setPercentage(100);
                head.addChild(bar);
            }
        }

        this.head = head;
        head.setPositionRound({ align: "center" }, { align: "center" });
        head.setScale(0.75);
        this.addChild(head);
    },

    createTitle: function () {
        var title = this.title = cleverapps.UI.generateTTFText(this.generateText(), cleverapps.styles.FONTS.WINDOW_TEXT);
        title.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        title.setPositionRound(cleverapps.styles.EditorFogHeadView.title);
        this.title = title;
        this.addChild(title);
    },

    generateText: function () {
        return this.fogBlock.id + " (" + this.fogBlock.tiles.length + ")";
    },

    updateTitle: function () {
        this.title.setString(this.generateText());
    }
});

cleverapps.styles.EditorFogHeadView = {
    title: {
        x: { align: "center" },
        y: { align: "center", dy: 50 }
    }
};