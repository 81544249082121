/**
 * Created by mac on 4/23/21
 */

var MergePushes = function () {
    this.updateFruits(cleverapps.LocalPushes.TYPES.FRUIT_RIPE);
    this.updateFruits(cleverapps.LocalPushes.TYPES.CASTLE_PRIZE);
    this.updateFruits(cleverapps.LocalPushes.TYPES.MAGIC_PLANT_READY);
    this.updateOrders();
};

MergePushes.prototype.updateBuilt = function () {
    var nextBuilding = this._buildingBuilt();

    cleverapps.localPushes.sendPush(cleverapps.LocalPushes.TYPES.BUILDING_BUILT, nextBuilding);
};

MergePushes.prototype._buildingBuilt = function () {
    var worker = Map2d.currentMap.workers.findLeastBusy(Buildable);
    if (worker) {
        return worker.getTimeLeft();
    }
};

MergePushes.prototype.updateChopped = function () {
    var nextTree = this._treeChopped();
    var type = cleverapps.LocalPushes.TYPES.TREE_CHOPPED;

    if (cleverapps.travelBook.isExpedition()) {
        type = cleverapps.LocalPushes.TYPES.EXPEDITION_TREE_CHOPPED;
    }

    cleverapps.localPushes.sendPush(type, nextTree);
};

MergePushes.prototype._treeChopped = function () {
    var worker = Map2d.currentMap.workers.findLeastBusy(Mineable);
    if (worker) {
        return worker.getTimeLeft();
    }
};

MergePushes.prototype.updateOrders = function () {
    var nextOrder = this._orderReady();

    cleverapps.localPushes.sendPush(cleverapps.LocalPushes.TYPES.ORDER_READY, nextOrder);
};

MergePushes.prototype._orderReady = function () {
    var heroes = Map2d.currentMap.listAvailableUnits(function (unit) {
        return unit.findComponent(MakesOrder);
    });

    var minTime = undefined;
    heroes.forEach(function (heroUnit) {
        var makesOrder = heroUnit.findComponent(MakesOrder);
        if (makesOrder.isCooking()) {
            var timeLeft = makesOrder.getCookingTimeLeft();
            if (minTime === undefined || minTime > timeLeft) {
                minTime = timeLeft;
            }
        }
    });

    return minTime;
};

MergePushes.prototype.updateMiniGames = function () {
    var nextPrize = this._miniGamePrize();

    cleverapps.localPushes.sendPush(cleverapps.LocalPushes.TYPES.MINI_GAME_PRIZE, nextPrize);
};

MergePushes.prototype._miniGamePrize = function () {
    var miniGames = Map2d.currentMap.listAvailableUnits(function (unit) {
        return unit.findComponent(MiniGame);
    });

    var minTime = undefined;
    miniGames.forEach(function (miniGameUnit) {
        var miniGame = miniGameUnit.findComponent(MiniGame);
        var timeLeft = miniGame.timeToReady();
        if (timeLeft > 0) {
            if (minTime === undefined || minTime > timeLeft) {
                minTime = timeLeft;
            }
        }
    });

    return minTime;
};

MergePushes.prototype.updateFruits = function (type) {
    var nextTime = this._fruitsRipe(type);

    cleverapps.localPushes.sendPush(type, nextTime);
};

MergePushes.prototype._fruitsRipe = function (type) {
    var nextTime;

    Map2d.currentMap.listAvailableUnits().forEach(function (unit) {
        var fruit = unit.findComponent(Fruit);
        if (fruit && fruit.localPush === type) {
            var timeLeft = fruit.timeToReady();
            if (timeLeft > 0 && (nextTime === undefined || nextTime > timeLeft)) {
                nextTime = timeLeft;
            }
        }
    });
    return nextTime;
};
