/**
 * Created by r4zi4l on 17.06.2021
 */

var FeastMissionWindow = CleverappsWindow.extend({
    onWindowLoaded: function (options) {
        this.mission = options.mission;
        this.name = options.name;
        this.bundleName = options.bundleName || this.mission.getName().toLowerCase() + "_window";
        this.pack = options.pack;
        var offer = this.offer = cleverapps.offerManager.findOffer({ mission: this.mission.type });

        options.styles = cleverapps.overrideStyles(options.styles || {}, {
            windowShowUpAnimation: { name: "slideUp", force: true }
        }, true);

        this._super({
            name: options.name,
            content: this.createContent(),
            contentPadding: cleverapps.styles.FeastMissionWindow.contentPadding,
            noBackground: true,
            styles: options.styles,
            notCloseByTouchInShadow: true,
            offerWindowButton: offer ? {
                text: "FeastMissionWindow." + this.mission.getName() + ".offer",
                offerType: offer.type,
                onClicked: this.onOfferClicked.bind(this),
                bundle: this.bundleName,
                badgeText: "FeastMissionWindow." + this.mission.getName() + ".offerBadge"
            } : undefined
        });

        cleverapps.focusManager.showControlsWhileFocused(options.controls);
        this.mission.updateCompetitionResults();
    },

    onOfferClicked: function () {
        cleverapps.windows.currentWindow().close();
        new MissionOfferWindow(this.offer);
    },

    createContent: function () {
        var styles = cleverapps.styles.FeastMissionWindow;
        var mission = this.mission;

        var table = this.createTable();
        table.setAnchorPoint(0.5, 0.5);
        table.setPositionRound(styles.table);

        var mainTitle = cleverapps.UI.generateOnlyText(this.name + ".title", cleverapps.styles.FONTS.FEAST_MISSION_MAIN_TITLE_TEXT);
        mainTitle.setAnchorPoint(0.5, 0.5);
        mainTitle.setPositionRound(styles.mainTitle);
        mainTitle.fitTo(styles.mainTitle.width, styles.mainTitle.height);
        table.addChild(mainTitle);

        var helpButton = new cleverapps.UI.HelpButton({
            type: cleverapps.styles.UI.Button.Images.help_button_nobg,
            callback: function () {
                new GuideWindow(this.getGuideOptions());
            }.bind(this)
        });
        helpButton.setAnchorPoint(0.5, 0.5);
        helpButton.setPositionRound(styles.helpButton);
        table.addChild(helpButton);

        if (mission.isRunning()) {
            var timeLeft = mission.getTimeLeft();
            if (timeLeft > 0) {
                timeLeft = new cleverapps.CountDown(timeLeft, {
                    onFinish: function () {
                        var StartWindow = this.mission.startWindow;
                        new StartWindow(this.mission);
                        this.close();
                    }.bind(this)
                });
            }

            var timer = new cleverapps.CountDownView(timeLeft, {
                font: cleverapps.styles.FONTS.FEAST_MISSION_TIMER_TEXT
            });

            var items = [timer];

            if (bundles[this.bundleName].frames.timer_icon) {
                items.unshift(new cc.Sprite(bundles[this.bundleName].frames.timer_icon));
            }

            var timerLayout = new cleverapps.Layout(items, {
                direction: cleverapps.UI.HORIZONTAL,
                margin: styles.timer.margin
            });
            timerLayout.setAnchorPoint(0.5, 0.5);
            timerLayout.setPositionRound(styles.timer);
            table.addChild(timerLayout);
        } else {
            var finishTitle = cleverapps.UI.generateOnlyText("FeastMissionWindow.finished", cleverapps.styles.FONTS.FEAST_MISSION_TIMER_TEXT);
            finishTitle.setAnchorPoint(0.5, 0.5);
            finishTitle.setPositionRound(styles.finishTitle);
            finishTitle.fitTo(styles.finishTitle.width, styles.finishTitle.height);
            table.addChild(finishTitle);

            var finishButton = new cleverapps.UI.Button({
                width: styles.finishButton.width,
                height: styles.finishButton.height,
                type: cleverapps.styles.UI.Button.Images.button_blue,
                text: mission.getPrize() ? "ClaimPrize.Button.text" : "Close",
                onClicked: this.close.bind(this)
            });
            finishButton.setPositionRound(styles.finishButton);
        }

        return new cleverapps.Layout([table, finishButton].filter(Boolean), {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    createTable: function () {
        var styles = cleverapps.styles.FeastMissionWindow;
        var mission = this.mission;

        var background = new cleverapps.Spine(bundles[this.bundleName].jsons.feast_mission_board);
        background.setAnimation(0, "animation", true);

        var table = this.mission.table;
        var tableView = new TableView(table, {
            id: mission.type,
            data: mission.getResults(),
            height: styles.table.height,
            bg: false,
            rowOptions: {
                dataIcon: MissionWindow.getTableRowIcon(mission.type),
                dataIconAnimation: MissionWindow.getTableRowIconAnimation(mission.type),
                amountTooltip: this.name + ".tooltip.amount",
                mission: mission
            },
            rowConstructor: FeastMissionRow
        });
        tableView.setPositionRound(styles.table);
        background.addChild(tableView);

        mission.onChangeResults = this.createListener(function () {
            var results = mission.getResults();
            table.updateRows(results);
        });

        (cleverapps.skins.getSlot("feastWindowBatteries") || [
            bundles.livesfeast_window.frames.battery1,
            bundles.livesfeast_window.frames.battery2,
            bundles.livesfeast_window.frames.battery3
        ].filter(Boolean)).forEach(function (batteryFrame, index) {
            var battery = new cc.Sprite(batteryFrame);
            battery.setAnchorPoint(0.5, 0);
            battery.setPositionRound(styles.batteries[index]);
            tableView.addChild(battery);
        });

        return background;
    },

    onClose: function () {
        this.mission.runningCompetition.updateShownPlace();
        this.mission.updateMissionIcon();
    },

    listBundles: function (mission) {
        return [mission.getName().toLowerCase() + "_window"];
    }
});

cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    FEAST_MISSION_STROKE: {
        color: new cc.Color(95, 29, 17, 255),
        size: 1
    },

    FEAST_MISSION_SHADOW: {
        color: new cc.Color(11, 13, 37, 128),
        direction: cc.size(0, -3),
        blur: 4
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    FEAST_MISSION_MAIN_TITLE_TEXT: {
        size: 78,
        color: new cc.Color(255, 201, 39, 255),
        stroke: cleverapps.styles.DECORATORS.FEAST_MISSION_STROKE,
        shadow: cleverapps.styles.DECORATORS.FEAST_MISSION_SHADOW
    },
    FEAST_MISSION_TIMER_TEXT: {
        size: 52,
        color: new cc.Color(255, 220, 132, 255),
        stroke: cleverapps.styles.DECORATORS.FEAST_MISSION_STROKE,
        shadow: cleverapps.styles.DECORATORS.FEAST_MISSION_SHADOW
    },
    FEAST_MISSION_REWARD_TEXT: {
        size: 34,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    }
});

cleverapps.styles.FeastMissionWindow = {
    margin: 20,

    contentPadding: {
        bottom: 0
    },

    table: {
        x: { align: "center", dx: -12 },
        y: { align: "center", dy: -106 },
        height: 680,
        margin: -2,
        bg: undefined
    },

    mainTitle: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 376 },
        width: 700,
        height: 90
    },

    helpButton: {
        x: { align: "center", dx: -385 },
        y: { align: "center", dy: 373 }
    },

    timer: {
        margin: 25,
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 280 }
    },

    finishTitle: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 280 },
        width: 415,
        height: 80
    },

    finishButton: {
        x: { align: "center", dx: -25 },
        y: { align: "center" },
        width: 400,
        height: 95
    },

    batteries: [{
        x: { align: "center", dx: -420 },
        y: { align: "bottom", dy: -48 }
    }, {
        x: { align: "center", dx: 450 },
        y: { align: "bottom", dy: -48 }
    }, {
        x: { align: "center", dx: -190 },
        y: { align: "bottom", dy: -48 }
    }]
};
